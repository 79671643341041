import React, { useRef } from 'react'
import styled from 'styled-components'
import { styles } from '../../../styles/AppTheme'
import { setHTML } from '../../../utils/helpers';

import OutlinedButton from '../../_library/buttons/OutlinedButton';

import Tony from '../../../assets/images/character/tony.png'

const OutlineBtnWrapper = styled.div `
  pointer-events: auto;
  position: relative;
  padding-left: 2rem;
  padding-bottom: .5rem;
  padding-top: 0.5rem;

  .nav-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    justify-content: flex-start;
    padding-bottom: .75rem;
    padding-left: 20px;


    &::-webkit-scrollbar {
      display: none;
    }
  }

  .outline-btn {
    flex: 1 0 auto;
    margin-right: .5rem;
    display: flex;
    height: 3rem;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;
    padding: .25rem .5rem .25rem .875rem;

    span {
      text-align: left;
      display: block;
      margin-right: .5rem;
      font-family: ${styles.headingFont};
      text-transform: uppercase;
    }

    &.active {
      background-color: ${styles.theme.secondaryColour};
      box-shadow: 0 6px 0 ${styles.theme.secondaryShadow};
      border: 0;
    }
  }
`

const CharacterWrapper = styled.div `
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin-bottom: 0.75rem;
  margin-left: 12px;

  img {
    height: 100%;
    width: auto;
  }

  .speech-bubble {
    position: absolute;
    top: -2.5rem;
    left: 0;
    background-color: #fff;
    color: ${styles.theme.darkGreen};
    font-family: ${styles.headingFont};
    font-size: 0.875rem;
    text-transform: uppercase;
    padding: 0.375rem 1.125rem;
    white-space: nowrap;
    border-radius: 1.1rem;

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #fff;
      position: absolute;
      bottom: -4px;
      left: 20px;
      transform: rotate(45deg);
    }

    .num-of-questions {
      position: absolute;
      top: -9px;
      right: -1px;
      background-color: ${styles.theme.secondaryColour};
      color: #fff;
      border-radius: 100%;
      width: 20px;
      height: 20px;
    }
  }
`

const QuestionsNav = ({questions, activeQuestion, pickQuestion, resetScroll}) => {
  const SliderRef = useRef(null)
  
  return (
    <OutlineBtnWrapper>

      <CharacterWrapper>
        <div className='speech-bubble'>
          Select a question below
          <span className='num-of-questions'>{questions.length}</span>
        </div>
        <img src={Tony} alt="" />
      </CharacterWrapper>

      <div className='nav-scroll' ref={SliderRef}>
        {questions && questions.map ((question, index) => (
          <OutlinedButton
            key={index}
            className={`outline-btn ${index === activeQuestion ? 'active' : ''}`}
            handleClick={() => pickQuestion(index)}
            resetScroll={resetScroll}
          >
            <span dangerouslySetInnerHTML={setHTML(question.question)}></span>
            <img src={question.icon} alt="Question Icon" />
          </OutlinedButton>
        ))}
      </div>

    </OutlineBtnWrapper>
  )
}

export default QuestionsNav
