// Required 
export const SET_DEVICE_INFO = 'SET_DEVICE_INFO';
export const SET_AR_TYPE = 'SET_AR_TYPE';
export const SET_ERROR = 'SET_ERROR';
export const SET_PROGRESS = 'SET_PROGRESS';
export const LOADING_FINISHED = 'LOADING_FINISHED';
export const SET_SURFACE_FOUND = 'SET_SURFACE_FOUND';
export const SET_SETUP_DONE = 'SET_SETUP_DONE';
export const SET_AR_PLACED = 'SET_AR_PLACED';
export const SET_AR_ENABLED = 'SET_AR_ENABLED';
export const SOUND_ENABLED = 'SOUND_ENABLED';
export const INTRO_COMPLETED = 'INTRO_COMPLETED';
export const INTRO_STARTED = 'INTRO_STARTED';

// Project specific
export const TOGGLE_AUDIO = 'TOGGLE_AUDIO';
export const CHANGE_STATE = 'CAHNGE_STATE';
export const TRIGGER_PRODUCT = 'TRIGGER_PRODUCT';
export const CHANGE_SCENE = 'CHANGE_SCENE';
export const TOGGLE_MAP = 'TOGGLE_MAP';
