import React from 'react'

const Heading = ({priority, className = '', children}) => {
  return (
    <>
      {priority === 'h1' && (<h1 className={`heading ${className}`}>{children}</h1>)}
      {priority === 'h2' && (<h2 className={`heading ${className}`}>{children}</h2>)}
      {priority === 'h3' && (<h3 className={`heading ${className}`}>{children}</h3>)}
      {priority === 'h4' && (<h4 className={`heading ${className}`}>{children}</h4>)}
      {priority === 'h5' && (<h5 className={`heading ${className}`}>{children}</h5>)}
      {priority === 'h6' && (<h6 className={`heading ${className}`}>{children}</h6>)}
    </>
  )
}

export default Heading
