import React, { useContext, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { usePC } from "@unbnd-operations/react-hooks"
import ReactGA from 'react-ga'

import { grownFarmlands, grownGlasshouse, grownPlantation, grownTunnels } from '../../../data/tutorial-grown'

import AppContext from '../../../global/contexts/app-context'
import TitleStamp from '../../custom/TitleStamp'
import { styles } from '../../../styles/AppTheme'
import Stamp from '../../custom/Stamp'
import PlantHandIcon from '../../../assets/images/icons/plant-hand-icon.svg'
import PlayIcon from '../../../assets/images/icons/play-icon.svg'
import { setHTML } from '../../../utils/helpers'
import Video from '../../_library/media/Video'

const HowItsGrownWrapper = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;

  &.active {
    pointer-events: auto;
    opacity: 1;
    z-index: ${props => props.videoOpen ? 3 : 1};
  }

  .title-wrapper {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .grown-stamp {
    position: absolute;
    top: 95px;
    left: 50%;
    transform: translateX(-50%);
    .inner-content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
      text-align: left;
      font-family: ${styles.primaryFont};
      color: ${styles.theme.baseDark};

      p {
        line-height: 1.3;
      }
    }
  }

  .plant-hand-icon-wrapper {
    width: 46px;
    height: 46px;
    background-color: ${styles.theme.secondaryColour};

    position: absolute;
    bottom: -25%;
    left: -10%;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 23px;
    border: 2px solid ${styles.theme.baseLight};

    pointer-events: auto;
  }

  .step-indicator {
    position: absolute;
    right: 10px;
    bottom: 6px;
}
  }
`;

const TimelineWrapper = styled.div`
  width: 100%;
  height: 5px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: nowrap;

  .timeline-block {
    height: 5px;
    background-color: #c4c4c4;
    flex: 1 1 100%;
    margin-left: 3px;
    margin-right: 3px;

    &:first-child{
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    &.active {
      background-color: #ED1A39;
    }
  }
`;

const Timeline = ({ grownData, currentStep }) => {
  return (
    <TimelineWrapper className='TimelineWrapper'>
      {grownData && grownData.map((block, index) => (
        <div key={index} className={`timeline-block ${index <= currentStep ? 'active' : ''}`}></div>
      ))}

    </TimelineWrapper>
  )
}

const HowItsGrown = ({ active, tutorialCompleted, completeTutorial }) => {
  const { app: { sceneData } } = useContext(AppContext);
  const [currentStep, setCurrentStep] = useState(-1);
  const [currentTutorialStep, setCurrentTutorialStep] = useState(0);
  const [grownTutorial, setGrownTutorial] = useState(null);
  const [videoPopup, setVideoPopup] = useState(false);

  const triggerVideoPopup = (e, step) => {
    e.stopPropagation();

    if (!sceneData.grownProcess[currentStep].videoLink) return;

    setVideoPopup(true)
  }

  const [triggerTutorialStep] = usePC({
    event: 'character:active'
  });

  const [hideTony] = usePC({
    event: 'character:hide'
  });

  const [triggerPhaseAnim] = usePC({
    event: 'grown:feature:on'
  });

  const tapSequence = useCallback((e) => {
    if (e) e.preventDefault();

    if (currentTutorialStep < 1 && !tutorialCompleted) return;

    //hide tony inside howitsgrown
    if (!tutorialCompleted) {
      completeTutorial();
      hideTony();
    }

    const nextStep = currentStep + 1;
    setCurrentStep(nextStep % sceneData.grownProcess.length);

    ReactGA.event({
      category: "Experience",
      action: "Change Grown Step",
      label: `${sceneData.title} - ${nextStep}`
    });
  }, [tutorialCompleted, sceneData, currentTutorialStep, currentStep]);

  const handleTutorial = (step) => {
    if (tutorialCompleted) return;

    const stepData = grownTutorial[step];

    triggerTutorialStep({ step: stepData });


    if (stepData.duration) {
      setTimeout(() => {
        setCurrentTutorialStep(step + 1);
      }, stepData.duration);
    } else {
      setTimeout(() => {
        tapSequence();
      }, 9100);
    }
  }

  // Resets every time the component is visible
  useEffect(() => {
    if (active) return;

    setCurrentStep(-1);
  }, [active])

  // Handles the animation
  useEffect(() => {
    if (currentStep < 0) return;

    triggerPhaseAnim({
      animationName: sceneData.grownProcess[currentStep].animation
    });
  }, [currentStep]);


  useEffect(() => {
    if (!tutorialCompleted) return;

    tapSequence();
  }, [active]);


  // Triggers the tutorial when active
  useEffect(() => {
    if (!active) return;
    if (tutorialCompleted) return;
    if (!sceneData) return;

    handleTutorial(currentTutorialStep)
  }, [active, currentTutorialStep]);


  // Changes the data for each tutorial
  useEffect(() => {
    if (!sceneData) return;
    if (sceneData.slug === 'reception') return;
    if (tutorialCompleted) return;

    switch (sceneData.slug) {
      case 'plantation':
        setGrownTutorial(grownPlantation)
        break;
      case 'tunnels':
        setGrownTutorial(grownTunnels)
        break;
      case 'glasshouse':
        setGrownTutorial(grownGlasshouse)
        break;
      case 'farmlands':
        setGrownTutorial(grownFarmlands)
        break;
      default:
        setGrownTutorial(null)
    }

  }, [sceneData]);

  return (
    <HowItsGrownWrapper videoOpen={videoPopup} id='how-its-grown-wrapper' onClick={tapSequence} className={`${active ? 'active' : ''}`}>

      {sceneData && (
        <Timeline
          grownData={sceneData.grownProcess}
          currentStep={currentStep}
        />
      )}

      {currentStep >= 0 && (
        <Stamp
          borderColor={styles.theme.baseLight}
          bgColor={styles.theme.yellow}
          width={22}
          height={11}
          scale={12}
          className='grown-stamp'
        >

          <div className='plant-hand-icon-wrapper' onClick={(e) => triggerVideoPopup(e, currentStep)}>
            <img
              className='plant-hand-icon'
              src={sceneData?.grownProcess[currentStep].icon === 'plant' ? PlantHandIcon : PlayIcon} alt="Plant Hand Icon"
            />
          </div>
          <p>{sceneData.grownProcess[currentStep].copy}</p>

          <span
            className="step-indicator"
            dangerouslySetInnerHTML={setHTML(`<b>${currentStep + 1}</b> of <b>${sceneData.grownProcess.length}</b>`)}
          ></span>
        </Stamp>
      )}

      {currentStep >= 0 && videoPopup && (
        <Video
          src={sceneData.grownProcess[currentStep].videoLink}
          className='video-player'
          closePopup={() => setVideoPopup(false)}
          linkOut={false}
        />
      )}


      <TitleStamp
        title="How it's grown"
        background='green'
        stampBG='yellow'
        stampPos={{ top: true, left: false }}
        large={true}
        border={styles.theme.baseLight}
      />

    </HowItsGrownWrapper>
  )
}

export default HowItsGrown
