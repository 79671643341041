import React from 'react'
import styled from 'styled-components'

import { styles } from '../../../styles/AppTheme'
import ArrowLeft from '../../../assets/images/icons/arrow-left.svg'
import ArrowRight from '../../../assets/images/icons/arrow-right.svg'

const ProduceSliderWrapper = styled.div`
  width: 100%;
  max-width: 210px;
  justify-content: center;
  align-items: center;
  position: relative; 
  padding: .375rem .75rem;
  background-color: #fff;
  border-radius: 1.5rem;
  margin: 0 auto 1.5rem;
  pointer-events: none;

  .month-selector {
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: row;
    left: 50%;
    transform: translateX(-50%);
    padding: .325rem 0;
    pointer-events: auto;
    justify-content: center;
    align-items: center;

    button {
      height: 40px;
      width: 40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 12px;

      &.next-btn {
        right: 0;
      }

      &.prev-btn {
        left: 0;
      }

      img {
        width: 100%;
      }
    }

    h3 {
      color: #328E39;
      font-size: 1.25rem;
      text-transform: uppercase;
      padding-top: 2px;
    }

    h3::before {
      content: '';
      background-color: #FF0000;
      width: .25rem;
      height: .25rem;
      position: absolute;
      top: 2px;
      left: 50%;
      border-radius: 50%;
      transform: translateX(-50%);
    }
  }

  @media screen and (min-width: 768px) {
    max-width: 600px;
    padding: .675rem .75rem .5rem .75rem;
    margin-bottom: 2rem;
  }

  .helper {
    position: absolute;
    left: 50%;
    top: -1.5rem;
    transform: translateX(-50%);
    font-size: .75rem;
  }

  ${'' /* .stamp {
    position: absolute;
    top: -2.5rem;
    left: ${props => props.offset}%;
    transform: translateX(-${props => props.offset}%);
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    @media screen and (min-width: 768px) {
      top: -3.25rem;
      height: 4em;
    }

    &.active {
      opacity: 1;
    }

    .inner-content {
      font-size: 0.875rem;

      @media screen and (min-width: 768px) {
        font-size: 1rem;
      }
      p {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 1;
      }
    }
  } */}

  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    margin: 0;
    color: #000;
    position: absolute;
    width: 100%;
    padding: 0 1rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    li {
      text-transform: uppercase;
      font-weight: 700;
      position: relative;
      font-size: .625rem;
      padding-top: .5rem;

      @media screen and (min-width: 768px) {
        font-size: .875rem;
      }

      &.active {
        color: ${styles.theme.secondaryColour};
        &::before {
          background-color: #ED1A39;  
        }
      }
    }

    li::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: #E8E8E8;  

      @media screen and (min-width: 768px) {
        height: 6px;
        width: 6px;
        border-radius: 3px;
      }
    }
  }

  input[type=range] {
    height: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    background: none;
    pointer-events: auto;
  }
  input[type='range'], 
  input[type='range']:focus, 
  input[type='range']:active, 
  input[type='range']::-moz-focus-inner, 
  input[type='range']:-moz-focusring {
      border: 0;
      outline: none;
  }
  input[type=range]::-webkit-slider-thumb {
    ${'' /* uncomment bg color to see thumb of slider */}
    background-color: #FFFFFF; 
    border: 0;
    height: 30px;
    width: 15px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0px;
  }
`;

const allMonths = [
  {
    title: 'January',
    slug: 'jan'
  }, {
    title: 'February',
    slug: 'feb'
  }, {
    title: 'March',
    slug: 'mar'
  }, {
    title: 'April',
    slug: 'apr'
  }, {
    title: 'May',
    slug: 'may'
  }, {
    title: 'June',
    slug: 'jun'
  }, {
    title: 'July',
    slug: 'jul'
  }, {
    title: 'August',
    slug: 'aug'
  }, {
    title: 'September',
    slug: 'sep'
  }, {
    title: 'October',
    slug: 'oct'
  }, {
    title: 'November',
    slug: 'nov'
  }, {
    title: 'December',
    slug: 'dec'
  }
]

const ProduceSlider = ({handleChange, currentMonth, clickable}) => {
 
  const handlePrevMonth = () => {
    if(!clickable) return;

    if ( currentMonth <= 0 ) {
      handleChange(11);
    } else {
      const stepChange = (currentMonth - 1) % 12;
      handleChange(stepChange);
    }
  }

  const handleNextMonth = () => {
    if(!clickable) return;
    
    const stepChange = (currentMonth + 1) % 12;
    handleChange(stepChange);
  }
  
  return (
    <ProduceSliderWrapper>

        <div className="month-selector">
          <button className='prev-btn' onClick={handlePrevMonth}>
            <img src={ArrowLeft} alt="Left Arrow Icon" />
          </button>

          <h3>{allMonths[currentMonth].title}</h3>

          <button className='next-btn' onClick={handleNextMonth}>
            <img src={ArrowRight} alt="Right Arrow Icon" />
          </button>
        </div>

      
    </ProduceSliderWrapper>
  )
}

export default ProduceSlider
