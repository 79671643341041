import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { usePC } from "@unbnd-operations/react-hooks"

import ConfigContext from '../../global/contexts/config-context'
import AppContext from '../../global/contexts/app-context'

import Navbar from '../_library/navbar/Navbar'
import BottomNav from './general/BottomNav'
import HandSelector from './general/HandSelector'
import Seasonality from './seasonality/Seasonality'
import Product from './product/Product'
import HowItsGrown from './grown/HowItsGrown'

const ExperienceWrapper = styled.div`
  height: 100%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms linear;
  color: #FFFFFF;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;

  &.active {
    opacity: 1;
  }
`;

const menuItemsData = [
  {
    name: 'Reset AR Placement',
    id: 'reset-ar'
  }, {
    name: 'View Tutorial',
    id: 'reset-tutorial'
  }
];

const Experience = () => {
  // Contexts
  const {config: { setupDone } } = useContext(ConfigContext);
  const {
    app: { sceneData, sceneState, mapOpen },
    tutorialStep,  
    nextTutorialStep,
    tutorialCompleted,
    skipTutorial,
    resetTutorial
  } = useContext(AppContext);

  // Component State
  const [isHandPickerActive, setIsHandPickerActive] = useState(false);
  const [showBack, setShowBack] = useState(false);
  const [showBottomBar, setShowBottomBar] = useState(false);
  const [showHandpicker, setShowHandpicker] = useState(false);
  const [grownTutorialCompleted, setGrownTutorialCompleted] = useState(false);
  const [seasonTutorialCompleted, setSeasonTutorialCompleted] = useState(false);

  // PlayCanvas Events
  const [triggerPickerDown] = usePC({event: 'picker:down'});
  const [triggerPickerUp] = usePC({event: 'picker:up'});
  const [arRecenter] = usePC({event: 'ar:recenter'});
  const [triggerCharacter] = usePC({event: 'character:active'});
  const [showTony] = usePC({event: 'character:show'});

  usePC({event: 'tutorial:skip', callback: () => {
    setGrownTutorialCompleted(true);
    setSeasonTutorialCompleted(true);
  }});

  usePC({event: 'tutorial:reset', callback: () => {
    setGrownTutorialCompleted(false);
    setSeasonTutorialCompleted(false);
  }});

  // Menu item click
  const handleMenuItemClick = itemId => {
    switch (itemId) {
      case 'reset-ar':
        arRecenter();
        break;
      case 'reset-tutorial':
        if(!tutorialCompleted) return; 
        
        resetTutorial();
        break;
      case 'skip-tutorial':
        skipTutorial();
        break;
      default:
        break;
    }
  }

  const handlePickerDown = (e) => {
    // e.preventDefault();

    if(tutorialStep < 7 && !tutorialCompleted) return;

    setIsHandPickerActive(true)
  }

  const resetTony = () => {
    showTony();

    if (!tutorialCompleted) return;
    triggerCharacter({step: sceneData.character});
  }

  // Enable/Disable Hand picker
  useEffect(() => {
    // console.log({isHandPickerActive})
    if(isHandPickerActive) {
      triggerPickerDown();
    } else {
      triggerPickerUp();
    }
  }, [isHandPickerActive]);

  // Handle UI Visibility for different states
  useEffect(() => {
    if(sceneData?.slug === 'reception') {
      if(tutorialCompleted) {
        setShowBottomBar(true);
      } else {
        return;
      }
    }

    switch(sceneState) {
      case 'product':
        setShowBottomBar(false);
        setShowBack(true);
        setShowHandpicker(false);
        break;
      case 'seasonality':
        setShowBottomBar(false);
        setShowBack(seasonTutorialCompleted ? true : false);
        setShowHandpicker(false);
        break;
      case 'grown':
        setShowBottomBar(false);
        setShowBack(grownTutorialCompleted ? true : false);
        setShowHandpicker(false);
        break;
      case 'main':
      default:
        setShowBottomBar(true);
        setShowBack(false);
        setShowHandpicker(true);
        resetTony();
    }
  }, [sceneState, sceneData, grownTutorialCompleted, seasonTutorialCompleted, tutorialCompleted]);

  // Hide handpicker on reception
  useEffect(() => {
    if(!sceneData) return;

    if(sceneData?.slug !== 'reception'){
      if(tutorialStep < 5 && !tutorialCompleted) {
        setShowHandpicker(false);
      } else {
        setShowHandpicker(true);
      }
      
    } else {
      setShowHandpicker(false);

      // Bring up the map icon in the right spot
      if(tutorialStep < 3 && !tutorialCompleted) {
        setShowBottomBar(false);
      } else {
        setShowBottomBar(true);
      }
    }
  }, [sceneState, sceneData, tutorialStep, tutorialCompleted]);

  // Hide bottom bar when Map is open
  useEffect(() => {
    if(mapOpen) {
      setShowBottomBar(false);
    } else {
      setShowBottomBar(true);
    }
  }, [mapOpen]);

  useEffect(() => {
    if(!tutorialCompleted) return;

    const characterInfo = sceneData?.character;
    triggerCharacter({step: characterInfo})

  }, [sceneData, tutorialCompleted]);

  return (
    <ExperienceWrapper id="experience" className={setupDone ? 'active' : 'active'} >
      <Navbar
        menuItems={menuItemsData}
        handleMenuItem={handleMenuItemClick}
        showBack={showBack}
      />

      <HandSelector active={isHandPickerActive} />

      <Seasonality 
        active={sceneState === 'seasonality'} 
        tutorialCompleted={seasonTutorialCompleted}
        completeTutorial={() => setSeasonTutorialCompleted(true)}
      />

      <HowItsGrown 
        active={sceneState === 'grown'}
        tutorialCompleted={grownTutorialCompleted}
        completeTutorial={() => setGrownTutorialCompleted(true)}
      />

      {sceneState === 'product' && (
        <Product />
      )}

      {showBottomBar && (
        <BottomNav
          handleTouchStart={handlePickerDown}
          handleTouchEnd={() => setIsHandPickerActive(false)}
          isHandPickerActive={isHandPickerActive}
          showHandpicker={showHandpicker}
          scene={sceneData}
          tutorialStep={tutorialStep}
          nextTutorialStep={nextTutorialStep}
          tutorialCompleted={tutorialCompleted}
        />
      )}
    </ExperienceWrapper>
  )
}

export default Experience
