import React, { useState } from 'react'
import styled from 'styled-components'

import { styles } from '../../../styles/AppTheme'
import CopyIcon from '../../../assets/images/icons/exclamation-icon.svg'

const StyledCopy = styled.button`
  pointer-events: auto;
  padding: 1rem 2rem;
  background: ${styles.theme.primaryColour};
  box-shadow: 0 6px 0 ${styles.theme.primaryShadow};
  color: ${styles.theme.invertedTextColour};
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translateX(-50%);
  border-radius: 100px;
  font-size: 1rem;
  border: none;
  font-family: ${styles.headingFont};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;

  .symbol {
    margin-right: 0.5rem;
    width: 26px;
    height: 26px;
  }

  span {
    display: block;
    white-space: nowrap;
  }
`;

const LinkCopy = ({url, className = ''}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    if(!url) return;
    let tempInput = document.createElement("textarea");
    tempInput.textContent = url;
    tempInput.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(tempInput);
    tempInput.select();

    try {
      let cliptry = document.execCommand("copy");  // Security exception may be thrown by some browsers.
      if(cliptry){
        setIsCopied(true);
      }
      return cliptry;
    }
    catch (err) {
      console.warn("Copy to clipboard failed.", err);
      return false;
    }
    finally {
        document.body.removeChild(tempInput);
    }
  }

  return (
    <StyledCopy className={`btn-copy ${className}`} onClick={copyToClipboard}>
      <>
        <img src={CopyIcon} className="symbol" alt="Copy Icon" />
        <span>{isCopied ? 'Link Copied' : 'Copy Link Now'}</span>
      </>
    </StyledCopy>
  )
}

export default LinkCopy
