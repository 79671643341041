import React, { useContext} from 'react'
import styled from 'styled-components'

import { styles } from '../../styles/AppTheme'
import CameraPermErr from './CameraPermErr'
import MotionPermErr from './MotionPermErr'
import BrowserErr from './BrowserErr'
import DesktopErr from './DesktopErr'

import ConfigContext from '../../global/contexts/config-context'
import Heading from '../_library/typography/Heading'

import ErrorLogo from '../../assets/images/pf-logo.svg';

const ErrorScreensWrapper = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: ${styles.theme.baseLight};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 1.5rem;
  z-index: 99999;
  pointer-events: auto;
  
  .footer-heading {
    position: absolute;
    left: 50%;
    bottom: 1.25rem;
    transform: translatex(-50%);
    text-transform: uppercase;
    width: 100%;
    font-size: .875rem;
  }

  .error-logo {
    width: 230px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-1.75rem, -1.375rem);
  }
`

const Error = () => {
  const {
    config: {
      error
    }
  } = useContext(ConfigContext);

  return (
    <ErrorScreensWrapper>
      <img className="error-logo" src={ErrorLogo} alt="Perfection Fresh Logo" />

      {error === 'camera' && <CameraPermErr />}
      {error === 'motion' && <MotionPermErr />}
      {error === 'browser' && <BrowserErr />}
      {error === 'desktop' && <DesktopErr />}

      <Heading 
        priority="h4"
        className="footer-heading"
      >
        Explore the world of taste in AR
      </Heading>
    </ErrorScreensWrapper>
  )
}

export default Error