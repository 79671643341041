import React, {useEffect} from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import { styles } from '../../styles/AppTheme'

import Heading from '../_library/typography/Heading'
import Paragraph from '../_library/typography/Paragraph'
import QrCode from '../_library/qr/QrCode'

const DesktopErrWrapper = styled.div `

  .copy-wrapper {
    margin-bottom: 2rem;
    .error-heading {
      margin-bottom:0.75rem;
    }
  }

  .qr-code {
    width: 250px;
    height: 250px;
    margin: 0 auto;
  }
`

const DesktopErr = () => {
  useEffect(() => {
    ReactGA.event({
      category: "Error",
      action: "Desktop"
    });
  }, [])

  return (
    <DesktopErrWrapper>
      <div className="copy-wrapper">
        <Heading 
          priority="h2"
          content="This is a mobile experience!"
          className="error-heading"
        />
        <Paragraph 
          content="Please scan the below qr code to open the experience on your smart phone."
        />
      </div>
      <QrCode 
        fg={styles.theme.baseDark}
        bg={styles.theme.baseLight}
        url={window.location.href}
      />
    </DesktopErrWrapper>
  )
}

export default DesktopErr
