const tutorial = [
  {
    scene: 'glasshouse',
    step: 1,
    text: 'COME AND EXPLORE HOW THE VARYING SEASONS AFFECT HOW OUR PRODUCE GROWS AND HOW WE MANAGE SUPPLY THROUGHOUT THE YEAR!',
    audio: 'vo-seasonality1',
    duration: 8500,
    animation: 'talk',
    position: {
      glasshouse: {
        x: -0.7,
        y: 0.01,
        z: 0.134
      },
      plantation: {
        x: 0.34,
        y: 0.01,
        z: 0.472
      },
      farmlands: {
        x: -1.93,
        y: 0,
        z: -0.92
      },
      tunnels: {
        x: -1.5,
        y: 0.1,
        z: -0.118
      }
    }
  }, {
    scene: 'glasshouse',
    step: 2,
    text: 'TAP LEFT TO RIGHT TO EXPLORE EACH MONTH USING THE TIMELINE.',
    animation: 'talk',
    position: {
      glasshouse: {
        x: -0.7,
        y: 0.01,
        z: 0.134
      },
      plantation: {
        x: 0.34,
        y: 0.01,
        z: 0.472
      },
      farmlands: {
        x: -1.93,
        y: 0,
        z: -0.92
      },
      tunnels: {
        x: -1.5,
        y: 0.1,
        z: -0.118
      }
    }
  }
];

export default tutorial
