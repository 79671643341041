import React from 'react'
import styled from 'styled-components'

import { styles } from '../../../styles/AppTheme'

const StyledSolid = styled.button`
  pointer-events: auto;
  padding: 1rem 2rem;
  background: ${styles.theme.primaryColour};
  box-shadow: 0 6px 0 ${styles.theme.primaryShadow};
  color: ${styles.theme.invertedTextColour};
  border-radius: 100px;
  font-size: 1rem;
  border: none;
  font-family: ${styles.headingFont};
  text-transform: uppercase;
`;

const SolidButton = ({children, handleClick, className = ''}) => {
  return (
    <StyledSolid className={`btn-solid ${className}`} onClick={handleClick} >
      {children}
    </StyledSolid>
  )
}

export default SolidButton
