import { sendEvent } from './analytics'

export const setHTML = content => {
  return {
    __html: content
  }
}

export const linkOut = (url, sameTab = false) => {
  sendEvent({
    eventName: 'link-out',
    eventLabel: url
  });

  window.open(url, sameTab ? '_self' : '_blank');
}

export const coffeeBreak = function (time) {
	return new Promise(resolve => {
	setTimeout(() => {
			resolve();
	}, time);
	});
}