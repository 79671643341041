import React, {useEffect} from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import Paragraph from '../_library/typography/Paragraph'


const CameraPermErrWrapper = styled.div `


`;

const CameraPermErr = () => {
  useEffect(() => {
    ReactGA.event({
      category: "Error",
      action: "Camera"
    });
  }, []); 

  return (
    <CameraPermErrWrapper>
      <Paragraph>The camera permission were denied on launch. Please refresh your browser and press allow on the pop-up.</Paragraph>
    </CameraPermErrWrapper>
  )
}

export default CameraPermErr
