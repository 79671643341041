import React from 'react'
import styled from 'styled-components'

const ImageWrapper = styled.img`
  max-width: 100%;
`;

const Image = ({className, src}) => {
  return (
    <ImageWrapper 
      className={className}
      src={src}
    />
  )
}

export default Image
