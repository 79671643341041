import React from 'react'
import styled from 'styled-components'

import { styles } from '../../../styles/AppTheme'

const StyledOutlined = styled.button`
  padding: .25rem .5rem;
  box-shadow: 1px 1px 3px rgba(${styles.theme.baseDark}, 0.15);
  color: ${styles.theme.baseLight};
  border: 1px solid ${styles.theme.baseLight};
`;

const OutlinedButton = ({children, handleClick, resetScroll, className = ''}) => {

  const handleClickEvent = () => {
    handleClick();
    resetScroll();
  }

  return (
    <StyledOutlined className={`btn-outlined ${className}`} onClick={handleClickEvent} >
      {children}
    </StyledOutlined>
  )
}

export default OutlinedButton
