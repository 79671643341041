import React, { useState, useReducer, useEffect, useContext, useRef } from 'react'
import ReactGA from 'react-ga'

import AppContext from './contexts/app-context'
import ConfigContext from './contexts/config-context'
import appReducer from './reducers/app-reducer'
import {
  TOGGLE_AUDIO,
  CHANGE_STATE,
  TRIGGER_PRODUCT,
  CHANGE_SCENE,
  TOGGLE_MAP
} from './action-types'

import { usePC } from "@unbnd-operations/react-hooks"

import scenes from '../data/scenes'
import tutorial from '../data/tutorial'

const AppState = (props) => {
  const {config: { arPlaced, setupDone }} = useContext(ConfigContext);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const initalState = {
    audioOn: true,
    sceneData: null,
    sceneState: 'main',
    mapOpen: false,
    currentProduct: -1,
    tutorialStep: -1,
  };

  const [state, dispatch] = useReducer(appReducer, initalState);

  // * Tutorial
  const [tutorialStep, setTutorialStep] = useState(-1);
  const [tutorialCompleted, setTutorialCompleted] = useState(false);
  const timeoutRef = useRef(null);

  const [triggerTutorial] = usePC({event: 'character:active'});
  const [showTony] = usePC({event: 'character:show'});
  const [hideScene] = usePC({event: 'scene:hide'});
  const [triggerSkip] = usePC({event: 'tutorial:skip'});
  const [triggerReset] = usePC({event: 'tutorial:reset'});

  // Next tutorial step
  const nextTutorialStep = (step) => {
    if(tutorialCompleted) return;
    if (step > tutorial.length) return;

    setTutorialStep(step);
  }

  // Play tutorial step
  const playTutorialStep = (step) => {
      if (timeoutRef.current) {
      console.log("Removing");
      clearTimeout(timeoutRef.current);
    }

    if (step > tutorial.length) return;
    if (tutorialCompleted) return;

    const stepData = tutorial[step];
    if(state.sceneData?.slug !== tutorial[step].scene) return;

    showTony();
    triggerTutorial({step: stepData});

    if (stepData.duration) {
      console.log('Setting');
      timeoutRef.current = setTimeout(() => {
        const nextStep = tutorialStep + 1;
        nextTutorialStep(nextStep);
      }, stepData.duration);
    }
  }

  const skipTutorial = () => {
    setTutorialCompleted(true);
    setTutorialStep(tutorial.length - 1);
    triggerSkip();
  }

  // Trigger "current" tutorial step
  useEffect(() => {

    if (!state.sceneData) return;
    if (tutorialStep < 0) return;

    if(tutorialStep !== tutorial.length - 1 && tutorialCompleted) {
      setTutorialStep(tutorial.length - 1);
      return;
    }

    playTutorialStep(tutorialStep);
  }, [state.sceneData, tutorialStep, tutorialCompleted])

  const [audioOn, isLoaded] = usePC({event: 'audio:on', debug: true});
  const [audioOff] = usePC({event: 'audio:off', debug: true});

  // Trigger tutorial on setup done
  useEffect(() => {
    if(!setupDone) return;
    setTutorialStep(0);
    // const audioTO = setTimeout(() => {
    //   audioOff();
    //   clearTimeout(audioTO);
    // }, 1000);
  }, [setupDone]);

  // * Handle App Audio
  const toggleAudio = () => {
    dispatch({
      type: TOGGLE_AUDIO,
      payload: !state.audioOn
    })
  }

  useEffect(() => {
    if (!isLoaded) return;
    console.log('test')
    console.log(`state.sceneData`, state.sceneData)
    if(state.audioOn) {
      audioOn();
      ReactGA.event({
        category: "Experience",
        action: "Audio On"
      });
    } else {
      audioOff();
      ReactGA.event({
        category: "Experience",
        action: "Audio Off"
      });
    }
  }, [state.audioOn, isLoaded, state.sceneData]);

  // Toggle Map
  const openMap = () => {
    dispatch({
      type: TOGGLE_MAP,
      payload: true
    })
    ReactGA.event({
      category: "Experience",
      action: "Open Map"
    })
  }

  const closeMap = () => {
    dispatch({
      type: TOGGLE_MAP,
      payload: false
    })
  }

  const [triggerMapOpen] = usePC({event: 'map:open'});
  const [triggerMapClose] = usePC({event: 'map:close'});
  const [hideTony] = usePC({event: 'character:hide'});

  useEffect(() => {
    if (!isLoaded) return;

    if(state.mapOpen) {
      hideScene();
      hideTony();

      setTimeout(() => {
        triggerMapOpen();
      }, 100);
    } else {
      triggerMapClose()
    }
  }, [state.mapOpen, isLoaded]);

  const changeSceneFromMap = (data) => {
    closeMap();

    setTimeout(() => {
      changeScene(data);
    }, 600)
  }

  // Scnene navigation functionality
  const changeScene = ({name}) => {
    const scene = scenes.find(item => item.slug === name);
    // console.log(`CHANING SCENE: ${name}`);
    // console.log(`SceneData: ${scene}`);

    if(!scene) return;

    dispatch({
      type: CHANGE_SCENE,
      payload: scene
    });
    ReactGA.event({
      category: "Experience",
      action: "Change Scene",
      label: scene.title
    })
  }

  const [triggerScene] = usePC({event: 'scene:show'});
  usePC({event: 'map:picked', callback: changeSceneFromMap})

  useEffect(() => {
    if(!state.sceneData) return;

    // console.log(state);
    triggerScene({name: state.sceneData.slug});
  }, [state.sceneData]);


  // Hand Picker functionality
  const [triggerHandpicker, setTriggerHandpicker] = useState(null);
  const [pickedValue, setPickedValue] = useState(null); // Only there to fix tutorial
  const [productEnter] = usePC({event: 'product:enter'});
  const [productExit] = usePC({event: 'product:exit'});
  const [seasonEnter] = usePC({event: 'season:enter'});
  const [seasonExit] = usePC({event: 'season:exit'});
  const [grownEnter] = usePC({event: 'grown:enter'});
  const [grownExit] = usePC({event: 'grown:exit'});

  const handleHandPicker = ({name}) => {
    setPickedValue(name);
  }

  useEffect(() => {
    if(pickedValue === null) return;

    if(!tutorialCompleted) {
      if(pickedValue !== 'qukes') {
        nextTutorialStep(7);
        return;
      }
    }

    setTriggerHandpicker(pickedValue);
    setPickedValue(null);
  }, [pickedValue])

  useEffect(() => {
    if(triggerHandpicker  === null) return;

    if(!tutorialCompleted) {
      setTutorialCompleted(true);
    }

    switch(triggerHandpicker) {
      case 'seasonality':
        dispatch({
          type: CHANGE_STATE,
          payload: 'seasonality'
        });
        break;
      case 'how-its-grown':
        dispatch({
          type: CHANGE_STATE,
          payload: 'grown'
        });
        break;
      default:
        const productIndex = state.sceneData.products.findIndex(item => item.slug === triggerHandpicker);
        if(productIndex < 0) return;

        dispatch({
          type: TRIGGER_PRODUCT,
          payload: {
            state: 'product',
            product: productIndex
          }
        });
    }

    setTriggerHandpicker(undefined);

  }, [triggerHandpicker]);

  usePC({event: 'picker:grab', callback: handleHandPicker});

  // Send exit to PC
  const triggerStateGoBack = (currentState, mapOpen) => {
    if(mapOpen) {
      closeMap();
      return;
    }

    switch(currentState) {
      case 'product':
        productExit();
        break;
      case 'seasonality':
        seasonExit();
        break;
      case 'grown':
        grownExit();
        break;
      default:
        console.log('Weird exit');
    }

    dispatch({
      type: CHANGE_STATE,
      payload: 'main'
    });
  }

  // Send enter to PC
  useEffect(() => {
    switch(state.sceneState) {
      case 'product':
        if(state.currentProduct < 0) return;

        // console.log(state);
        const productName = state.sceneData.products[state.currentProduct].slug;
        return productEnter({name: productName});
      case 'seasonality':
        return seasonEnter();
      case 'grown':
        return grownEnter();
      default:
        // console.log('main state');
    }
  }, [state.sceneState]);

  // Skip Tutorial
  const resetTutorial = () => {
    setTutorialStep(0);
    setTutorialCompleted(false);
    dispatch({
      type: CHANGE_STATE,
      payload: 'main'
    });

    setTimeout(() => {
      triggerReset();
      changeScene({name: 'reception'});
    }, 1000);

    ReactGA.event({
      category: "Tutorial",
      action: "Reset tutorial"
    });
  }

  // Trigger Reception on AR Placed
  useEffect(() => {
    if(!arPlaced) return;

    changeScene({name: 'reception'});
  }, [arPlaced])

  const triggerVideoPlaying = () => {
    setVideoIsPlaying(true);
  }

  const triggerVideoPaused = () => {
    setVideoIsPlaying(false);
  }

  return (
    <AppContext.Provider value={{
      app: state,
      toggleAudio,
      triggerStateGoBack,
      openMap,
      closeMap,
      tutorialStep,
      nextTutorialStep,
      tutorialCompleted,
      resetTutorial,
      skipTutorial,
      videoIsPlaying,
      triggerVideoPlaying,
      triggerVideoPaused
    }}>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppState
