import React, { useContext, useEffect } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import Paragraph from '../_library/typography/Paragraph'
import LinkCopy from '../_library/buttons/LinkCopy'

import ConfigContext from '../../global/contexts/config-context'
import { styles } from '../../styles/AppTheme'

const BrowserErrWrapper = styled.div `
  .device-list {
    font-size: .75rem;
    text-transform: uppercase;
    font-family: ${styles.headingFont};
  }
`

const BrowserErr = () => {
  const {config: { deviceInformation }} = useContext(ConfigContext);
  console.log(deviceInformation);

  useEffect(() => {
    ReactGA.event({
      category: "Error",
      action: "Browser"
    });
  }, []);
  return (
    <BrowserErrWrapper>
      <Paragraph>Your browser does not support the features required for WebAR. Click the button below to copy the link, then paste it to a supported browser.</Paragraph>
      <p className="device-list">{deviceInformation.vendor === 'Apple' ? 'Safari' : `${deviceInformation.vendor === 'Samsung' ? 'Samsung Browser, ' : ''}Google Chrome, Firefox`}</p>

      <LinkCopy 
        url={window.location.href}
      />
    </BrowserErrWrapper>
  )
}

export default BrowserErr
