import { useState, useEffect } from 'react';

const usePC = (reference) => {
  const [sceneLoaded, setSceneLoaded] = useState(false);
  const { event, callback, once } = reference;

  // Send event to PlayCanvas
  const dispatchEvent = (data) => {
    if(!sceneLoaded) return;
    window.pc.app.fire(event, data);
  }

  // Handle callback to React
  const handleCallback = (data) => {
    if(!callback) return;
    callback(data);

    if(!once) return;
    removeListener();
  }

  // Set listener
  const setListener = () => {
    window.pc.app.on(event, handleCallback);
  }

  // Remove listener
  const removeListener = () => {
    window.pc.app.off(event, handleCallback);
  }

  // When the scene is loaded, handle listeners
  useEffect(() => {
    if(!sceneLoaded) return;
    setListener();

    return () => removeListener();
  }, [sceneLoaded])

  // Check that the PlayCanvas scene is ready
  useEffect(() => {
    if(sceneLoaded) return;

    let interval = setInterval(() => {
      // Playcanvas Check
      if(!window.pc.app) return;

      // App is ready
      clearInterval(interval);
      setSceneLoaded(true);
    }, 200); 
    
  }, []);

  // Return
  return dispatchEvent;
}

export default usePC;