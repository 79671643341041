import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga'

import ConfigContext from '../../global/contexts/config-context';
import { styles } from '../../styles/AppTheme';
import { usePC } from "@unbnd-operations/react-hooks"

import Heading from '../_library/typography/Heading';
import Stamp from '../custom/Stamp';
import PFLogo from '../../assets/images/pf-logo.svg';
import eighthWallLogo from '../../assets/images/poweredby8thwall.svg'

import StampOne from '../../assets/images/loading/loading-stamp-1.jpg';
import StampTwo from '../../assets/images/loading/loading-stamp-2.jpg';
import StampThree from '../../assets/images/loading/loading-stamp-3.jpg';
import StampFour from '../../assets/images/loading/loading-stamp-4.jpg';

import LoadingVO from '../../assets/audio/loading-combined-short.mp3'

const loadingStamps = [StampOne, StampTwo, StampThree, StampFour];

const LoadingScreen = styled.section`
  width: 100%;
  height: 100%;
  background: ${styles.theme.mainBackground};
  position: absolute;
  z-index: 800;
  pointer-events: auto;
  top: 0;

  .heading {
    color: ${styles.theme.baseLight};
    text-transform: uppercase;
    font-size: 1.5rem;
    transition: all 300ms;
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(var(--vh, 1vh) * 6 + 90px);

    @media screen and (min-width: 768px) {
      top: calc(var(--vh, 1vh) * 6 + 250px);
    }
  }

  .logo {
    width: 88px;
    height: 88px;
    margin-bottom: .75rem;
    transition: all 300ms;
    position: absolute;
    left: 50%;
    top: calc(var(--vh, 1vh) * 3 + 44px);
    transform: translate(-50%, -50%);

    @media screen and (min-width: 768px) {
      width: 240px;
      height: 240px;
      top: calc(var(--vh, 1vh) * 3 + 120px);
    }
  }

  .logo-wrapper {
    text-align: center;
    padding: 2rem;
    position: relative;
    z-index: 2; 
    height: calc(var(--vh, 1vh) * 88);
  }

  &.is-loading {
    .logo {
      width: 250px;
      height: 250px;
      top: 50%;
      transform: translate(-50%,-50%);
    }

    .heading {
      position: absolute;
      top: auto;
      bottom: calc(var(--vh, 1vh) * 2);
    }
  }

  .stamp {
    position: absolute;
    z-index: 1;
    box-shadow: 0 0 0 0;
    opacity: 0;
    transition: opacity 300ms;

    &.stamp-1 {
      left: 10vw;
      top: calc(var(--vh, 1vh) * 10);
      transform: rotate(11deg);

      @media screen and (min-width: 768px) {
        height: 22em;
        width: 22em;
      }
    }

    &.stamp-2 {
      right: 8vw;
      bottom: calc(var(--vh, 1vh) * 30);
      transform: rotate(11deg);

      @media screen and (min-width: 768px) {
        height: 22em;
        width: 22em;
      }
    }

    &.stamp-3 {
      left: 40vw;
      top: calc(var(--vh, 1vh) * 4);
      transform: rotate(-15deg);

      @media screen and (min-width: 768px) {
        height: 18em;
        width: 18em;
      }
    }

    &.stamp-4 {
      left: 6vw;
      bottom: calc(var(--vh, 1vh) * 26);
      transform: rotate(-15deg);

      @media screen and (min-width: 768px) {
        height: 18em;
        width: 18em;
      }
    }

    &.visible {
      opacity: 1;
    }
  }

  .eighthwall-logo {
    width: 110px;
    filter: invert(1);
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  background: white;
  height: calc(var(--vh, 1vh) * 11);
  position: absolute;
  left: 0;
  bottom: 0;
  display: grid;
  place-items: center;

  &::before {
    content: '';
    border-top: .5rem dotted ${styles.theme.baseLight};
    position: absolute;
    top: -.25rem;
    left: -.5rem;
    width: 100%;
  }

  @keyframes loading {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  div {
    font-size: .875rem;
    font-family: ${styles.headingFont};
    text-align: center;
    text-transform: uppercase;  
    position: absolute;
    opacity: 0;
    transition: opacity 300ms linear 0ms;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }

    &.loading {
      animation: loading;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
    }
  }
`;

const LoadingProgress = ({complete, completed}) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if(!completed) return; 
    setIsReady(true);

    return () => setIsReady(false);
  }, [completed]);

  return (
    <LoadingWrapper>
      <div onClick={complete} className={`swipe-start ${isReady ? 'active' : ''} ${!completed ? 'loading' : ''}`}>{completed ? 'Tap to start' : 'Loading...'}</div>
    </LoadingWrapper>
  )
}

const StampWrapper = ({stamp, index, progress}) => {
  const showAfter = 25 * index + 10;
  return (
    <Stamp 
      key={index}
      scale={10}
      height={index > 1 ? 11 : 13}
      width={index > 1 ? 11 : 13}
      borderColor={styles.theme.baseLight}
      className={`stamp-${index + 1} ${progress > showAfter ? 'visible' : ''}`}
    >
      <img src={stamp} alt="A stamp from the world" />
    </Stamp>
  )
}

const Loading = () => {
  const { config: { soundEnabled }, completeIntro  }  = useContext(ConfigContext);
  const audioRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingCompleted, setLoadingCompleted] = useState(false);

  const [stampsOrder, setStampsOrder] = useState([]);
  const [voProgress, setVoProgress] = useState(0);
  const [audioPlaying, setAudioPlaying] = useState(false);

  // PC Loading functions
  const [loadAssets] = usePC({
    event: 'assets:load', 
    callback: () => setIsLoading(true)
  });

  usePC({
    event: 'assets:loaded',
    callback: () => {
      setTimeout(() => {
        setLoadingCompleted(true)
      }, 1500);
    }
  });

  // Start experience
  const startExperience = () => {
    if(!loadingCompleted) return;
    completeIntro();
    ReactGA.event({
      category: "Experience",
      action: "Started AR"
    })
  }

  // Fade to progress screen and start VO
  useEffect(() => {
    if(!soundEnabled) return;

    setAudioPlaying(true);

    setTimeout(() => {
      loadAssets();
    }, 500);

  }, [soundEnabled]);

  useEffect(() => {
    if(!soundEnabled) return;
    if(!audioPlaying) return;
    let progressInterval;

    const audioElem = audioRef.current;

    audioElem.play();
    audioElem.volume = 1;

    function handleTime() {
      progressInterval = setInterval(() => {
        const currentPos = this.currentTime / this.duration;
        setVoProgress(Math.floor(currentPos * 100));
      }, 100)
    }

    function pause() {
      audioElem.pause();
    }

    function play() {
      audioElem.play();
    }

    function audioEnded() {
      clearInterval(progressInterval);
      audioElem.removeEventListener('playing', handleTime);
      audioElem.removeEventListener('ended', audioEnded);
      window.removeEventListener('blur', pause);
      window.removeEventListener('focus', play);
    }

    audioElem.addEventListener('playing', handleTime);
    audioElem.addEventListener('ended', audioEnded);
    window.addEventListener('blur', pause);
    window.addEventListener('focus', play);

    return () => {
      clearInterval(progressInterval);
      audioElem.removeEventListener('playing', handleTime);
      audioElem.removeEventListener('ended', audioEnded);
      window.removeEventListener('blur', pause);
      window.removeEventListener('focus', play);
    }
  }, [audioPlaying, soundEnabled]);

  // Randomise the loading stamps positions
  useEffect(() => {
    const newArr = loadingStamps.sort(() => Math.random() - 0.5);
    setStampsOrder(newArr);
  }, []);

  return (
    <LoadingScreen id="loading-screen" className={`loading-screen ${isLoading ? 'is-loading' : ''}`}>
      <audio ref={audioRef} src={LoadingVO} />
    
      <div className="logo-wrapper">
        <img 
          src={PFLogo} 
          className="logo" 
          alt="Perfection Fresh Logo" 
        />

        <Heading priority="h2">Explore the<br />world of taste in AR</Heading>
      </div>
      
      {isLoading && (
        <>
          {stampsOrder.map((stamp, index) => (
            <StampWrapper key={index} index={index} stamp={stamp} progress={voProgress} />
          ))}
          <LoadingProgress completed={loadingCompleted} complete={startExperience} />
        </>
      )}

      {!isLoading && (
        <div className="eighthwall-logo">
          <img src={eighthWallLogo} alt="8th Wall Logo" />
        </div>
      )}

    </LoadingScreen>
  )
}

export default Loading
