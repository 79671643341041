import React, { Fragment } from 'react'
import styled from 'styled-components'

import { styles } from '../../../styles/AppTheme'
import { linkOut, setHTML } from '../../../utils/helpers/'

import linkOutIcon from '../../../assets/images/icons/link-out-icon.svg'
import Heading from '../../_library/typography/Heading'
import BackArrow from '../../_library/buttons/BackArrow'
import SolidButton from '../../_library/buttons/SolidButton'
import Image from '../../_library/media/Image'
import Video from '../../_library/media/Video'


const RecipeContentWrapper = styled.div `
  position: absolute;
  z-index: 999;
  background-color: #fff;
  pointer-events: auto;
  height: 100%;
  width: 100%;
  top: 0;
  overflow-x: scroll;

  .back-arrow {
    position: fixed;
    top: 1rem;
    left: 2rem;
  }

  .recipe-header {
    .recipe-image {
      width: 100%;
      ${'' /* height: calc(100vw / 16 * 9); */}
      margin-bottom: 2.5rem;
    }

    .heading {
      text-transform: uppercase;
      color: ${styles.theme.baseDark};
      font-size: 1.375rem;
    }
  }


  .recipe-content {
    padding: .5rem 1.375rem 1rem;
    max-width: 600px;
    margin: 0 auto;

    .heading {
      text-transform: uppercase;
      color: ${styles.theme.baseDark};
      font-size: 1.125rem;
    }

    p + .heading {
      margin-top: 1.75rem;
    }

    .main-heading {
      color: ${styles.theme.secondaryColour};
      font-size: 1.375rem;
    }

    p {
      line-height: 1.4;
      margin-bottom: .75em;
      font-size: 0.875rem;
      color: ${styles.theme.baseDark};
      font-weight: 400;

      span {
        color: ${styles.theme.secondaryColour};
        font-weight: 500;

        &.recipe-tip {
          display: block;
          color: ${styles.theme.baseDark};
          font-style: italic;
          font-size: 0.75rem; 
          line-height: 1.3;
          font-weight: 400;
        }
      }
    }

    .recipe-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2.5rem auto;
      img {
        margin-right: 10px;
      }
    }
  }

`

const RecipeContent = ({content, closePopup}) => {
  const {image, title, recipe, button} = content;

  return (
    <RecipeContentWrapper id='recipe-content-wrapper'>

      <BackArrow 
        handleBack={closePopup}
      />

      <header className="recipe-header">
        <Image 
          src={image}
          className='recipe-image'
        />

        <Heading priority='h2'>
          {title}
        </Heading>
      </header>
      <section className="recipe-content">
        {recipe && recipe.map((item, index) => (
          <Fragment key={index}>

            <Heading priority='h3' className={`${item.main ? 'main-heading' : ''}`}>
              {item.title}
            </Heading>

            {item.content && item.content.map((step, index) => (
              <p key={index} dangerouslySetInnerHTML={setHTML(step)}></p>
            ))}

          </Fragment>
        ))}

        <SolidButton handleClick={() => linkOut(button.link)} className='recipe-btn'>
          <img src={linkOutIcon} alt="Link out button icon" />
          <span>{button.text}</span>
        </SolidButton>

      </section>

    </RecipeContentWrapper>
  )
}

const QuestionPopupWrapper = styled.div `
  width: 100%;
  height: 100%;
  pointer-events: auto;
`

const QuestionPopup = ({content, closePopup}) => {
  // console.log(`content`, content);
  return (
    <QuestionPopupWrapper id='question-popup-wrapper'>

      {content.type === 'recipe' && (
        <RecipeContent 
          content={content} 
          closePopup={closePopup} 
        />
      )}

      {content.type === 'video' && (
        <Video 
          src={content.video}
          className='video-player'
          closePopup={closePopup} 
          linkOut={content.button}
          autoplay={true}
        />
      )}

      {content.type === 'image' && (
        <Image 
          content={content}
          closePopup={closePopup}
        />
      )}
    </QuestionPopupWrapper>
  )
}

export default QuestionPopup
