import React from 'react'
import styled from 'styled-components'

const StampStyling = styled.div`
  font-size: ${props => props.size}px; /* Change font size to scale */
	width: ${props => props.width}em; 
	height: ${props => props.height}em; 
	display: inline-block;
  padding: .5em;
	background: transparent;
	position: relative;
	background-image: radial-gradient(
		transparent 0, 
		transparent .25em, 
		${props => props.border} .25em,
		${props => props.border}
	);
	background-size: 1em 1em;
	background-position: -.5em -.5em;
  pointer-events: auto;


  > .corners {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &::before,
    &::after {
      content: '';
      background-color: ${props => props.border};
      display: block;
      width: .5em;
      height: .5em;
      position: absolute;
    }

    &::before { left: 0; }
    &::after { right: 0; }

    &.top::before,
    &.top::after {
      top: 0;
    }

    &.bottom::before,
    &.bottom::after {
      bottom: 0;
    }
  }

  > .inner-content {
    background-color: ${props => props.background};
    width: 100%;
    height: 100%;
    position: relative;
  }
`;

const Stamp = ({borderColor, bgColor, scale = 16, width, height, children, className, onClick}) => {
  const handleClick = () => {
    if(!onClick) return;

    onClick();
  }

  return (
    <StampStyling 
      border={borderColor}
      background={bgColor}
      size={scale} 
      width={width}
      height={height}
      className={`stamp ${className ? className : ''}`}
      onClick={handleClick}
    >
      <div className="corners top"></div>
      <div className="corners bottom"></div>
      
      <div className="inner-content">
        {children}
      </div>
    </StampStyling>
  )
}

export default Stamp
