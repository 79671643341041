import React, { useState, useContext } from 'react'
import styled from 'styled-components'

import ConfigContext from '../../global/contexts/config-context';
import { styles } from '../../styles/AppTheme';
import CookiePrompt from '../cookie-prompt/CookiePrompt'
import Heading from '../_library/typography/Heading'
import Paragraph from '../_library/typography/Paragraph';
import Stamp from '../custom/Stamp';
import QrCode from '../_library/qr/QrCode';

import HeroImage from '../../assets/images/landing/perfection-fresh-hero.jpg'
import PFLogo from '../../assets/images/pf-logo.svg'
import Border from '../../assets/images/landing/border.png'
import SliderImageOne from '../../assets/images/landing/landing-image-farmlands.jpg'
import SliderImageTwo from '../../assets/images/landing/landing-image-plantations.jpg'
import SliderImageThree from '../../assets/images/landing/landing-image-tunnels.jpg'
import SliderImageFour from '../../assets/images/landing/landing-image-glasshouse.jpg'

import FooterImageOne from '../../assets/images/loading/loading-stamp-1.jpg'
import FooterImageTwo from '../../assets/images/loading/loading-stamp-2.jpg'


const LandingPageWrapper = styled.section`
  background: ${styles.theme.mainBackground};
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100 + 1px);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: auto;

  

  .top-wrapper {
    @media screen and (min-width: 1024px) {
      flex-direction: row;
      display: flex;
      align-items: stretch;
    }
  }
`;

const LeftWrapper = styled.div`
  background: url('${HeroImage}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 1rem .75rem;
  position: relative;

  &::after {
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 80%);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    z-index: 1;
  }

  .inner-left {
    border: 2px solid #FFFFFF;
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
  }


  .logo-wrapper {
    width: 120px;
    height: 120px;
    background: #FFFFFF;
    border-radius: 50%;
    margin-bottom: calc(var(--vh, 1vh) * 20);
  }

  .intro-content {
    position: relative;
    max-width: 360px;

    &::before {
      content: '';
      background-color: rgba(0,0,0,.05);
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .inner {
      position: relative;
      z-index: 2;
      padding: 1.25rem .75rem;
      text-align: center;
      color: ${styles.theme.invertedTextColour};
    }

    .paragraph {
      font-size: .875rem;
      margin-bottom: 0;
    }

    .heading {
      text-transform: uppercase;
      font-size: 1.125rem;
      margin-bottom: .25rem;
    }
  }

  @media screen and (min-width: 1024px) {
    flex: 0 0 50%;

    .logo-wrapper {
      width: 332px;
      height: 332px;
    }

    .inner-left {
      border: none;
      height: 100%;
      display: flex;
      ${'' /* flex-direction: column; */}
      align-items: center;
      justify-content: center;
    }
  }
`;

const RightWrapper = styled.div`
  background: ${styles.theme.secondaryColour};
  padding: 2rem 0;

  @media screen and (min-width: 1024px) {
    flex: 0 0 50%;
    overflow: hidden;
  }

  .image-wrapper {
    padding: 0;
    width: 100%;
    overflow-x: scroll;
    scroll-snap-type: x proximity;

    &::-webkit-scrollbar {
      display: none;
    }

    .stamp-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .carousel {
    display: flex;

    &::before,
    &::after {
      content: '';
      width: 1rem;
      flex: 1 0 1rem;
    }

    .stamp {
      margin: 0 .5rem;
      flex: 1 0 24em;
    }
  }
  .qr-section {
    width: 100%;
    font-size: 1.25em;
    text-transform: uppercase;


    p, span {
      font-family: ${styles.headingFont};
      text-align: center;
      color: ${styles.theme.baseLight};
    }

    .qr-wrapper {
      margin: 2em auto 0;
      width: 270px;
      padding: 20px 25px;
      border-radius: 21px;
      background-color: ${styles.theme.baseLight};
      p {
        margin-bottom: 40px;
        color: ${styles.theme.baseDark};
      }
    }

    .or {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 26px 0;

      &::before, &::after {
        display: inline-block;
        content: '';
        height: 2px;
        width: 70px;
        background-color: ${styles.theme.baseLight};
      }

      &::before {
        margin-right: 15px;
      }
      &::after {
        margin-left: 15px;
      }
    }
    .visit {
      margin-bottom: 50px;
    }
  }


`;

const FooterWrapper = styled.footer`
  padding: 2rem 1.5rem 9rem 1.5rem;

  .stamp-wrapper {
    font-size: ${props => props.size}px;
    height: 10em;
    position: relative;
    margin: 0 auto .75rem auto;
    max-width: 360px;

    .large {
      position: absolute;
      left: 38%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(11deg);
    }

    .small {
      position: absolute;
      left: 62%;
      top: 30%;
      transform: translate(-50%, -50%) rotate(-15deg);
    }
  }

  .paragraph {
    text-align: center;
    font-size: .675rem;
    margin-bottom: 1.5rem;
  }

  .links {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .footer-link {
    font-family: ${styles.headingFont};
    text-transform: uppercase;
    font-size: .675rem;
  }

  .separator {
    width: 1.6rem;
    position: relative;

    &::after {
      content:'';
      height: 100%;
      width: 1px;
      background: ${styles.theme.baseDark};
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
    }
  }
`;

const sliderImages = [
  SliderImageOne,
  SliderImageTwo,
  SliderImageThree,
  SliderImageFour
];

const LandingPage = () => {
  const { 
    config: { 
      deviceInformation
    }
  } = useContext(ConfigContext);

  const [isPromptOpen, setIsPromptOpen] = useState(true);

  return (
    <LandingPageWrapper id="landing-page">
      <div className="top-wrapper">
      
        <LeftWrapper id='left-wrapper'>
          <div className="inner-left">
            <figure className="logo-wrapper">
              <img src={PFLogo} alt="Perfection fresh logo" />
            </figure>
            <div className="intro-content">
              <img src={Border} alt="Stamp border" />
              <div className="inner">
                <Heading priority="h2">
                  Explore the World of Taste in Augmented Reality
                </Heading>
                <Paragraph>
                  Join us on an augmented reality journey of discovery through our farms, where you’ll learn all about Perfection Fresh and our amazing produce
                </Paragraph>
              </div>
            </div>
          </div>
        </LeftWrapper>

        <RightWrapper id='right-wrapper'>

          {deviceInformation.type === 'desktop' && (
            <div className="qr-section">
              <div className="qr-wrapper">
                <p>Scan the code with your mobile device to open the experience</p>
                <QrCode 
                  bg={styles.theme.baseLight}
                  fg={styles.theme.baseDark}
                  url={window.location.href}
                />
              </div>
              <span className='or'>Or</span>
              <p className='visit'>Visit<br/>{window.location.hostname}<br/>on your mobile device.</p>
            </div>
          )}

          <div className="image-wrapper">
            <div className="carousel">
              {sliderImages.map((image, index) => (
                <Stamp
                  key={index}
                  scale={10}
                  width={24}
                  height={24}
                  borderColor={styles.theme.baseLight}
                >
                  <div className="stamp-image" style={{backgroundImage: `url(${image})`}} />
                </Stamp>
              ))}
            </div>
          </div>
        </RightWrapper>

      </div>

      <FooterWrapper size={24}>
        <div className="stamp-wrapper" >
          <Stamp
            className="large"
            scale={15}
            height={10}
            width={10}
            borderColor={styles.theme.baseLight}
            bgColor={styles.theme.baseLight}
          >
            <img src={FooterImageOne} alt="Postcard stamp" />
          </Stamp>

          <Stamp
            className="small"
            scale={15}
            height={8}
            width={8}
            borderColor={styles.theme.baseLight}
            bgColor={styles.theme.baseLight}
          >
            <img src={FooterImageTwo} alt="Postcard stamp" />
          </Stamp>
        </div>
        {/* <Paragraph>
          This section is the T&C’s lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius diam arcu, in euismod magna consectetur sed.
        </Paragraph> */}
        <ul className="links">
          <li className="footer-link">
            <a href="https://www.perfection.com.au/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
          </li>
        </ul>

      </FooterWrapper>

      {isPromptOpen  && (
        <CookiePrompt handleClose={() => setIsPromptOpen(false)} />
      )}
    </LandingPageWrapper>
  )
}

export default LandingPage
