// !EDIT
const ANALYTICS_TYPE = ''; // Options are: gtag, ga, gtm
const ANALYTICS_ID = 'UA-0000000-1'; // Only if using GTAG
// !

const gtag = window.gtag;
const ga = window.ga;
const dataLayer = window.dataLayer;

// Page Tracking
export const sendPageView = ({
  path = window.location.pathname, 
  title = document.title
}) => {

  if(!ANALYTICS_TYPE) {
    console.log('No analytics enabled');
    return;
  }

  switch(ANALYTICS_TYPE) {
    case 'gtag':
      gtag('config', ANALYTICS_ID, {
        'page_title': title,
        'page_path': path
      });  
      break;

    case 'ga':
      ga('set', 'page', path);
      ga('set', 'title', title);
      ga('send', 'pageview'); 
      break; 

    case 'gtm':
      dataLayer.push({
        event: 'pageview',
        page: {
          path: path,
          title: title
        }
      });
      break;

    default:
      console.log('Analytics is not set up correctly');
  }
}

// Event Tracking
export const sendEvent = (eventData) => {

  if(!ANALYTICS_TYPE) {
    console.log('No analytics enabled');
    return;
  }

  const {
    eventName,
    eventCategory,
    eventLabel,
    eventValue,
    nonInteraction = false
  } = eventData;


  if(!eventName) {
    console.log('Requires eventName');
    console.log(eventData);
    return eventData;
  }

  switch(ANALYTICS_TYPE) {
    case 'gtag':
      gtag('event', eventName, {
        'event_label': eventLabel || "(not set)",
        'event_category': eventCategory || "(not set)",
        'event_value': eventValue || 0,
        'non_interaction': nonInteraction
      });  
      break;

    case 'ga':
      !nonInteraction 
        ? ga('send', 'event', eventCategory, eventName, eventLabel, eventValue)
        : ga('send', 'event', eventCategory, eventName, eventLabel, eventValue, {nonInteraction});
      break;  

    case 'gtm':
      dataLayer.push({
        event: eventName,
        category: eventCategory,
        label: eventLabel,
        value: eventValue,
        nonInteraction
      });
      break;

    default:
      console.log('Analytics is not set up correctly');
  }
}