import React, { useContext, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import { usePC } from "@unbnd-operations/react-hooks"

import AppContext from '../../../global/contexts/app-context'
import { styles } from '../../../styles/AppTheme'

import MapIcon from '../../../assets/images/icons/map-trifold.svg'
import TitleStamp from '../../custom/TitleStamp'

const BottomNavWrapper = styled.div`
  position: fixed;
  bottom: 2rem;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 2;

  .navbar {
    margin-top: 1.25rem;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }

  @keyframes pulse {
      0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
      }

      10% {
        opacity: .75;
      }

      100% {
        transform: translate(-50%, -50%) scale(1.3);
        opacity: 0;
      }
    }

  .hand-icon {
    width: 70px;
    height: 70px;
    background: ${props => props.isPressed ? styles.theme.baseGrey : styles.theme.secondaryColour };
    border: 2px solid ${styles.theme.baseLight};
    border-radius: 35px;
    box-shadow: 0 3px ${props => props.isPressed ? '#8E8E8E' : styles.theme.secondaryShadow };
    display: grid;
    place-items: center;
    pointer-events: none;
    opacity: 0;

    &.active {
      pointer-events: auto;
      opacity: 1;
    }

    &.tutorial-active {
      &::before,
      &::active {
        width: 60px;
        height: 60px;
      }
    }

    .picker-prompt {
      position: absolute;
      left: 50%;
      top: -20px;
      width: 300px;
      transform: translateX(-50%);
      text-transform: uppercase;
      color: ${styles.theme.baseLight};
      font-family: ${styles.headingFont};
    }
  }

  .map-icon {
    position: absolute;
    right: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    height: 64px;
    width: 64px;
    border-radius: 32px;
    display: grid;
    place-items: center;
    pointer-events: auto;

    img {
      width: 42px;
      z-index: 2;
    }
  }

  .tutorial-active {
    background: ${styles.theme.secondaryColour};


    &::before, &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      border: 10px solid ${styles.theme.secondaryColour};
      width: 54px;
      height: 54px;
      border-radius: 50%;
    }

    &::before {
      animation: pulse 2000ms infinite;
    }

    &::after {
      animation: pulse 2000ms infinite 1000ms;
    }
  }
`

const TutorialBackground = styled.div`
  height: calc(var(--vh) * 100 + 1px);
  width: 100vw;
  position: fixed;
  z-index: 1;
  background: rgba(211,209,200,.8);
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 300ms;

  &.active {
    opacity: 1;
  }
`;

const BottomNav = (
  {
    handleTouchStart,
    handleTouchEnd,
    isHandPickerActive,
    showHandpicker,
    scene,
    tutorialStep,
    nextTutorialStep,
    tutorialCompleted
  }
  ) => {
  const { app: { mapOpen, sceneData }, openMap } = useContext(AppContext);
  const [focusObject, setFocusObject] = useState(null);
  const [currentScene, setCurrentScene] = useState(null);
  const [mapHighlight, setMapHighlight] = useState(false);

  const handleFocus = useCallback(({name}) => {
    if (focusObject?.slug === name) return;
    // console.log(`Focusing: ${name}`);

    if(!tutorialCompleted) {
      if(name === 'qukes') {
        if(tutorialStep < 8) {
          nextTutorialStep(8)
        }
      }
    }

    switch(name) {
      case 'seasonality':
        setFocusObject({
          slug: name,
          title: 'Seasonality',
          background: 'green',
          border: false,
          stampBG: 'blue',
          stampPos: {
            left: true,
            top: false
          }
        });
        break;
      case 'how-its-grown':
        setFocusObject({
          slug: name,
          title: 'How it\'s grown',
          background: 'green',
          border: false,
          stampBG: 'yellow',
          stampPos: {
            left: true,
            top: false
          }
        });
        break;
      default:
        const product = scene.products?.find(item => item.slug === name);
        if(!product) return;

        const {
          title,
          logo,
          background,
          border,
          stampBackground
        } = product;

        setFocusObject({
          slug: name,
          title,
          logo,
          background,
          border,
          stampBG: stampBackground,
          stampPos: {
            top: true,
            left: true
          }
        });
    }
  }, [scene]);

  const handleBlur = () => {
    setFocusObject(null);

    if(!tutorialCompleted) {
      nextTutorialStep(7)
    }
  }

  const handleMapClick = () => {
    if(!tutorialCompleted) {
      if(tutorialStep === 4) {
        nextTutorialStep(tutorialStep + 1);
        setMapHighlight(false);
      } else {
        return;
      }
    } else {
      setMapHighlight(false);
    }

    openMap();
  }

  useEffect(() => {
    if(tutorialCompleted) return;
    // console.log(`Tutorial step is ${tutorialStep}`)
    if (tutorialStep === 3 || tutorialStep === 4) {
      setTimeout(() => {
        setMapHighlight(true);
      }, 5000);
    } else {
      setMapHighlight(false);
    }

    return () => setMapHighlight(false);
  }, [tutorialStep, tutorialCompleted]);

  useEffect(() => {
    if(!tutorialCompleted) return;
    if(currentScene?.slug !== 'reception') return;
    if(mapOpen) {
      setMapHighlight(false);
      return;
    }

    setMapHighlight(true);

  }, [tutorialCompleted, currentScene, mapOpen])

  usePC({
    event: 'picker:focus',
    callback: handleFocus
  })

  usePC({
    event: 'picker:blur',
    callback: handleBlur
  })

  usePC({
    event: 'picker:grab',
    callback: () => setFocusObject(null)
  })

  useEffect(() => {
    if(scene === null) return;
    setMapHighlight(false);
    setCurrentScene(scene);
    console.log(`scene`, scene)

    return () => setCurrentScene(null)
  }, [scene]);

  return (
    <>
      {/* <TutorialBackground className={mapHighlight ? 'active' : ''} /> */}

      <BottomNavWrapper isPressed={isHandPickerActive}>

      {focusObject && (
        <TitleStamp
          title={focusObject.title}
          logo={focusObject.logo}
          background={focusObject.background}
          border={focusObject.border}
          stampBG={focusObject.stampBG}
          stampPos={focusObject.stampPos}
        />
      )}

      <div className="navbar">

        <button
          className={`hand-icon ${showHandpicker ? 'active' : ''} ${tutorialStep === 7 ? 'tutorial-active' : ''}`}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onContextMenu={(e)=> e.preventDefault()}
        >
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6875 14.0625V7.96875C19.6875 7.34715 19.9344 6.75101 20.374 6.31147C20.8135 5.87193 21.4097 5.625 22.0313 5.625C22.6529 5.625 23.249 5.87193 23.6886 6.31147C24.1281 6.75101 24.375 7.34715 24.375 7.96875V17.8125C24.375 19.0436 24.1325 20.2627 23.6614 21.4002C23.1903 22.5376 22.4997 23.5711 21.6291 24.4416C20.7586 25.3122 19.7251 26.0027 18.5877 26.4739C17.4502 26.945 16.2312 27.1875 15 27.1875C9.82235 27.1875 7.50002 24.375 3.68339 16.3226C3.52919 16.0561 3.42904 15.7617 3.38865 15.4564C3.34826 15.1512 3.36844 14.8409 3.44802 14.5434C3.5276 14.2459 3.66502 13.967 3.85244 13.7227C4.03986 13.4783 4.27359 13.2733 4.54028 13.1193C4.80698 12.9653 5.1014 12.8654 5.40672 12.8253C5.71205 12.7852 6.02229 12.8056 6.31971 12.8854C6.61714 12.9653 6.89591 13.1029 7.14011 13.2905C7.3843 13.4782 7.58913 13.7121 7.74288 13.9789L10.3125 18.4296V6.09375C10.3125 5.47215 10.5594 4.87601 10.999 4.43647C11.4385 3.99693 12.0347 3.75 12.6563 3.75C13.2779 3.75 13.874 3.99693 14.3135 4.43647C14.7531 4.87601 15 5.47215 15 6.09375V14.0625" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.6875 14.0625V4.21875C19.6875 3.59715 19.4406 3.00101 19.001 2.56147C18.5615 2.12193 17.9654 1.875 17.3438 1.875C16.7221 1.875 16.126 2.12193 15.6865 2.56147C15.2469 3.00101 15 3.59715 15 4.21875V14.0625" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>

          <span className="picker-prompt">{isHandPickerActive ? 'Release to select' : 'Push and hold to select items'}</span>
        </button>

        {/* tutorial-active = the class for map icon highlight */}
        <button className={`map-icon ${mapHighlight ? 'tutorial-active' : ''}`} onClick={handleMapClick}>
          <img src={MapIcon} alt='map icon' />
        </button>

      </div>
      </BottomNavWrapper>
    </>
  )
}

export default BottomNav
