import React from 'react'
import styled from 'styled-components'

import { styles } from '../../styles/AppTheme'
import SolidButton from '../_library/buttons/SolidButton'

const LinkoutPromptWrapper = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  pointer-events: auto;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  top: 0;

  .prompt-wrapper {
    background-color: #fff;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    padding: 1.5rem 2.5rem;
    border-radius: 2rem;
    box-shadow: 0 6px 0 #B0B0B0;
  }

  .prompt-copy {
    font-family: ${styles.headingFont};
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    p {
      font-family: ${styles.headingFont};
    }

    button {
      padding: 0.5rem 1rem;
    }

    .take-me-there {

    }

    .go-back {
      background-color: ${styles.theme.baseGrey};
      box-shadow: 0 6px 0 #B0B0B0;
      margin-left: 1rem;
    }
  }
`

const LinkoutPrompt = ({linkOutURL, goBackClick}) => {

  const linkOut = e => {
    window.open(linkOutURL, '_blank');
    goBackClick(e);
  }

  return (
    <LinkoutPromptWrapper onClick={goBackClick}>
      <div className='prompt-wrapper'>
        <p className='prompt-copy'>You're about to leave the experience</p>
        <div className='btn-wrapper'>

          <SolidButton
            className='take-me-there'
            handleClick={linkOut}
          >
            <p>Take me there</p>
          </SolidButton>

          <SolidButton
            className='go-back'
            handleClick={goBackClick}
          >
            <p>Go back</p>
          </SolidButton>

        </div>
      </div>
    </LinkoutPromptWrapper>
  )
}

export default LinkoutPrompt
