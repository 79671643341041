const tutorial = [
  {
    scene: 'reception',
    step: 1,
    text: 'HI! I\'M TONY, LEAD GROWER AT PERFECTION FRESH.',
    duration: 4500,
    animation: 'talk',
    audio: 'vo-reception-tony',
    position: {
      x: 0.101,
      y: 0.1,
      z: 0.62
    }
  }, {
    scene: 'reception',
    step: 2,
    text: 'WE\'RE BEHIND SOME OF AUSTRALIA\'S FAVOURITES SUCH AS BROCCOLINI, ONE OF OUR GREATEST AND TASTIEST FIELD DISCOVERIES, AND QUKES BABY CUCUMBERS, OUR COOLEST AND CRUNCHIEST CREATION YET!',
    duration: 13300,
    animation: 'talk',
    position: {
      x: 0.101,
      y: 0.1,
      z: 0.62
    }
  }, {
    scene: 'reception',
    step: 3,
    text: 'THERE\'S SO MUCH MORE TO DISCOVER, SO LETS GET STARTED!',
    duration: 5200,
    animation: 'talk',
    position: {
      x: 0.101,
      y: 0.1,
      z: 0.62
    }
  }, {
    scene: 'reception',
    step: 4,
    text: 'TO EXPLORE OUR FARMS, SIMPLY TAP THE MAP ICON IN THE BOTTOM RIGHT-HAND CORNER.',
    duration: 5000,
    animation: 'talk',
    audio: 'vo-reception-explore',
    position: {
      x: 0.101,
      y: 0.1,
      z: 0.62
    }
  }, {
    scene: 'glasshouse',
    step: 5,
    text: 'ONCE IN A FARM, YOU CAN EXPLORE THE ENVIRONMENT BY MOVING YOUR PHONE AROUND THE SCENE.',
    duration: 5500,
    animation: 'talk',
    audio: 'vo-farm',
    rotation: 25,
    position: {
      x: -0.535,
      y: 0.01,
      z: 0.913
    }
  }, 
  // {
  //   scene: 'glasshouse',
  //   step: 6,
  //   text: 'MOVE YOUR PHONE OR WALK TOWARDS AN OBJECT TO SEE IT IN MORE DETAIL. YOU CAN ALSO EXPLORE THE FARMS BY USING SIMPLE PINCH AND DRAG GESTURES ON YOUR SCREEN.',
  //   duration: 11700,
  //   animation: 'talk',
  //   rotation: 25,
  //   position: {
  //     x: -0.535,
  //     y: 0.01,
  //     z: 1.913
  //   }
  // }, 
  {
    scene: 'glasshouse',
    step: 6,
    text: 'TAP AND HOLD ON THE HAND ICON AND MOVE YOUR PHONE TO EXPLORE OBJECTS AROUND THE FARM.',
    animation: 'talk',
    rotation: 25,
    audio: 'vo-tap-hold-hand',
    duration: 6200,
    position: {
      x: -0.535,
      y: 0.01,
      z: 0.913
    }
  }, {
    scene: 'glasshouse',
    step: 7,
    text: 'ONCE FOUND, RELEASE THE HAND BUTTON AND SEE IT COME TO LIFE!',
    duration: 4600,
    animation: 'talk',
    rotation: 25,
    position: {
      x: -0.535,
      y: 0.01,
      z: 0.913
    }
  }, {
    scene: 'glasshouse',
    step: 8,
    text: 'NOW TRY AND FIND OUR FAMOUS QUKES!',
    animation: 'talk',
    rotation: 35,
    position: {
      x: 0.934,
      y: 0.01,
      z: 0.725
    }
  }, {
    scene: 'glasshouse',
    step: 9,
    text: 'FOUND THEM! NOW RELEASE TO GRAB',
    animation: 'talk',
    position: {
      x: 0.934,
      y: 0.01,
      z: 0.725
    }
  }
];

export default tutorial
