import React from 'react'
import styled from 'styled-components'

import { styles } from '../../styles/AppTheme'
import CloseButton from '../_library/buttons/CloseButton'

const CookiePromptWrapper = styled.div `
  background-color: ${styles.theme.secondaryColour};
  position: absolute;
  top: .75rem;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  padding: 0.5rem 1rem;
  pointer-events: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: .5rem;
  z-index: 10;
  text-align: center;
  box-shadow: 1px 1px 3px ${styles.theme.baseDark};

  .cookie-paragraph {
    font-size: 0.75rem;
    line-height: 1.3;
    padding-right: 1rem;
  }

  a {
    text-decoration: underline;
  }

  .close-btn {
    right: 0;
    top: 0;
    background-color: transparent;
    position: relative;

    span {
      &::before, &::after {
        background-color: #000;
      }
    }
  }
`

const CookiePrompt = ({handleClose}) => {
  return (
    <CookiePromptWrapper id='cookie-prompt'>
      <p className="cookie-paragraph">This site uses cookies to improve the experience. By clicking start, you accept our <a href="https://www.perfection.com.au/privacy-policy/" target='_blank' rel="noreferrer">privacy policy.</a></p>
      <CloseButton
        handleClose={handleClose}
      />
    </CookiePromptWrapper>
  )
}

export default CookiePrompt