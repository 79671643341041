// Icons
import HealthIcon from '../assets/images/question-icons/question-health-icon.svg'
import HeartIcon from '../assets/images/question-icons/question-heart-icon.svg'
import RecipeIcon from '../assets/images/question-icons/question-recipe-icon.svg'
import TasteIcon from '../assets/images/question-icons/question-taste-icon.svg'
import TimeIcon from '../assets/images/question-icons/question-time-icon.svg'

// * Glasshouse
// *-------------------------------------* /
// Logos
import QukesLogo from '../assets/images/product-logos/qukes-logo.svg'
import MixamatoLogo from '../assets/images/product-logos/mixamato-logo.svg'
import KumatoLogo from '../assets/images/product-logos/kumato-logo.svg'

// Recipe Images
import QukesAnimalSandwiches from '../assets/images/recipes/glasshouse/qukes-funny-face-animal-sandwiches.jpg'
import QukesFalafelBowl from '../assets/images/recipes/glasshouse/qukes-easy-felafel-snack-bowl.jpg'
import QukesPickledQukes from '../assets/images/recipes/glasshouse/qukes-quick-pickles.jpg'

import MixamatoAllDayBrekfast from '../assets/images/recipes/glasshouse/mixamato-all-day-breakfast.jpg'
import MixamatoHaloumi from '../assets/images/recipes/glasshouse/tomato-medley-barbecued-haloumi-skewers.jpg'
import MixamatoMedley from '../assets/images/recipes/glasshouse/tomato-medley-tasting-plate.jpg'

import KumatoBaguette from '../assets/images/recipes/glasshouse/kumato-prosciutto-baguette.jpg'
import KumatoSalad from '../assets/images/recipes/glasshouse/kumato-puttanesca-salad.jpg'
import KumatoRoasted from '../assets/images/recipes/glasshouse/kumato-roasted-kumato.jpg'

import SolantoTortilla from '../assets/images/recipes/glasshouse/solanato-qukes-tortilla-cups.jpg'
import SolantoMacCheese from '../assets/images/recipes/glasshouse/solanato-tomatoes_mac-cheese.jpg'

import RomaBreakfast from '../assets/images/recipes/glasshouse/romatherapy-breakfast-bowl.jpg'
import RomaSalad from '../assets/images/recipes/glasshouse/romatherapy-summer-cobb-salad.jpg'

// MiniRoos
import MiniRoosVideo from '../assets/videos/qukes-miniroos-video.mp4'
import MiniRoosThumb from '../assets/images/qukes-miniroos-thumbnail.jpg'

// grownProcess Videos
import GH_environment from '../assets/videos/glasshouse/glasshouse_environment_c.mp4'
import GH_growing from '../assets/videos/glasshouse/glasshouse_growing_c.mp4'
import GH_harvest from '../assets/videos/glasshouse/glasshouse_harvest_c.mp4'
import GH_packing from '../assets/videos/glasshouse/glasshouse_delivery_c.mp4'


// * Tunnels
// *-------------------------------------* /
import StrawberryPannaCotta from '../assets/images/recipes/tunnels/strawberry-lime-and-vanilla-panna-cotta.png'
import StrawberryButter from '../assets/images/recipes/tunnels/strawberry-butter.png'
import StrawberryFrenchToast from '../assets/images/recipes/tunnels/strawberry-french-toast.png'

import BlueberryBananaBread from '../assets/images/recipes/tunnels/blueberry-coconut-banana-bread.png'
import BlueberryCreamBun from '../assets/images/recipes/tunnels/blueberry-cream-bun.png'
// import BlueberryGinTonic from '../assets/images/recipes/tunnels/'

import RaspberryPavlova from '../assets/images/recipes/tunnels/raspberry-pavlova.png'
import RaspberryDoughnuts from '../assets/images/recipes/tunnels/raspberry-custard-doughnuts.png'
import RaspberryYoghurt from '../assets/images/recipes/tunnels/raspberry_yoghurt_swirl.png'
import BerryGinPopsicle from '../assets/images/recipes/tunnels/Blueberries_Berry-Gin-Tonic-Popsicles.png'

// * Farmlands
// *-------------------------------------* /
import BroccoliniPotatoWedge from '../assets/images/recipes/farmlands/broccolini-and-potato-salad-with-blackberries.png'
import BroccoliniCheesy from '../assets/images/recipes/farmlands/broccolini-cheesey.png'
import BroccoliniHoneySoy from '../assets/images/recipes/farmlands/broccolini-honey-soy.png'

import CabbageLemonCaper from '../assets/images/recipes/farmlands/bambino-baby-cabbage-roasted-with-lemon.png'
import CabbageSnackAttack from '../assets/images/recipes/farmlands/bambino-baby-cabbage-snack-attack.png'

import BrusselParmasan from '../assets/images/recipes/farmlands/brussels_roasted_parmesan.png'
import BrusselPancetta from '../assets/images/recipes/farmlands/brussels-sprouts-pancetta-haloumi-fritters-solanato-qukes.png'

import FiorettoPomegranate from '../assets/images/recipes/farmlands/fioretto-farro-pistachio.png'
import FiorettoProscuitto from '../assets/images/recipes/farmlands/fioretto-charred-proscuitto-wrapped.png'
import FiorettoCheese from '../assets/images/recipes/farmlands/fioretto-with-cheese-sauce.png'

// import LettucePrawn from '../assets/images/recipes/farmlands/'
// import LettuceMushroomCups from 'sssaa../assets/images/recipes/farmlands/'

// grownProcess Videos
import FL_environment from '../assets/videos/farmlands/farmlands_environment_c.mp4'
import FL_growing from '../assets/videos/farmlands/farmlands_growing_c.mp4'
import FL_harvest from '../assets/videos/farmlands/farmlands_harvesting_c.mp4'
import FL_packing from '../assets/videos/farmlands/farmlands_delivery_c.mp4'


// * Plantation
// *-------------------------------------* /
import MangoPrawns from '../assets/images/recipes/plantation/calypso-mango-bbq-chilli-lime-with-prawns.png'
import MangoBreakfastBowl from '../assets/images/recipes/plantation/calypso-mang-dairy-free-breakfast-bowls.png'
import MangoPavlova from '../assets/images/recipes/plantation/calypso-mango-pistachio-pavlova-wreath.png'

// grownProcess Videos
import PT_environment from '../assets/videos/plantation/plantation_environment_c.mp4'
import PT_growing from '../assets/videos/plantation/plantation_growing_c.mp4'
import PT_harvest from '../assets/videos/plantation/plantation_harvesting_c.mp4'
import PT_packing from '../assets/videos/plantation/plantation_delivery_c.mp4'


const scenes = [{
    id: 0,
    title: 'Reception',
    slug: 'reception',
    character: {
      animation: 'idle',
      rotation: 15,
      position: {
        x: -0.195,
        y: 0.1,
        z: -0.182
      }
    }
  },
  {
    id: 1,
    title: 'Glasshouse',
    slug: 'glasshouse',
    character: {
      animation: 'idle',
      rotation: 25,
      position: {
        x: -0.7,
        y: 0.01,
        z: 0.134
      }
    },
    products: [
      {
        title: 'Qukes',
        slug: 'qukes',
        logo: QukesLogo,
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
            question: 'What are<br>Qukes?',
            icon: HeartIcon,
            content: [{
              type: 'text',
              copy: 'Unique variety of crunchy baby cucumbers. Full of sun sweetened flavour with a cool, cleansing crunch.'
            }]
          }, {
            question: 'Recipe<br>Inspiration',
            icon: RecipeIcon,
            content: [
              {
                type: 'recipe',
                title: 'QUKES® FUNNY FACE ANIMAL SANDWICHES',
                image: QukesAnimalSandwiches,
                button: {
                  link: 'https://www.perfection.com.au/recipes/qukes-funny-face-animal-sandwiches',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>250g</span> Qukes® baby cucumbers',
                      '<span>200g</span> Sweet Solanato® Tomatoes',
                      '<span>4 tbs</span> spreadable cream cheese',
                      '<span>2 tbs</span> mango chutney',
                      '<span>100g</span> ham, salami or turkey',
                      '<span>4 slices</span> tasty cheese'
                    ]
                  },
                  {
                    title: 'For the elephant',
                    content: [
                      '<span>4 slices</span> white bread',
                      '<span>4</span> cos lettuce leaves',
                      '<span>1</span> red radish, thinly sliced',
                      '<span>4</span> blueberries, black grapes or black olives'
                    ]
                  },
                  {
                    title: 'For the frog face',
                    content: [
                      '<span>4</span> rice cakes',
                      'Minicaps® baby capsicums',
                      '<span>125g</span> Perfection Blueberries'
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Slice 3 Qukes® lengthways and 6 tomatoes into rounds. Mix the cream cheese and chutney together and season.',
                      'For the elephant, spread the cream cheese mixture evenly over all 4 slices of bread. Top 2 slices of bread with tomatoes, ham, sliced Qukes® and cheese. Top with remaining bread, spread side down. Poke two lettuce leaves into the sandwich. Position 2 radish rounds as eyes, securing with a little cream cheese. Position the blueberries in centre of radish with a little cream cheese. Shape a Qukes® for the trunk and secure with cream cheese.',
                      'For the frog, Spread cream cheese mixture evenly over all 4 slices of the rice cakes. Top 2 slices of the rice cakes with tomatoes, ham, sliced Qukes® and cheese. Top with remaining rice cakes spread side down. Cut four rounds from 1 Quke® for the eyes and top each with a blueberry. Secure to the rice cake with cream cheese. Cut a mouth shape from Minicap® and shape Qukes® for legs. Secure with cream cheese'
                    ]
                  }
                ]
              },
              {
                type: 'recipe',
                title: 'NO COOK FALAFEL & QUKES® SNACK BOWL',
                image: QukesFalafelBowl,
                button: {
                  link: 'https://www.perfection.com.au/recipes/no-cook-felafel-qukes-snack-bowl',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>250g</span> Qukes® baby cucumbers, halved lengthways',
                      '<span>200g</span> Sweet Solanato® Tomatoes',
                      '<span>175g</span> Minicaps® baby capsicums, halved',
                      '<span>100g</span> hummus dip',
                      '<span>100g</span> beetroot dip',
                      '<span>1 cup</span> purchased tabouli, (see tip)',
                      '<span>250g</span> purchased felafel, sliced',
                      '<span>100g</span> feta, cut into cubes',
                      '<span>3 tbs</span> extra virgin olive oil',
                      '<span>1</span> small lemon, juiced',
                      '<span>1/2 tsp</span> caster sugar',
                      'baked lebanese bread & lemon wedges, to serve'
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Combine the hummus and beetroot dip together, spoon into base of 4 lunchbox or snack bowls. Add Qukes®, tomatoes, Minicaps®, tabouli and felafel. Scatter over the feta and season with pepper.',
                      'Combine oil, lemon, sugar and season. Spoon into a separate container to take to work or school. Spoon dressing over just before serving. Serve with baked Lebanese bread and lemon wedges.',
                      '<span class="recipe-tip">Tip: To make your own tabouli, Add 1 cup burghul into a large bowl. Cover with cold water, stand 30 minutes then drain, pressing through a sieve to remove all water. Chop 200g Sweet Solanato® or Kumato® tomatoes, stir into the burghul with 4 chopped Qukes®, 1 bunch chopped parsley, 1 bunch chopped mint, 3 chopped shallots, 2 tbs extra virgin olive oil and juice ½ lemon. Season and mix well.Tip Some felafel are more delicious warm, so just before adding the dressing, warm the felafel for 1-2 minutes on medium/50% in the microwave. Tip To bake Lebanese bread, cut into pieces, spray lightly with olive oil and bake in a single layer 180°C fan forced for 8-10 minutes. Once cool it becomes crisp.</span>'
                    ]
                  }
                ]
              },
              {
                type: 'recipe',
                title: 'QUICK PICKLED QUKES® BABY CUCUMBERS',
                image: QukesPickledQukes,
                button: {
                  link: 'https://www.perfection.com.au/recipes/quick-pickled-qukes-baby-cucumbers',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '2 x 250g Qukes® baby cucumbers',
                      '2 tbs chopped fresh dill',
                    ]
                  },
                  {
                    title: 'Pickling Liquid',
                    content: [
                      '<span>1 tbs</span> coriander seeds',
                      '<span>1 tbs</span> yellow mustard seeds',
                      '<span>2 cups</span> white wine vinegar',
                      '<span>1 cup</span> caster sugar',
                      '<span>2 tsp</span> sea salt flakes',
                      '<span>½ tsp</span> dried chilli flakes'
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'To make pickling liquid, heat the coriander and mustard seeds in a small frying pan over a medium to high heat for 2 minutes, or until fragrant. Transfer to a plate.',
                      'Combine vinegar, sugar and salt in a small saucepan. Stir over a medium heat until sugar is dissolved. Bring to boil. Transfer to a medium heatproof jug. Stir in seeds and chilli flakes. Set aside to cool.',
                      'Cut Qukes® in half lengthways. Divide between two sterilised jars (500ml capacity). Add 1 tablespoon dill to each jar. Pour over cooled pickling liquid. Seal tightly with lid. Refrigerate for a minimum of 4 hours. Pickled Qukes® are best eaten within 3 days.',
                      '<span class="recipe-tip">Serving suggestions:</span> Serve on a toasted corned beef, cheese and chutney salad sandwich. Serve on a cheese plate with pate, fig paste and muscatels',
                      '<span class="recipe-tip">Tip- To sterilise jars, wash in hot soapy water. Place in a large saucepan of cold water. Bring to the boil over high heat. Carefully remove jar, turn upside down on paper towel to cool completely. Recipe makes 2 x 500ml jars</span>'
                    ]
                  }
                ]
              },
              {
                type: 'link-out',
                title: 'QUICK PICKLED QUKES® BABY CUCUMBERS - VIDEO',
                link: 'https://www.youtube.com/watch?v=LcdpvhpEFZQ&list=PLiamnc-NBKYHA9in98Xv9CarDc-o7Zd2e&index=9'
              }
            ]
          },
          {
            question: 'Taste & Flavour',
            icon: TasteIcon,
            content: [{
                type: 'text',
                copy: 'Sun sweetened flavour with a cool, cleansing crunch.'
              },
              {
                type: 'text',
                copy: 'Qukes® are extra crunchy with a crisp, mild skin that’s offset by a refreshing, cleansing flavour. They’re a quick getaway on a long day.'
              },
              {
                type: 'text',
                copy: 'Their adorable, snackable size makes them a delicious go-to for rumbling stomachs and hungry little hands.'
              }
            ]
          },
          {
            question: 'How do I keep this fresh<br>for the longest time?',
            icon: TimeIcon,
            content: [{
              type: 'text',
              copy: 'Store your Qukes® at room temperature. Wash before use.'
            }]
          },
          {
            question: 'What can I<br> pair this with?',
            icon: RecipeIcon,
            content: [{
                type: 'text',
                copy: 'From lunchbox snacks to grazing board stars, pair with your favourite dips for a simple snack.'
              },
              {
                type: 'text',
                copy: 'Dial up the flavour with a grazing board featuring salami wrapped Qukes®, tomato, bocconcini & Qukes® skewers and a selection of charcuterie, fruits & vegetables.'
              }
            ]
          },
          {
            question: 'Health & Nutrition',
            icon: HealthIcon,
            content: [{
                type: 'text',
                copy: 'Small but packed with vitamins and minerals!'
              },
              {
                type: 'text',
                copy: 'Qukes® are a great source of folate for healthy cells, as well as fibre and vitamin C.'
              }
            ]
          }
        ],
        seasonality: [5, 5, 5, 5, 4, 3, 1, 3, 5, 5, 5, 5]
      },
      {
        title: 'Mix-a-mato',
        slug: 'mix-a-mato',
        logo: MixamatoLogo,
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
            question: 'What are<br>Mix-a-Matos?',
            icon: HeartIcon,
            content: [{
              type: 'text',
              copy: 'A medley of red, yellow, brown and green tomatoes.'
            }]
          },
          {
            question: 'Recipe<br>Inspiration',
            icon: RecipeIcon,
            content: [
              {
                type: 'recipe',
                title: 'MIX-A-MATO® ALL DAY BREAKFAST',
                image: MixamatoAllDayBrekfast,
                button: {
                  link: 'https://www.perfection.com.au/recipes/mix-a-mato-all-day-breakfast',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>320g</span> Mix-a-mato® tomatoes',
                      '<span>3</span> Qukes® baby cucumbers',
                      '<span>1/2</span> avocado, smashed',
                      '<span>20g</span> butter',
                      '<span>250g</span> mushrooms (mix of small flats and cups)',
                      '<span>2 tsp</span> Worcestershire sauce',
                      '<span>2</span> eggs',
                      'sriracha sauce, to serve',
                      '<span>40g</span> snow pea tendrils, baby rocket or spinach',
                      '<span>4</span> slices seed & grain sourdough, chargrilled'
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Cut the Qukes® in half lengthways. Turn cut side down onto the board. Use a wide blade knife to smash the Qukes® into rough pieces. Stir into the smashed avocado and season.',
                      'Heat a medium frying pan over medium-high heat. Add the oil sachet from the Mix-a-Mato® tomatoes, butter and mushrooms, sauté for 3-4 minutes until mushrooms start to colour. Add the tomatoes and sauté for a further 5 minutes until tomatoes start to blister. Drizzle over the Worcestershire sauce. Shake pan to coat the tomatoes.',
                      'Crack over the eggs and shake the pan to allow egg white to flow to base of the pan. Cook for 1-2 minutes until eggs are cooked to your liking. Season with pepper, drizzle with sriracha and top with snow pea tendrils. Spread the sourdough with Qukes® and avocado mixture and serve.'
                    ]
                  }
                ]
              },
              {
                type: 'recipe',
                title: 'MIX-A-MATO® TOMATO & BARBECUED HALOUMI SKEWERS',
                image: MixamatoHaloumi,
                button: {
                  link: 'https://www.perfection.com.au/recipes/tomato-medley-barbecued-haloumi-skewers',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>400g</span> Mix-a-mato® tomatoes',
                      '<span>600g</span> haloumi cheese',
                      '<span>8</span> slices sourdough',
                      '<span>2 tbs</span> olive oil',
                      '<span>12 x 25cm</span> skewers',
                      'DRESSING',
                      '<span>3 tbs</span> extra virgin olive oil',
                      '<span>1</span> lemon, juiced',
                      '<span>1</span> small garlic clove',
                      '<span>3 tsp</span> Dijon mustard',
                      '<span>2 tsp</span> honey',
                      '<span>2 tbs</span> chopped dill',
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'To make the dressing, whisk oil, 2 tablespoons of lemon juice, garlic, mustard, honey and dill together. Season well.',
                      'Cut the haloumi into 2-3cm pieces. Thread the tomatoes and haloumi onto skewers.',
                      'Heat a barbecue grill plate or char-grill on medium heat. Brush both sides of the sourdough with a little oil. Cook for 1-2 minutes on each side or until lightly charred.',
                      '<span class="recipe-tip">Serving suggestions; Delicious with barbecue steak or gourmet sausages</span>'
                    ]
                  }
                ]
              },
              {
                type: 'recipe',
                title: 'MIX-A-MATO® CHEDDAR PARTY PULL APART',
                image: MixamatoMedley,
                button: {
                  link: 'https://www.perfection.com.au/recipes/mix-a-mato-cheddar-party-pull-apart',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>2 x 320g</span> Mix-a-mato® tomatoes',
                      '<span>10-12</span> dinner bread rolls, halved (see tip)',
                      '<span>125g</span> butter, softened',
                      '<span>2</span> garlic cloves, crushed',
                      '<span>2 tbs</span> finely chopped flat leaf parsley',
                      '<span>40g</span> finely grated parmesan',
                      '<span>80g</span> mozzarella, grated',
                      '<span>200g</span> cheddar cheese, grated',
                      '<span>160g</span> salami, chopped',
                      '<span>120g (1/2 cup)</span> mixed pitted olives, chopped'
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Preheat oven to 220°C fan forced. Scatter the tomatoes in a roasting pan. Drizzle with both oil sachets and sprinkle with both salt sachets. Shake the pan to coat. Roast for 8 minutes until the tomatoes are tender and just start to collapse. Set aside to cool for 15 minutes. Reduce the oven to 180°C fan forced.',
                      'Meanwhile, mix butter, garlic, parsley and half the parmesan until well combined, season. Spread over the cut side of the rolls. Arrange garlic buttered side up on a greased 38cm round (or 30cm x 45cm) oven tray. Bake for 5 minutes until rolls start to colour. Remove from the oven.',
                      'Combine the mozzarella and cheddar and sprinkle 3/4 over the rolls. Top with salami, olives, tomatoes, remaining cheddar mix and remaining parmesan. Bake for 15 minutes until cheese has melted. Serve warm.',
                      '<span class="recipe-tip"> Tip: The number dinner rolls will depend on the size of your rolls.</span>',
                      '<span class="recipe-tip">Tip: Time saving tip, replace the homemade garlic rolls with purchased garlic bread.</span>',
                      '<span class="recipe-tip">Tip: The pull apart can be made ahead. Bake for 10 minutes in step 3. Warm in a 160°C fan forced oven for 10 minutes to serve.</span>'
                    ]
                  }
                ]
              },
              {
                type: 'link-out',
                title: 'Mix-a-Mato® Cheddar Party Pull Apart ',
                link: 'https://www.youtube.com/watch?v=suXj-GViZqc&list=PLiamnc-NBKYFvGYgFnpCLvsjjHRI0m6dY&index=4'
              }
            ]
          },
          {
            question: 'Taste & Flavour',
            icon: TasteIcon,
            content: [{
              type: 'text',
              copy: 'A rainbow of sensational sweetness.'
            }, {
              type: 'text',
              copy: 'Mix-a-mato® tomatoes are blooming with colourful beauty, ranging in flavour from super sweet to deep and rich.'
            }]
          },
          {
            question: 'How do I keep this fresh<br>for the longest time?',
            icon: TimeIcon,
            content: [{
              type: 'text',
              copy: 'Store these beauties at room temperature and shield them from direct sunlight to help them ripen to perfection.'
            }]
          },
          {
            question: 'What can I<br>pair this with?',
            icon: RecipeIcon,
            content: [{
              type: 'text',
              copy: 'Toss with olive oil and salt for a simple salad or create a cafe style breakfast at home with sautéed Mix-a-Mato® over eggs benedict.'
            }]
          },
          {
            question: 'Health & Nutrition',
            icon: HealthIcon,
            content: [{
              type: 'text',
              copy: 'Great source of natural vitamins! Rich in natural antioxidants and low in calories, fat and sugars, supporting heart health.'
            }]
          }
        ],
        seasonality: [5, 5, 5, 4, 3, 2, 1, 1, 3, 4, 5, 5]
      },
      {
        title: 'Kumato',
        slug: 'kumato',
        logo: KumatoLogo,
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
            question: 'What are<br>Kumatos?',
            icon: HeartIcon,
            content: [{
              type: 'text',
              copy: 'Kumato® is not your ordinary tomato. It’s a darker, sweeter variety which can be eaten through its three ripening stages.'
            }]
          },
          {
            question: 'Recipe<br>Inspiration',
            icon: RecipeIcon,
            content: [
              {
              type: 'recipe',
              title: 'KUMATO® & PROSCIUTTO BAGUETTE',
              image: KumatoBaguette,
              button: {
                link: 'https://www.perfection.com.au/recipes/kumato-prosciutto-baguette',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>500g</span> Kumato® Tomatoes',
                    '<span>1</span> large ripe avocado, halved, seed removed',
                    '<span>1</span> tbs chipotle in adobe sauce',
                    '<span>150g</span thinly sliced prosciutto, roughly chopped',
                    '<span>1</span long baguette',
                    'lime wedges, to serve (optional)'
                  ]
                },
                {
                  title: 'Method',
                  main: true,
                  content: [
                    'Thickly slice the Kumato® tomatoes, followed by seasoning with salt and pepper.',
                    'Scoop avocado into a bowl and roughly smash with a fork, then stir in the chipotle.',
                    'Cut the baguette at 3-4 cm intervals making sure not to cut all the way through the baguette.',
                    'Spread the avocado between each cut then fill with prosciutto and the sliced Kumato® tomatoes.',
                    'Slice into pieces and serve with limes wedges.'
                  ]
                }
              ]
              },
              {
                type: 'recipe',
                title: 'KUMATO® PUTTANESCA SALAD',
                image: KumatoSalad,
                button: {
                  link: 'https://www.perfection.com.au/recipes/kumato-puttanesca-salad',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>500g</span> Kumato® Tomatoes',
                      '<span>3</span> anchovy fillets in oil, drained',
                      '<span>3 tbs (60ml)</span> extra virgin olive oil',
                      '<span>2</span> garlic cloves, crushed',
                      '<span>1 tsp</span> dried chilli flakes',
                      '<span>2 tbs (40ml)</span> red wine vinegar',
                      '<span>40g</span> mixed salad leaves',
                      '<span>3</span> green onions, finely chopped',
                      '<span>2 tbs (20g)</span> salted capers, rinsed, drained',
                      '<span>60g (1/3 cup)</span> pitted black olives, roughly chopped',
                      '<span>1/4 cup</span> flat-leaf parsley leaves, roughly chopped',
                      '<span>25g</span> Parmesan, shaved'
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Finely chop the anchovies then use a fork to mash them to a paste. Add to a small non-stick frying pan with 1 tablespoon of oil. Cook, stirring for 3 minutes over medium heat until dissolved. Add the garlic and chilli, cook for 30 seconds. Transfer to a bowl and cool for 10 minutes. Stir in remaining oil, vinegar and season with pepper.',
                      'Scatter the leaves over a serving platter. Slice the Kumato® tomatoes and arrange on the platter. Top with the onions, capers, olives, parsley and parmesan. Spoon over the dressing & season with pepper. Serve.',
                      '<span class="recipe-tip">Serving suggestions; Delicious with barbecue steak or gourmet sausages</span>'
                    ]
                  }
                ]
              },
              {
                type: 'recipe',
                title: 'ROASTED KUMATO® TOMATOES',
                image: KumatoRoasted,
                button: {
                  link: 'https://www.perfection.com.au/recipes/roasted-kumato-tomatoes',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>500g</span> Kumato® Tomatoes',
                      '<span>3 tbs (60ml)</span> extra virgin olive oil',
                      '<span>1 tbs</span> brown sugar',
                      '<span>6</span> sprigs thyme',
                      '<span>2 tbs (40ml)</span> pomegranate molasses, vincotto or caramelized balsamic',
                      'Prosciutto, sliced salami, rocket leaves, marinated goats cheese and char-grilled crusty bread, to serve'
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Preheat oven to 150°C fan forced. Lightly grease a roasting pan. Cut the Kumato® tomatoes in half and arrange cut side up in the pan. Drizzle with oil & sprinkle with sugar and thyme. Roast for 30 minutes or until Kumato® tomatoes are tender but hold their shape. Allow to cool for 1 hour.',
                      'Divide Kumato®, cold meat, rocket and goats cheese between serving plates. Drizzle with pomegranate molasses. Serve with char-grilled crusty bread.',
                      '<span class="recipe-tip">Tags Kumato® tomatoes; Entree; Starter; Light meal; Entertaining; Gourmet; Make ahead; 4 ingredients.</span>'
                    ]
                  }
                ]
              }
            ]
          },
          {
            question: 'Taste & Flavour',
            icon: TasteIcon,
            content: [{
              type: 'text',
              copy: 'Radiating with robust richness, they are bold and authentic in flavour.'
            }, {
              type: 'text',
              copy: 'They can be eaten at any ripening stage and taste as good as they look!'
            }, {
              type: 'text',
              copy: 'When green they are firm and flavourful, when deep brown, its flavour intensifies.'
            },{
              type: 'text',
              copy: 'When a dark reddish brown, its ripe with a very mild flavour.'
            }]
          },
          {
            question: 'How do I keep this fresh<br>for the longest time?',
            icon: TimeIcon,
            content: [{
              type: 'text',
              copy: 'Store your Kumato® tomatoes at room temperature to explore the rich flavour as they ripen.'
            }]
          },
          {
            question: 'What can I<br>pair this with?',
            icon: RecipeIcon,
            content: [{
              type: 'text',
              copy: 'The Tomato Lovers Tomato'
            }, {
              type: 'text',
              copy: 'Kumato® loves mediterranean flavours, think olives, feta cheese and fresh sourdough.'
            },{
              type: 'text',
              copy: 'Try them in a puttanesca or Greek salad for a delightful escape.'
            }]
          },
          {
            question: 'Health & Nutrition',
            icon: HealthIcon,
            content: [{
              type: 'text',
              copy: 'Rich in natural antioxidants like lypcopene with potassium, Vit A & C, and low in calories, fat and sugars.'
            }]
          }
        ],
        seasonality: [5, 5, 5, 5, 4, 3, 1, 3, 5, 5, 5, 5]
      },
      {
        title: 'Petite Tomatoes',
        slug: 'petite',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
            question: 'What are<br>Petite Tomatoes?',
            icon: HeartIcon,
            content: [{
              type: 'text',
              copy: 'A deliciously sweet, snacking tomato.'
            }]
          },
          {
            question: 'Recipe<br>Inspiration',
            icon: RecipeIcon,
            content: [{
              type: 'recipe',
              title: 'SWEET SOLANATO® TOMATO MAC N CHEESE',
              image: SolantoMacCheese,
              button: {
                link: 'https://www.perfection.com.au/recipes/solanato-tomato-mac-n-cheese',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>2 x 200g</span> Sweet Solanato® Tomatoes',
                    '<span>50g</span> butter',
                    '<span>1</span> garlic clove, crushed',
                    '<span>1/4 cup</span> plain flour',
                    '<span>3 cups</span> full cream milk',
                    'pinch salt & pepper, to taste',
                    '<span>2 1/2 cups</span> grated tasty cheese',
                    '<span>400g</span> macaroni',
                    '<span>2 tsp</span> olive oil'
                  ]
                },
                {
                  title: 'Method',
                  main: true,
                  content: [
                    'Preheat oven to 200°C fan forced. Lightly grease a 10-cup capacity baking dish.',
                    'Cook macaroni in a large saucepan of lightly salted boiling water for 6 minutes, or until tender.',
                    'Meanwhile, melt butter and garlic in a large saucepan over a medium heat. Cook, stirring until foaming. Add flour and cook, stirring for 1 minute. Whisk in milk. Bring to the boil, stirring constantly. Stir in 1 1/2 cups of the cheese. Season with salt and ground white pepper.',
                    'Drain the pasta and return to the pan. Add the sauce and mix well. Spoon into the prepared dish. Scatter with remaining cheese.',
                    'Combine Solanato® tomatoes and oil in a bowl. Season. Spoon over the macaroni in the dish. Bake for 30-35 minutes, or until cheese is bubbling and tomatoes soften.',
                    '<span class="recipe-tip">Tip- For a cheesier macaroni, add 1/2 cup grated parmesan with the grated tasty cheese.</span>'
                  ]
                }
              ]
            },
            {
              type: 'recipe',
              title: 'SWEET SOLANATO® TOMATO & QUKES® TORTILLA CUPS',
              image: SolantoTortilla,
              button: {
                link: 'https://www.perfection.com.au/recipes/sweet-solanato-tomatoes-and-qukes-tortilla-cups',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>200g</span> Sweet Solanato® Tomatoes, halved',
                    'Sweet Solanato® Tomatoes, sliced, to serve',
                    'Qukes® baby cucumbers, to serve',
                    '<span>6</span> mini flour tortilla',
                    '<span>150g</span> shaved ham, chopped',
                    '<span>80g</span> tasty cheese, grated',
                    '<span>2</span> eggs',
                    '<span>1/3 cup</span> full cream milk',
                    'Olive oil spray',
                    'Baby rocket, to serve'
                  ]
                },
                {
                  title: 'Method',
                  main: true,
                  content: [
                    'Preheat oven to 180°C fan forced. Place two tortillas onto a microwave safe plate. Heat in microwave for 10 seconds until warm. Spray olive oil on both sides and press into Texas muffin trays. Repeat to make 6.',
                    'Place muffin tray into the oven and bake for 10 minutes until light golden.',
                    'Place ham, cheese and Sweet Solanato® tomatoes into tortilla cups. Whisk eggs and milk together and pour over the filling. Season with salt and pepper. Bake for 20-25 minutes until set in the centre. Set aside to cool',
                    '<span class="recipe-tip">Tip 1- For kids, place into lunch boxes with extra Sweet Solanato® tomatoes and Qukes® baby cucumbers.</span>',
                    '<span class="recipe-tip">Tip 2- For adults, combine extra Sweet Solanato® tomatoes, sliced Qukes® baby cucumbers and rocket with extra virgin olive oil. Season and pile on top of tortilla cups. </span>',
                    '<span class="recipe-tip">Tip 3- Other filling suggestions- Replace the ham with salami, shredded chicken or shaved turkey breast.</span>'
                  ]
                }
              ]
            }]
          },
          {
            question: 'Taste & Flavour',
            icon: TasteIcon,
            content: [{
              type: 'text',
              copy: 'Pretty as a picture, our Petite Tomatoes™ are sweetness itself. '
            },{
              type: 'text',
              copy: 'Bite into their delicate, thin skin to receive a heady burst of robust tomato flavour with a delightfully sweet aftertaste.'
            }, {
              type: 'text',
              copy: 'A moreish snack for peckish adults. A great mess-free munch for little kids.'
            }]
          },
          {
            question: 'How do I keep this fresh<br>for the longest time?',
            icon: TimeIcon,
            content: [{
              type: 'text',
              copy: 'Store your Petite Tomatoes™ at room temperature so they’re sweet and juicy from first bite.'
            }]
          },
          {
            question: 'What can I<br>pair this with?',
            icon: RecipeIcon,
            content: [{
              type: 'text',
              copy: 'Thread them with meats, cheese or veggies for savoury skewers, or use them to decorate your tarts, quiches and pies.'
            }]
          },
          {
            question: 'Health & Nutrition',
            icon: HealthIcon,
            content: [{
              type: 'text',
              copy: 'Rich in natural antioxidants like lypcopene with potassium, Vit A & C, and low in calories, fat and sugars.'
            }]
          }
        ],
        seasonality: [5, 5, 5, 5, 3, 2, 1, 3, 5, 5, 5, 5]
      },
      {
        title: 'Romatherapy',
        slug: 'baby-roma',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
            question: 'What are Romatherapy<br>Baby Roma Tomatoes?',
            icon: HeartIcon,
            content: [{
              type: 'text',
              copy: 'Vine ripened sweet baby roma tomatoes.'
            }]
          },
          {
            question: 'Recipe<br>Inspiration',
            icon: RecipeIcon,
            content: [
              {
              type: 'recipe',
              title: 'ROMATHERAPY® TOMATO SUMMER SALAD',
              image: RomaSalad,
              button: {
                link: 'https://www.perfection.com.au/recipes/romatherapy-baby-roma-tomato-summer-salad',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>2</span> Baby Cos Heart',
                    '<span>250g</span> Romatherapy® Baby Roma Tomatoes, halved',
                    '<span>1</span> lemon, juiced',
                    '<span>1 tbs</span> caster sugar',
                    '<span>1</span> red onion, thinly sliced',
                    '<span>3</span> eggs, at room temperature',
                    '<span>2</span> cobs corn, husk removed, washed',
                    '<span>2</span> chorizo, sliced',
                    '<span>18 (600g)</span> medium cooked prawns, peeled, deveined',
                    '<span>1</span> large avocado, diced',
                    '<span>1/3 cup</span> tomato sauce',
                    '<span>1/2 tsp</span> Worcestershire sauce',
                    '<span>1/2 tsp</span> sweet paprika',
                    'THOUSAND ISLAND DRESSING',
                    '<span>1/4 cup</span> whole egg mayonnaise',
                    '<span>1/4 cup</span> buttermilk',
                    '<span>1/4 cup</span> whole egg mayonnaise',
                    '<span>1/4 cup</span> buttermilk',
                    '<span>1/2</span> lemon, juiced'
                  ]
                },
                {
                  title: 'Method',
                  main: true,
                  content: [
                    'For the Thousand island dressing; whisk all the ingredients together. Refrigerate until ready to serve.',
                    'Combine lemon juice, sugar and plenty salt and pepper in a bowl. Add the onion and stir to coat. Cover and set aside 1 hour to pickle.',
                    'Lower the eggs into a saucepan of gently boiling water. Boil gently for 7 minutes for medium-boiled, or to your liking. Submerge into a bowl cold water until cool enough to handle. Peel and halve.',
                    'Preheat the barbecue plate on high. Add corn. Barbecue, turning occasionally for 10 minutes, or until charred. Remove to a board. Barbecue the chorizo slices for 1-2 minutes or until golden. Remove to a plate. Slice the corn from the cobs.',
                    'Thinly slice the lettuce crossways and arrange on large platter or board. Spoon over a little dressing. Add the prawns, avocado, drained pickled onion, egg, corn, chorizo and Romatherapy® baby roma tomatoes. Serve with remaining dressing.'
                  ]
                }
              ]
              },
              {
                type: 'recipe',
                title: 'ROMATHERAPY® TOMATOES BREAKFAST BOWL',
                image: RomaBreakfast,
                button: {
                  link: 'https://www.perfection.com.au/recipes/romatherapy-baby-roma-tomatoes-breakfast-bowl',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>2 x 250g</span> Romatherapy® Baby Roma Tomatoes',
                      '<span>4</span> slices sour dough, cut into 4cm pieces',
                      '<span>8</span> slices pancetta',
                      'olive oil cooking spray',
                      '<span>4</span> flat mushrooms',
                      '<span>4</span> eggs, at room temperature',
                      '<span>3 tbs</span> dukkah',
                      '<span>250g</span> thick Greek yoghurt',
                      '<span>60g</span> baby spinach leaves',
                      'Lemon wedges, to serve'
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Preheat oven 200°C fan forced. Scatter the bread onto a baking tray and spray with oil. Arrange pancetta in a single layer of bread. Place mushrooms in a small roasting pan and spray with oil. Season well. Roast mushrooms and bread for 10-12 minutes until the bread and pancetta are lightly golden and the mushrooms tender. Keep warm.',
                      'Place Romatherapy® baby Roma tomatoes into another small roasting pan, spray with oil and season well. Roast for 5 minutes until they start to soften.',
                      'Meanwhile, submerge the eggs into a saucepan of gently boiling water. Boil gently for 6 minutes for soft-boiled, or to your liking. Submerge in a bowl cold water until cool enough to handle. Peel. Dip peeled eggs in cold water then roll one at a time in the dukkaha to coat. Reserve any leftover dukkah.',
                      'Spoon yoghurt over the base of 4 serving bowls, sprinkle with leftover dukkah. Top with spinach, roasted tomatoes, mushroom, pancetta, bread and dukkah coated egg. Serve with lemon wedges.',
                      '<span class="recipe-tip">Tip- To make your own dukkah, combine 1 tbs each ground cumin, coriander and sesame seeds with 1 tsp each sweet paprika, sea salt flakes and brown sugar. Warm in a small frying pan over medium heat until aromatic. Remove to a bowl, stir in 1 tbs finely chopped toasted pistachio. All to Cool. Leftover can be stored in a jar in the fridge for 6 months.</span>'
                    ]
                  }
                ]
              }
            ]
          },
          {
            question: 'Taste & Flavour',
            icon: TasteIcon,
            content: [
              {
                type: 'text',
                copy: 'Glossy red with a tantalising aroma. Eat them whole, or sliced in half.'
              },
              {
                type: 'text',
                copy: 'You’ll find these luscious lovelies firm to touch, rich in texture and packed with mouth-watering sweetness.'
              },
          ]
          },
          {
            question: 'How do I keep this fresh<br>for the longest time?',
            icon: TimeIcon,
            content: [{
              type: 'text',
              copy: 'Store them at room temperature away from the sun for a beautiful, ripe flavour.'
            }]
          },
          {
            question: 'What can I<br>pair this with?',
            icon: RecipeIcon,
            content: [{
              type: 'text',
              copy: 'A deliciously savoury garnish for tarts, halve them and scatter in generous handfuls.'
            }]
          },
          {
            question: 'Health & Nutrition',
            icon: HealthIcon,
            content: [{
              type: 'text',
              copy: 'Rich in natural antioxidants like lypcopene with potassium, Vit A & C, and low in calories, fat and sugars.'
            }]
          }
        ],
        seasonality: [5, 5, 5, 5, 3, 2, 1, 3, 5, 5, 5, 5]
      },
      {
        title: 'MiniRoos',
        slug: 'miniroos',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [
          {
            question: 'The Ultimate<br>Sporting Snack',
            icon: HeartIcon,
            content: [
              {
                type: 'text',
                copy: 'Qukes® & the MiniRoos are working together to encourage healthy eating choices among active young aussies.'
              },
              {
                type: 'text',
                copy: 'Healthy & fresh, Qukes® are the perfect half-time snack ready for eager young athletes, keen for the sporting season.'
              }
            ]
          },
          {
            question: 'What’s the<br>partnership about?',
            icon: TasteIcon,
            content: [
              {
                type: 'text',
                copy: 'This unique, interactive partnership aims to educate kids on the benefits of a healthy, active lifestyle with Qukes® as the ultimate ‘qrunchy’ fresh sporting snack for athletes both big and small.'
              }
            ]
          },
          {
            question: 'Healthy Snacks with<br>the Matildas & MiniRoos',
            icon: RecipeIcon,
            content: [
              {
                type: 'video',
                video: MiniRoosVideo,
                videoImage: MiniRoosThumb,
              }
            ]
          },
          {
            question: 'Why are Qukes®<br>great for sport?',
            icon: HeartIcon,
            content: [
              {
                type: 'text',
                copy: 'Qukes® are naturally the perfect snack size portion that also doubles as a source of folate and Vitamin C with high water content.'
              },
              {
                type: 'text',
                copy: '* High water content with mineral salts like potassium and magnesium for hydration'
              },
              {
                type: 'text',
                copy: '* B Vitamins for energy metabolism<br>* Hand held, portable and no waste<br>* Contains vitamin C and zinc'
              }
            ]
          },
          {
            question: 'Want to stay<br>up to date?',
            icon: HeartIcon,
            content: [
              {
                type: 'link-out',
                title: 'Qukes® & the Miniroos',
                link: 'https://info.perfection.com.au/qukes-miniroos'
              }
            ]
          }
        ]
      }
    ],
    grownProcess: [
        {
        id: 1,
        icon: '',
        videoLink: GH_environment,
        animation: 'environment',
        copy: 'This protected environment allows us to create the perfect growing conditions for our tomatoes & Qukes® all year round.'
      }, {
        id: 2,
        icon: '',
        videoLink: GH_growing,
        animation: 'growing',
        copy: 'Plants are grown hydroponically and grow vertically towards the light. As there are no bees in the glasshouse, pollination is done by hand by our growers.'
      }, {
        id: 3,
        icon: '',
        videoLink: GH_harvest,
        animation: 'harvest',
        copy: 'Tomatoes & Qukes® are hand picked when just right and placed onto automatic trolleys that deliver the produce to the packing area on site.'
      }, {
        id: 4,
        icon: '',
        videoLink: GH_packing,
        animation: 'packing',
        copy: 'Produce is checked for quality and packed ready for delivery to fruit & vege markets and supermarket shelves around Australia.'
      }, {
        id: 4,
        icon: 'plant',
        videoLink: '',
        animation: 'sustainability',
        copy: 'By recycling rainwater and minimising water wastage, we are able to use 90% less water than other farming methods. We use 95% less pesticides and 80% less fertilisers than other growing methods.'
      }
    ]
  },
  {
    id: 2,
    title: 'Tunnels',
    slug: 'tunnels',
    character: {
      animation: 'idle',
      rotation: 25,
      position: {
        x: -1.329,
        y: 0.091,
        z: -0.627
      }
    },
    products: [
      {
        title: 'Strawberries',
        slug: 'strawberries',
        logo: '',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [
          {
            question: 'What are<br>Strawberries?',
            icon: HeartIcon,
            content: [{
              type: 'text',
              copy: 'Glossy rose red hearts of strawberry sweetness.'
            }]
          }, {
            question: 'Recipe<br>Inspiration',
            icon: RecipeIcon,
            content: [{
                type: 'recipe',
                title: 'STRAWBERRY, LIME AND VANILLA PANNA COTTA',
                image: StrawberryPannaCotta,
                button: {
                  link: 'https://www.perfection.com.au/recipes/strawberry-lime-and-vanilla-panna-cotta',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>2 x 250g</span> Perfection Strawberries',
                      '<span>1 cup (220g)</span> caster sugar',
                      '<span>2</span> limes juiced',
                      '<span>300ml</span> thickened cream',
                      '<span>2 tsp</span> powdered gelatine',
                      '<span>1 tsp</span> vanilla bean paste',
                      '<span>11/4 cups (330g)</span> Greek yoghurt',
                      'Limes wedges to serve, optional',
                    ]
                  }, {
                    title: 'Method',
                    main: true,
                    content: [
                      'Set 4 strawberries aside in the fridge until you are ready to serve. Slice the remaining strawberries. Combine ½ cup sugar, lime juice and 1 tablespoon water in a medium non-stick saucepan. Bring to simmer, stirring to dissolve the sugar. Simmer gently 1 minute. Remove from the heat. Add sliced strawberries, shake the pan gently to coat. Cool 15 minutes.',
                      'Using a slotted spoon, spoon the strawberries between 4 x ¾ cup-capacity (185ml) glasses. Refrigerate until required. Pour the syrup into a jug and refrigerate until ready to serve.',
                      'Pour 100ml of cream into a medium saucepan. Sprinkle over the gelatine. Whisk gently over low heat until the cream is warm and gelatine has dissolved (do not boil). Add the remaining cream, remaining ½ cup sugar and vanilla. Whisk gently over low until sugar has dissolved and mixture is warm, do not boil. Remove from heat and stand 5 minutes. Whisk in yoghurt, then strain into a jug.',
                      'Pour panna cotta mix over the strawberries, cover and refrigerate 4 hours until set. Just before serving, hull and slice reserved strawberries, arrange over panna cotta. Spoon over reserved strawberry lime syrup. Serve with lime wedges.',
                    ]
                  }
                ]
              },
              {
                type: 'recipe',
                title: 'STRAWBERRY BUTTER',
                image: StrawberryButter,
                button: {
                  link: 'https://www.perfection.com.au/recipes/strawberry-butter',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>250g</span> Perfection Strawberries',
                      '<span>½ cup (110g)</span> white sugar',
                      '<span>1 tsp</span> vanilla bean paste',
                      '<span>2 tbs (40ml)</span> lemon juice',
                      '<span>80g</span> butter at room temperature',
                      'Warm croissants, Bagels, crumpets or toasted sour dough to serve',
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Wash, hull and slice the strawberries. Place into a non-stick frying pan with sugar, vanilla and lemon juice. Stir over medium heat until sugar has dissolved. Bring to gentle boil, boil 6 minutes, stirring often (you should have 3/4 cup). Set aside 20 minutes to cool to room temperature.',
                      'Spoon the strawberry mixture into a food processor. Add butter and pulse until well combined. Transfer to an airtight container. Refrigerate 3 hours.',
                      'Strawberry butter is delicious spread on croissants toasted bagels, sour dough, crumpets and scones.'
                    ]
                  }
                ]
              },
              {
                type: 'recipe',
                title: 'PERFECTION BERRY FRENCH TOAST WAFFLES WITH ESPRESSO LABNE',
                image: StrawberryFrenchToast,
                button: {
                  link: 'https://www.perfection.com.au/recipes/perfection-berry-french-toast-waffles-with-espresso-labne',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>250g</span> Perfection Strawberries hulled, sliced',
                      '<span>125g</span> Perfection Blueberries',
                      '<span>500g</span> Greek yoghurt',
                      '<span>1 tbs</span> espresso coffee powder',
                      '<span>1 tbs</span> warm water',
                      '<span>1/4 cup</span> caster sugar',
                      '<span>8</span> waffles thawed, lightly toasted',
                      '<span>3</span> eggs',
                      '<span>1/4 cup</span> milk',
                      '<span>1/4 cup</span> pouring cream',
                      '<span>60g</span> unsalted butter chopped',
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Spoon the yoghurt into a sieve lined with muslin or a new dish cloth and place over a bowl. Cover and refrigerate for 2 hours or until thick. Meanwhile, dissolve the coffee powder in water and cool. Spoon the thick yoghurt into a clean bowl, discarding the drained liquid. Swirl through the coffee and 1 tablespoon sugar.',
                      'Place toasted waffles onto a board. Spread a tablespoon of coffee labne onto each, leaving 1/2 cm border around all edges. Top four of the waffles with a few Perfection Strawberries and Perfection Blueberries. Sandwich together with remaining waffles and coffee labne side down. Place in a shallow dish in a single layer.',
                      'Combine remaining caster sugar, strawberries, blueberries in a bowl. Toss gently to combine. Cover and set aside.',
                      'Whisk eggs, milk and cream in a jug. Pour egg mixture over waffle sandwiches. Stand 3 minutes. Carefully turn and stand waffles a further 3 minutes.',
                      'Melt half the butter in a large non-stick frying pan over medium heat. Cook 2 waffle sandwiches, for 3 minutes each side or until golden. Keep warm. Repeat with remaining butter and waffle sandwiches. Place waffle French toasts onto serving plates. Top with a dollop of remaining espresso labne and spoon over Perfection berries and juices.'
                    ]
                  }
                ]
              }
            ]
          }, {
            question: 'Taste & Flavour',
            icon: TasteIcon,
            content: [{
                type: 'text',
                copy: 'Strawberries have a fruity, sweet and juicy taste balanced with a little acidity to get the tastebuds jumping. A bite full of berry bliss!'
              }
            ]
          }, {
            question: 'How do I keep this fresh<br>for the longest time?',
            icon: TimeIcon,
            content: [{
              type: 'text',
              copy: 'Strawberries are best kept dry and refrigerated. Rinse strawberries with cold water just before consumption. For optimal sweet flavours, allow strawberries to reach room temperature before serving.'
            }]
          }, {
            question: 'What can I<br> pair this with?',
            icon: RecipeIcon,
            content: [{
                type: 'text',
                copy: 'Perfect in desserts and smoothies, eat as is or thrown in a salad for a fresh twist.'
              },
            ]
          }
        ],
        seasonality: [5, 5, 5, 4, 2, 3, 3, 5, 3, 2, 3, 5]
      },
      {
        title: 'Blueberries',
        slug: 'blueberries',
        logo: '',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [
          {
            question: 'What are<br>Blueberries?',
            icon: HeartIcon,
            content: [{
              type: 'text',
              copy: 'Fresh, tangy and deliciously sweet. Did you know Blueberries are one of the only foods that are naturally blue?'
            }]
          }, {
            question: 'Recipe<br>Inspiration',
            icon: RecipeIcon,
            content: [
              {
                type: 'recipe',
                title: 'BLUEBERRY, COCONUT BANANA BREAD',
                image: BlueberryBananaBread,
                button: {
                  link: 'https://www.perfection.com.au/recipes/blueberry-coconut-banana-bread',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>125g</span> Perfection Blueberries',
                      '<span>125g</span> butter softened',
                      '<span>1/2 cup (80g)</span> brown sugar',
                      '<span>1/4 cup (60ml)</span> golden syrup',
                      '<span>2</span> eggs',
                      '<span>2</span> large ripe bananas mashed',
                      '<span>2 cups (300g)</span> self raising flour',
                      '<span>1/2 tsp</span> baking powder',
                      '<span>2/3 cup (40g)</span> flaked coconut',
                      'Butter and maple syryp to serve',
                    ]
                  }, {
                    title: 'Method',
                    main: true,
                    content: [
                      'Preheat oven to 160°C fan forced. Grease and line 6cm deep, 10cmx21cm (base) loaf pan.',
                      'Cream the butter, sugar and golden syrup with hand mixer until well combined. Add the eggs one at a time and beating well, mixture may curdle slightly. Stir in the banana. Sift the flour and baking powder together over the banana mixture. Stir gently to combine. Fold ½ cup of the coconut.',
                      'Spoon half the mixture into prepared loaf pan. Top with half the blueberries. Top with remaining banana mixture and poke in remaining blueberries. Scatter over the remaining coconut. Bake for 55-60 minutes or until cooked when tested with a skewer (see tip). Cool in the pan for 15 minutes before lifting out onto a wire rack to cool completely. Slice and serve as is or spread with butter and drizzled with maple syrup.'
                    ]
                  }
                ]
              },
              {
                type: 'recipe',
                title: 'CHEATS BLUEBERRY CREAM BUN',
                image: BlueberryCreamBun,
                button: {
                  link: 'https://www.perfection.com.au/recipes/cheats-blueberry-cream-bun',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>125g</span> Perfection Blueberries',
                      '<span>2 tbs</span> caster sugar',
                      '<span>½ cup (100g)</span> smooth ricotta',
                      '<span>100g</span> spreadable cream cheese',
                      '<span>8</span> small brioche buns warmed',
                      'Icing sugar mixture to serve'
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Wash the blueberries and place into a small frying pan with 1 tbs caster sugar. Place over medium heat. Warm, shaking pan for 2 minutes. Remove from heat and cool 15 minutes.',
                      'Beat ricotta, cream cheese and remaining caster sugar until well combined.',
                      'Split the brioche buns down the centre of tops. Spoon in 1 tablespoon ricotta mixture then top with blueberries and a little syrup from the pan. Serve dusted with icing sugar.'
                    ]
                  }
                ]
              },
              {
                type: 'recipe',
                title: 'BERRY GIN & TONIC POPSICLES',
                image: BerryGinPopsicle,
                button: {
                  link: 'https://www.perfection.com.au/recipes/berry-gin-tonic-popsicles',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>2 x 125g</span> Perfection Blueberries',
                      '<span>1 cup (220g)</span> caster sugar',
                      '<span>160ml (2/3 cup)</span> Gin',
                      '<span>400ml</span> tonic water',
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Combine blueberries, caster sugar and 1 cup (250ml) water in a saucepan. Stir over medium-high heat until sugar is dissolved. Bring to a gentle boil, then remove from heat immediately. Set aside to cool.',
                      'Add Gin and tonic to the blueberry mixture. Half fill 16x 80ml (1/3 cup) capacity ice-block moulds with blueberry gin and tonic mixture. Freeze two hours then fill with remaining mixture. Insert popsicle sticks. Freeze overnight.',
                    ]
                  }
                ]
              }
            ]
          }, {
            question: 'Taste & Flavour',
            icon: TasteIcon,
            content: [{
                type: 'text',
                copy: 'Fresh, tangy and deliciously sweet eaten raw. When cooked Blueberries enhance their sweetness and succulent intensity.'
              }
            ]
          }, {
            question: 'How do I keep this fresh<br>for the longest time?',
            icon: TimeIcon,
            content: [{
              type: 'text',
              copy: 'They’re best kept refrigerated for maximum juicy freshness.'
            }]
          }, {
            question: 'What can I<br> pair this with?',
            icon: RecipeIcon,
            content: [{
                type: 'text',
                copy: 'Blueberries pair well with other fruits like mango, banana, watermelon and lime. Also with spices like cinnamon, ginger.'
              }
            ]
          }, {
            question: 'Health & Nutrition',
            icon: HealthIcon,
            content: [{
                type: 'text',
                copy: "Naturally high in antioxidants as well as Low GI Blueberries are a superfood. They're a good source of Vit C, E, Potassium and fibre."
              }
            ]
          }
        ],
        seasonality: [2,5,3,1,1,1,2,3,5,5,4,1]
      },
      {
        title: 'Raspberries',
        slug: 'raspberries',
        logo: '',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [
          {
            question: 'What are<br>Raspberries?',
            icon: HeartIcon,
            content: [{
              type: 'text',
              copy: 'Lusciously soft with a sweet zing. Our exclusive variety of Raspberries are like no other.'
            }]
          }, {
            question: 'Recipe<br>Inspiration',
            icon: RecipeIcon,
            content: [
              {
                type: 'recipe',
                title: 'PULL APART RASPBERRY PAVLOVAS',
                image: RaspberryPavlova,
                button: {
                  link: 'https://www.perfection.com.au/recipes/pull-apart-raspberry-pavlova',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>2 x 125g</span> Perfection Raspberries',
                      '<span>8</span> egg whites',
                      '<span>2 cups (440g)</span> caster sugar',
                      '<span>1 tbs</span> cornflour',
                      '<span>3 tsp (15ml)</span> white vinegar',
                      '<span>300ml</span> thickened cream',
                      '<span>¼ cup (45g)</span> icing sugar mixture',
                      '<span>1 tsp</span> vanilla bean paste',
                      '<span>250g</span> mascarpone',
                      '<span>¼ cup (40g)</span> pistachio kernels toasted, chopped',
                      'Mint leaves to decorate, optional',
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Preheat oven 100°C fan forced. Trace a 20cm x 30cm rectangle on a piece of baking paper. Place baking paper, trace-side down, on a large, greased baking tray.',
                      'Place egg whites, caster sugar, cornflour and vinegar in a large bowl of an electric mixer. Beat on high speed for 10-14 minutes, or until sugar is dissolved and meringue is thick and shiny.',
                      'Using a heaped ½ cup of mixture, spoon onto the tray, side-by-side, with three crossways and four lengthways, inside the rectangle on the prepared tray. Bake 11/2 hours, or until pavlova feels dry and crisp. Turn oven off, leave door ajar and allow pavlova to cool completely in the oven.',
                      'Using the baking paper, lift the pavlova onto a serving plate or board. Beat cream, 2 tablespoons of the sugar and vanilla with a hand mixer until soft peaks form. Gently fold in the mascarpone. Spoon cream evenly over each meringue. Top with raspberries, sprinkle with pistachios and mint leaves. Dust with remaining icing sugar to serve. To serve gently pull each meringue apart.',
                    ]
                  }
                ]
              },
              {
                type: 'recipe',
                title: 'RASPBERRY CUSTARD DOUGHNUTS',
                image: RaspberryDoughnuts,
                button: {
                  link: 'https://www.perfection.com.au/recipes/raspberry-custard-doughnuts',
                  text: 'Learn more about recipes'
                },
                recipe: [{
                    title: 'Ingredients',
                    main: true,
                    content: [
                      '<span>2 x 125g</span> Perfection Raspberries',
                      '<span>3</span> egg yolks',
                      '<span>1/2 cup (115g)</span> caster sugar',
                      '<span>1 tsp</span> vanilla paste',
                      '<span>1 tbs</span> cornflour',
                      '<span>2 tbs</span> plain flour',
                      '<span>11/2 cups (375ml)</span> full cream milk',
                    ]
                  },
                  {
                    title: 'Doughnuts',
                    content: [
                      '<span>1/3 cup (80g)</span> caster sugar',
                      '<span>¾ cup (180ml)</span> milk warmed',
                      '<span>60g</span> butter melted',
                      '<span>1</span> egg yolk',
                      'Vegetable oil for frying',
                      '<span>½ cup (110g)</span> white sugar',
                      '<span>21/4 cups (340g)</span> plain flour',
                      '<span>2 tsp (6g)</span> instant dried yeast',
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Whisk egg yolks, sugar and vanilla in a bowl. Whisk in cornflour and flour. Add milk, 1/4 cup at a time, whisking well after each addition. Transfer to a saucepan and cook, stirring constantly, over medium heat for 10 minutes or until custard comes to the boil. Pour custard into a heatproof bowl. Cover the surface with plastic wrap. Refrigerate until cold. Beat lightly to loosen the custard.',
                      'For the doughnuts; Combine the flour, yeast, sugar, and a good pinch salt in a bowl of a stand mixer. Add the milk, butter and egg yolk. Beat on low speed with dough hook until combined. Beat on low for further 5 minutes until dough is smooth and elastic, but still sticky. Place the dough in a lightly oiled bowl, turning once to coat the top of the dough with oil. Cover with plastic wrap and tea towel. Set aside to rise in a warm place for 1 hour or until doubled in size.',
                      'When the dough is ready, turn out onto a lightly floured bench. Knead gently until smooth. Divide dough into 24 equal portions. Roll each into a smooth ball. Place on a lined baking tray. Cover with plastic wrap. Set aside 30-45 minutes until they almost double in size.',
                      'Crush the raspberries with a fork. Heat oil in a medium, deep saucepan to 160°C. Fry the doughnuts 4 at a time, turning constantly for about 4, turning until golden. Remove with a slotted spoon to a tray scattered with the white sugar, turn to coat in immediately.',
                      'Cut a slit in the top of each doughnut. Spoon in custard. Top with crushed raspberries. Serve.',
                    ]
                  }
                ]
              },
              {
                type: 'recipe',
                title: 'RASPBERRY YOGHURT SWIRL',
                image: RaspberryYoghurt,
                button: {
                  link: 'https://www.perfection.com.au/recipes/raspberry-yoghurt-swirl',
                  text: 'Learn more about recipes'
                },
                recipe: [
                  {
                    title: 'Ingredients',
                    content: [
                      '<span>2 x 125g</span> Perfection Perfection Raspberries',
                      '<span>500g</span> greek yoghurt',
                      '<span>2 tsp</span> vanilla bean paste',
                      '<span>1 tbs</span> caster or coconut sugar',
                      '<span>2 tbs</span> flaked coconut toasted',
                    ]
                  },
                  {
                    title: 'Method',
                    main: true,
                    content: [
                      'Spoon yoghurt into a sieve lined with muslin or an un-used cloth. Place over a bowl, cover and refrigerate for 3 hours. Discard the liquid in the bowl. Tip the yoghurt into a dish. Stir in the vanilla.',
                      'Tip 125g of raspberries onto a plate. Sprinkle over the sugar, crush with a fork. Spoon over the yoghurt and use a spoon to fold through the yoghurt in a swirled effect. Cover and refrigerate until ready to serve.',
                      'Scatter over the coconut and remaining raspberries. Serve.',
                    ]
                  }
                ]
              }
            ]
          },
          {
            question: 'Taste & Flavour',
            icon: TasteIcon,
            content: [{
                type: 'text',
                copy: 'Delicate clouds of flavour discovery. Lusciously sweet with a lightening-quick zing. Perfect raw of cooked.'
              }
            ]
          },
          {
            question: 'How do I keep this fresh<br>for the longest time?',
            icon: TimeIcon,
            content: [{
              type: 'text',
              copy: 'Handle these delicate berries with care. Refrigerate them in their punnet, and keep them dry to reduce wastage. Freeze for prolonged preserving.'
            }]
          },
          {
            question: 'What can I<br> pair this with?',
            icon: RecipeIcon,
            content: [{
                type: 'text',
                copy: 'Serve them for breakfast over cereal, toss them in your favourite smoothie or yoghurt.'
              }
            ]
          },
          {
            question: 'Health & Nutrition',
            icon: HealthIcon,
            content: [{
                type: 'text',
                copy: 'Perfection Raspberries come with a long list of goodies including antioxidants, iron, magnesium, potassium and zinc.'
              }
            ]
          }
        ],
        seasonality: [2,2,3,4,5,5,5,4,2,3,5,4]
      }
    ],
    grownProcess: [
        {
        id: 1,
        icon: 'plant',
        videoLink: '',
        animation: 'environment',
        copy: 'The tunnels are designed to protect the crop from weather elements whilst allowing air to circulate freely and keeps the environment warmer in the cooler months.'
      }, {
        id: 2,
        icon: 'plant',
        videoLink: '',
        animation: 'growing',
        copy: 'Berries are grown in rows hydropnically in bags as well as in the ground. Bees fly into the tunnels to naturally polliante the plants, we also have bees hives on site to help mother nature do her work.'
      }, {
        id: 3,
        icon: 'plant',
        videoLink: '',
        animation: 'harvest',
        copy: 'Workers move up and down the rows of berries, hand picking them and placing them into containers to go to the packing house.'
      }, {
        id: 4,
        icon: 'plant',
        videoLink: '',
        animation: 'packing',
        copy: 'Berries are cooled, then checked for quality and packed ready for delivery to fruit & vege markets and supermarket shelves around Australia.'
      }
    ]
  },
  {
    id: 3,
    title: 'Plantation',
    slug: 'plantation',
    character: {
      animation: 'idle',
      position: {
        x: 0.527,
        y: 0.1,
        z: -1.573
      }
    },
    products: [
      {
        title: 'Calypso Mangoes',
        slug: 'mangoes',
        logo: '',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
          question: 'What are<br>Calypso Mangoes?',
          icon: HeartIcon,
          content: [{
            type: 'text',
            copy: 'Calypso® mangoes are all about pure, juicy sweetness with a soft, fibre-free flesh. With more flesh, less seed!'
          }]
        }, {
          question: 'Recipe<br>Inspiration',
          icon: RecipeIcon,
          content: [
            {
              type: 'recipe',
              title: 'BARBECUE CHILLI LIME CALYPSO® MANGO WITH PRAWNS',
              image: MangoPrawns,
              button: {
                link: 'https://www.perfection.com.au/recipes/barbecue-chilli-lime-calypso-mango-with-prawns',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>4</span> Calypso® Mangoes chilled',
                    '<span>2</span> Limes',
                    '<span>1 tsp</span> Dried chilli flakes',
                    '<span>1/3 cup</span> White sugar',
                    '<span>1 tsp</span> Sea salt flakes',
                    '<span>700g</span> Cooked prawns peeled, deveined',
                    '<span>60g</span> Rocket leaves',
                  ]
                },
                {
                  title: 'Method',
                  main: true,
                  content: [
                    'Grate the rind from the limes. Place rind, chilli, sugar and salt into a small food processor. Process until well combined. Transfer to a bowl.',
                    'Half the limes. Juice two lime halves.',
                    'Slice the cheeks from each Calypso® mango. Score each portion deeply into the flesh in a cross-hatch pattern, cutting down but not through the skin. Spoon lime juice over the mangoes, allowing juice to run between cuts. Sprinkle 1 tsp chilli lime mixture over each.',
                    'Preheat a clean barbecue plate or chargrill on high heat. Place a piece baking paper onto the hot barbecue plate. Working quickly, place 4 mango cheeks, cut side down onto the paper. Barbecue for 4-5 minutes until charred. Remove to a board. Repeat with remaining mango, using a fresh sheet of baking paper.',
                    'Sprinkle a little more chilli lime mixture over each mango half. Serve with fresh prawns and rocket.',
                  ]
                }
              ]
            },
            {
              type: 'recipe',
              title: 'DAIRY FREE CALYPSO® MANGO BREAKFAST BOWL',
              image: MangoBreakfastBowl,
              button: {
                link: 'https://www.perfection.com.au/recipes/dairy-free-calypso-mango-breakfast-bowl',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>3</span> Calypso® Mangoes chilled',
                    'Perfection Strawberries, Perfection Raspberries, Perfection Blueberries, Perfection Blackberries to serve',
                    '<span>2</span> limes quartered',
                    '<span>1 cup</span> coconut water',
                    'coconut yoghurt to serve',
                    '<span>1/3 cup</span> maple syrup [[Oven baked muesli]]',
                    '<span>1/4 cup</span> light olive oil or melted coconut oil [[Oven baked muesli]]',
                    '<span>3 cups</span> traditional rolled oats [[Oven baked muesli]]',
                    '<span>1 cup</span> quinoa [[Oven baked muesli]]',
                    '<span>3 tbs</span> each sesame seeds, pumpkin seeds and sunflower seeds [[Oven baked muesli]]',
                    '<span>1/2 cup</span> flaked coconut [[Oven baked muesli]]',
                    '<span>500g</span> fruit and nut trail mix [[Oven baked muesli]]'
                  ]
                },
                {
                  title: 'Method',
                  main: true,
                  content: [
                    'Preheat oven to 150°C fan forced. Lightly grease a large roasting pan. Squeeze 4 lime quarters into the coconut water. Refrigerate until ready to serve.',
                    'For the oven baked muesli, combine maple syrup and oil in a jug, mix well. Combine oats, quinoa, seeds, coconut and fruit and nut mix in the roasting pan. Pour over maple mixture, mix until well coated. Spread mixture evenly over base of the pan. Bake for 45-50 minutes or until golden and toasted, do not stir. Set aside to cool completely. Break into pieces, transfer to an airtight jar until ready to serve.',
                    'Cut the cheeks from the mangoes. Using a large spoon, remove the mango fruit from two cheeks, thinly slice crossways. Score remaining 4 cheeks deeply into the flesh in a cross-hatch pattern, cutting down but not through the skin.',
                    'To serve, spoon muesli into bowls. Top with yoghurt, sliced mango and berries. Finish with scored mango cheeks. Drizzle with chilled lime coconut water. Serve with remaining lime wedges.'
                  ]
                }
              ]
            },
            {
              type: 'recipe',
              title: 'CALYPSO® MANGO PISTACHIO PAVLOVA WREATH WITH HAZELNUT SPREAD',
              image: MangoPavlova,
              button: {
                link: 'https://www.perfection.com.au/recipes/calypso-mango-pistachio-pavlova-wreath-with-hazelnut-spread/',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>4</span> Calypso® Mangoes chilled',
                    '<span>6</span> large egg whites',
                    '<span>1 1/2 cup</span> caster sugar',
                    '<span>1 tsp</span> white vinegar',
                    '<span>1 tsp</span> corn flour',
                    '<span>3/4 cup</span> pistachio kernels lightly toasted, chopped',
                    '<span>450ml</span> thickened cream whipped',
                    '<span>1/2 cup</span> Hazelnut Spread',
                    'grated milk chocolate to serve (optional)',
                  ]
                },
                {
                  title: 'Method',
                  main: true,
                  content: [
                    'Preheat oven to 130°C fan forced. Draw a 22cm circle on a sheet of non-stick baking paper and place, marked side down on a large greased baking tray.',
                    'Whisk the eggwhites on high speed in an electric mixer until soft peaks form. Gradually add the sugar, 1 spoon at a time, whisking until meringue is thick and glossy. After the last spoon, continue to beat on high speed a further 3 minutes. Fold in the vinegar then cornflour. Fold in 1/2 cup of the pistachios.',
                    'Drop large spoonfuls of the meringue on the tray using the circle as a guide. Sprinkle over half the pistachio. Bake for 1 hour 20 or until the top feels crisp and dry. Turn the oven off, leave the pavlova to cool completely in the oven with the door closed.',
                    'Just before serving, slide the pavlova wreath on a serving plate. Swirl the cream and hazelnut spread together. Spoon over the pavlova. Cut the cheeks from the mangoes. Using a large spoon, remove the mango fruit from the cheeks, slice. Arrange over the cream. Sprinkle with remaining pistachio and grated chocolate if desired. Serve.',
                  ]
                }
              ]
            }
          ]
        },
        {
          question: 'Taste & Flavour',
          icon: TasteIcon,
          content: [{
              type: 'text',
              copy: 'What a charm to eat! Our Calypso® mangoes are the golden daughter of a Kensington Pride and Sensation Mango, the Calypso® takes the best of both varieties.'
            }
          ]
        },
        {
          question: 'How do I keep this fresh<br>for the longest time?',
          icon: TimeIcon,
          content: [{
            type: 'text',
            copy: 'Store at room temperature and out of the sun until the fruit ripens. To avoid the mango being over-ripe, move the mango to the fridge. Avoid storing ripe mangoes in plastic bags – mangoes need air!'
          }]
        },
        {
          question: 'What can I<br> pair this with?',
          icon: RecipeIcon,
          content: [{
              type: 'text',
              copy: 'The star of the show! Perfect on pavlova or thinly sliced on pancakes. For a savoury snack, skewer with cooked prawns.'
            }
          ]
        },
        {
          question: 'Health & Nutrition',
          icon: HealthIcon,
          content: [{
              type: 'text',
              copy: 'Calypso® mangoes are the perfect sweet snack with around 80kcal in each cheek. Also packed with Vit A & C.'
            }
          ]
        }],
        seasonality: [5,3,0,0,0,0,0,0,3,4,5,5]
      },
      {
        title: 'Red Tip Bananas',
        slug: 'red-tip',
        logo: '',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
          question: 'What are<br>Red Tip Bananas?',
          icon: HeartIcon,
          content: [{
            type: 'text',
            copy: 'Red Tip® Bananas are grown using the Ecoganic® farming system making them good for you and the environment.'
          }]
        }, {
          question: 'Taste & Flavour',
          icon: TasteIcon,
          content: [{
              type: 'text',
              copy: 'A banana that tastes like it should – sweet & creamy. Red Tip® Bananas are firm and fleshy allowing for easy slicing.'
            }, {
              type: 'text',
              copy: 'Grown as nature intended without harsh chemicals, making them perfect for the environment and you.'
            }
          ]
        }, {
          question: 'How do I keep this fresh<br>for the longest time?',
          icon: TimeIcon,
          content: [{
            type: 'text',
            copy: 'Red Tip® Bananas are best stored in a cool, dry place out of direct sunlight.'
          }]
        }, {
          question: 'What can I<br> pair this with?',
          icon: RecipeIcon,
          content: [{
              type: 'text',
              copy: 'Perfect for a morning energizer in a smoothie, on top of cereal, porridge or yoghurt. Also ideal on their own as a healthy snack on the go.'
            }, {
              type: 'text',
              copy: 'These delicious Red Tip® Bananas pair perfectly with blueberries, raspberries, caramel and coconut.'
            }
          ]
        },
        {
          question: 'Health & Nutrition',
          icon: HealthIcon,
          content: [{
              type: 'text',
              copy: 'Nature has packaged up the perfect healthy, sweet snack without any nasties.'
            }, {
              type: 'text',
              copy: 'Naturally high in Vit B6 and Potassium with slow-release low GI carbohydrates for longer lasting energy.'
            }, {
              type: 'text',
              copy: 'A medium sized banana is <100 calories.'
            }
          ]
        }],
        seasonality: [5,5,5,5,5,5,5,5,5,5,5,5]
      },
      {
        title: 'Havana Bananas',
        slug: 'havana',
        logo: '',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
          question: 'What are<br>Havana Bananas?',
          icon: HeartIcon,
          content: [{
            type: 'text',
            copy: 'Havana™ is a medium sized, bright yellow skinned banana with a delicious creamy taste.'
          }]
        }, {
          question: 'Taste & Flavour',
          icon: TasteIcon,
          content: [{
              type: 'text',
              copy: 'Creamy mouthful with moderately sweet flavour & aroma. Best eaten when fully ripe and bright yellow.'
            }
          ]
        }, {
          question: 'How do I keep this fresh<br>for the longest time?',
          icon: TimeIcon,
          content: [{
            type: 'text',
            copy: 'Store them on the bench at room temperature, away from direct sunlight.'
          }]
        }, {
          question: 'What can I<br> pair this with?',
          icon: RecipeIcon,
          content: [{
              type: 'text',
              copy: 'Great on its own or add to your fruit salads, yoghurt, cereal, oatmeal and smoothies.'
            }, {
              type: 'text',
              copy: 'A great partner to other fruits, spices & nuts. Also great as a sweet hit with caramel and chocolate.'
            }
          ]
        },
        {
          question: 'Health & Nutrition',
          icon: HealthIcon,
          content: [{
              type: 'text',
              copy: 'Naturally high in Vit B6 and Potassium with slow-release low GI carbohydrates for longer lasting energy.'
            }, {
              type: 'text',
              copy: 'A medium sized banana is <100 calories.'
            }
          ]
        }],
        seasonality: [5,5,5,5,5,5,5,5,5,5,5,5]
      },
      {
        title: 'Little Gem Bananas',
        slug: 'little-gem',
        logo: '',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
          question: 'What are<br>Little Gem Bananas?',
          icon: HeartIcon,
          content: [{
            type: 'text',
            copy: 'The Perfect Mini Snack! These delicious gems are the perfect snack sized bananas, making them perfect for kids and lunchbox sized fruit.'
          }]
        }, {
          question: 'Taste & Flavour',
          icon: TasteIcon,
          content: [{
              type: 'text',
              copy: 'Balanced creamy & sweet taste. Not sickly sweet.'
            }
          ]
        }, {
          question: 'How do I keep this fresh<br>for the longest time?',
          icon: TimeIcon,
          content: [{
            type: 'text',
            copy: 'Store them on the bench at room temperature, away from direct sunlight.'
          }]
        }, {
          question: 'What can I<br> pair this with?',
          icon: RecipeIcon,
          content: [{
              type: 'text',
              copy: 'Eat on the go or add to a lunchbox. Slice them fresh and add to fruit salads, yoghurt, cereal, smoothies.'
            }
          ]
        },
        {
          question: 'Health & Nutrition',
          icon: HealthIcon,
          content: [{
              type: 'text',
              copy: 'Naturally high in Vit B6 and Potassium with slow-release low GI carbohydrates for longer lasting energy.'
            }
          ]
        }],
        seasonality: [5,5,5,5,5,5,5,5,5,5,5,5]
      }
    ],
    grownProcess: [
      {
      id: 1,
      icon: '',
      videoLink: PT_environment,
      animation: 'environment',
      copy: 'Our large mango and banana plantations are in Northern Australia to take advantage of the warm growing conditions.'
    }, {
      id: 2,
      icon: '',
      videoLink: PT_growing,
      animation: 'growing',
      copy: 'For Mangoes, the trees form buds in May, flowers grow, then fruit grows from each flower picked from September. Bananas grow all year. Firstly the flower bell forms & fruit grows from the bell, it\'s covered and protected from birds, waiting to be pick at the right time.'
    }, {
      id: 3,
      icon: '',
      videoLink: PT_harvest,
      animation: 'harvest',
      copy: 'Pickers handpick the mangoes individually & bananas in large bunches and place them onto a moving tractor to be washed or taken back to the packing area.'
    }, {
      id: 4,
      icon: '',
      videoLink: PT_packing,
      animation: 'packing',
      copy: 'Produce is checked for quality and packed ready for delivery to fruit & vege markets and supermarket shelves around Australia.'
    }
  ]
  },
  {
    id: 4,
    title: 'Farmlands',
    slug: 'farmlands',
    character: {
      animation: 'idle',
      rotation: 20,
      position: {
        x: -1.648,
        y: 0,
        z: -0.916
      }
    },
    products: [
      {
        title: 'Broccolini',
        slug: 'broccolini',
        logo: '',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
          question: 'What are<br>Broccolini?',
          icon: HeartIcon,
          content: [{
            type: 'text',
            copy: 'Long green stems: A mix of Broccoli and Chinese kale, which are mild, peppery, sweet and tender.'
          }]
        }, {
          question: 'Recipe<br>Inspiration',
          icon: RecipeIcon,
          content: [
            {
              type: 'recipe',
              title: 'ROASTED BROCCOLINI® & POTATO WEDGE SALAD WITH JALAPENO DRESSING',
              image: BroccoliniPotatoWedge,
              button: {
                link: 'https://www.perfection.com.au/recipes/roasted-broccolini-potato-wedge-salad-with-jalapeno-dressing-2',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>1</span> Broccolini® family bunch',
                    '<span>125g</span> Perfection Blackberries',
                    '<span>1.25kg</span> large washed potatoes cut into wedges',
                    '<span>2 tbs</span> olive oil',
                    '<span>100g</span> smokey chipotle seasoning or Fajita spice mix',
                    '<span>60g</span> baby rocket',
                    '<span>60g</span> baby spinach',
                    '<span>4 tbs</span> extra virgin olive oil [[JALAPENO DRESSING]]',
                    '<span>2</span> limes juiced [[JALAPENO DRESSING]]',
                    '<span>3 tsp</span> brown sugar [[JALAPENO DRESSING]]',
                    '<span>1 tbs</span> chopped drained pickled jalapenos [[JALAPENO DRESSING]]',
                  ]
                }, {
                  title: 'Method',
                  main: true,
                  content: [
                    'Preheat oven to 230°C fan forced. Arrange potatoes in a large greased roasting pan and spoon over 1 tablespoon oil. Season with salt and turn to coat. Roast for 30 minutes. Remove from the oven and turn potatoes over. Return to the oven for 10 minutes.',
                    'Add the Broccolini® to another roasting pan. Spoon over remaining oil and sprinkle with spice, turning to coat. Roast for 10 minutes on the shelf under the potatoes. Set both potatoes and Broccolini® aside to cool to room temperature.',
                    'Arrange the rocket and spinach on a large serving platter or board. Top with Broccolini®, including spices from pan and potatoes. Combine all the dressing ingredients and spoon over the salad just before serving. Serve.'
                  ]
                }
              ]
            },
            {
              type: 'recipe',
              title: 'CHEESY BROCCOLINI®',
              image: BroccoliniCheesy,
              button: {
                link: 'https://www.perfection.com.au/recipes/cheesy-broccolini',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>2</span> bunches of Broccolini®',
                    '<span>1</span> garlic clove crushed',
                    '<span>2 tbsp</span> olive oil',
                    'Pinch of Salt & Pepper',
                    '<span>2 tbsp</span> pecorino cheese',
                    '<span>¼ cup</span> parmesan or pecorino [[Parmesan & breadcrumb sprinkle]]',
                    '<span>½ cup</span> Panko bread crumbs [[Parmesan & breadcrumb sprinkle]]',
                    '<span>½ tsp</span> paprika [[Parmesan & breadcrumb sprinkle]]',
                    'Pinch of salt & pepper [[Parmesan & breadcrumb sprinkle]]',
                  ]
                },
                {
                  title: 'Method',
                  main: true,
                  content: [
                    'Pre-heat oven to 200°C.',
                    'In a large mixing bowl, add Broccolini®, garlic, olive oil, salt, pepper and pecorino and mix well. Make sure the Broccolini® is fully seasoned.',
                    'On a lined baking tray, lay the Broccolini® flat and spread out.',
                    'In a small mixing bowl, mix together the parmesan, breadcrumbs, paprika, salt and pepper.',
                    'Sprinkle the entire mixture over the Broccolini®.',
                    'Place the tray into the oven and cook for 15 minutes. You want the Broccolini® to have a slight crunch, so careful not to overcook!',
                    'Serve with a big squeeze of lemon and more cheese!',
                  ]
                }
              ]
            },
            {
              type: 'recipe',
              title: 'HONEY SOY & SESAME BROCCOLINI®',
              image: BroccoliniHoneySoy,
              button: {
                link: 'https://www.perfection.com.au/recipes/honey-soy-sesame-broccolini',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>2</span> bunches Broccolini®',
                    '<span>3 tsp</span> sesame seeds',
                    '<span>1 tbs</span> peanut or vegetable oil',
                    '<span>1 tbs</span> soy sauce',
                    '<span>1 tbs</span> honey',
                    'few drops sesame oil'
                  ]
                }, {
                  title: 'Method',
                  main: true,
                  content: [
                    'Tip sesame seeds into a cold wok and heat over high heat, stirring until sesame seeds are golden. Remove to a plate.',
                    'Reheat wok over a high heat until very hot. Add oil and swirl to coat the wok. Add the Broccolini® and stir-fry for 1 minute. Add 2 tsp water, cover and cook for 30 seconds until bright green.',
                    'Combine the soy, honey and sesame oil, pour over the Broccolini® and stir-fry for 1 minute until hot. Scatter over the sesame seeds and serve.',
                  ]
                }
              ]
            }
          ]
        },
        {
          question: 'Taste & Flavour',
          icon: TasteIcon,
          content: [{
              type: 'text',
              copy: 'Sweet, tender & entirely edible'
            }, {
              type: 'text',
              copy: ' Mild, sweet and tender with a slight peppery edge. And not only is it completely delicious, it’s also entirely edible – nothing to cut, nothing to waste.'
            }
          ]
        },
        {
          question: 'How do I keep this fresh<br>for the longest time?',
          icon: TimeIcon,
          content: [{
            type: 'text',
            copy: 'Store sealed in your fridge and cook within a few days of purchase.'
          }]
        },
        {
          question: 'What can I<br> pair this with?',
          icon: RecipeIcon,
          content: [{
              type: 'text',
              copy: 'Flavours that will make your dish sing'
            }, {
              type: 'text',
              copy: ' Stir-fry with a little garlic and ginger & drizzle over a sweet chilli dressing for a simple yet delicious side.'
            }
          ]
        },
        {
          question: 'Health & Nutrition',
          icon: HealthIcon,
          content: [{
              type: 'text',
              copy: 'Cooked in minutes, packed with nutrients!'
            },
            {
              type: 'text',
              copy: ' Filled with potassium for a happy heart, immunity-boosting vitamin C, and beta-carotene for healthy skin.'
            }
          ]
        }],
        seasonality: [5,5,5,5,5,5,5,5,5,5,5,5]
      },
      {
        title: 'Bambino cabbage',
        slug: 'cabbage',
        logo: '',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
          question: 'What are<br>Bambino® Cabbage?',
          icon: HeartIcon,
          content: [{
            type: 'text',
            copy: 'The new perfectly sized salad cabbage. Bambino® sweet baby cabbage merges the best qualities of cabbage and lettuce.'
          }]
        }, {
          question: 'Recipe<br>Inspiration',
          icon: RecipeIcon,
          content: [
            {
              type: 'recipe',
              title: 'ROASTED BAMBINO® BABY CABBAGE WITH LEMON AND CAPER DRESSING',
              image: CabbageLemonCaper,
              button: {
                link: 'https://www.perfection.com.au/recipes/roasted-bambino-baby-cabbage-with-lemon-and-caper-dressing/',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>2</span> Bambino® Wombok Hearts',
                    '<span>100ml</span> extra virgin olive oil',
                    '<span>2 tsp</span> red wine vinegar',
                    '<span>1</span> small lemon juiced',
                    '<span>1 cup</span> flat-leaf parsley chopped',
                    '<span>1/2 cup</span> mint leaves chopped',
                    '<span>2 tbs</span> chopped chives',
                    '<span>1</span> garlic clove crushed',
                    '<span>2 tbs</span> capers finely chopped',
                  ]
                }, {
                  title: 'Method',
                  main: true,
                  content: [
                    'Preheat oven to 220°C fan forced. Cut the cabbage into quarters lengthways. Arrange cabbage wedges in a greased roasting pan. Drizzle with 1 tablespoon of the oil and season with salt and pepper. Turn the wedges over, drizzle other side with 1 tablespoon oil and season.',
                    'Roast for 15-20 minutes or until golden, crisp and wilted.',
                    'Meanwhile, combine the remaining 60ml oil, vinegar and lemon juice in a bowl and season. Whisk to combine. Stir in the remaining ingredients. Spoon over the warm roasted cabbage wedges. Serve.'
                  ]
                }
              ]
            },
            {
              type: 'recipe',
              title: 'BAMBINO® BABY CABBAGE SNACK ATTACK',
              image: CabbageSnackAttack,
              button: {
                link: 'https://www.perfection.com.au/recipes/bambino-baby-cabbage-snack-attack/',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>1</span> Bambino® Sweet Baby Cabbage',
                    '<span>6</span> Sweet Solanato® Tomatoes sliced',
                    '<span>1/2 cup</span> chipotle guacamole (see tip)',
                    '<span>6 slices (60g)</span> prosciutto or ham shaved',
                    'Fresh herbs to garnish optional'
                  ]
                },
                {
                  title: 'Method',
                  main: true,
                  content: [
                    'Remove 2 leaves from the cabbage, reserve the remaining cabbage for another use. Top each Bambino® baby cabbage leaf with 1/4 cup guacamole, 3 slices prosciutto or ham and 3 sliced tomatoes. Season and serve.<br><span class="recipe-tip">Tip: To make the chipotle guacamole, stir 2 tsp chipotle sauce through purchased guacamole or 1 smashed avocado. Variations Basil hummus, sliced cooked chicken & shredded parmesan Beetroot dip, smoked salmon and diced Qukes® baby cucumbers Smokey eggplant dip, bought antipasto and crumbled feta</span>',
                    'Remove 2 leaves from the cabbage, reserve the remaining cabbage for another use.',
                    'Top each Bambino® baby cabbage leaf with 1/4 cup guacamole, 3 slices prosciutto or ham and 3 sliced tomatoes. Season and serve',
                    'Basil hummus, sliced cooked chicken & shredded parmesan',
                    'Beetroot dip, smoked salmon and diced Qukes® baby cucumbers',
                    'Smokey eggplant dip, bought antipasto and crumbled feta',
                  ]
                }
              ]
            }
          ]
        },
        {
          question: 'Taste & Flavour',
          icon: TasteIcon,
          content: [{
              type: 'text',
              copy: 'Bambino® sweet baby cabbage is a tornado of leaves curled together. Unfurl the leaves for a sweet, crunchy bite – perfection in salads, snacks and light meals.'
            }
          ]
        },
        {
          question: 'How do I keep this fresh<br>for the longest time?',
          icon: TimeIcon,
          content: [{
            type: 'text',
            copy: 'Bambino® baby cabbage is best kept dry and refrigerated in its original packaging. Wash with cold water right before consumption.'
          }]
        },
        {
          question: 'What can I<br> pair this with?',
          icon: RecipeIcon,
          content: [{
              type: 'text',
              copy: 'The perfect ingredient for a light meal or used as a carb replacement. Bambino® sweet baby cabbage also brings cabbage into the world of entertaining and snacking. Fill it with your favourite dip and cold meats or toss it in a stir fry.'
            }
          ]
        },
        {
          question: 'Health & Nutrition',
          icon: HealthIcon,
          content: [{
              type: 'text',
              copy: 'Very low in calories with virtually no carbs, yet high in fibre and Vit C.'
            }
          ]
        }],
        seasonality: [5,5,5,4,5,5,5,5,5,5,5,5]
      },
      {
        title: 'Bambino Brussel Sprouts',
        slug: 'bambino',
        logo: '',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
          question: 'What are<br>Bambino Brussels Sprouts?',
          icon: HeartIcon,
          content: [{
            type: 'text',
            copy: 'Bambino Brussels Sprouts are young, tender and delicious.'
          }]
        }, {
          question: 'Recipe<br>Inspiration',
          icon: RecipeIcon,
          content: [
            {
              type: 'recipe',
              title: 'ROASTED PARMESAN BABY BRUSSELS SPROUTS',
              image: BrusselParmasan,
              button: {
                link: 'https://www.perfection.com.au/recipes/roasted-parmesan-baby-brussels-sprouts/',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>300g</span> Bambino® Baby Brussels Sprouts',
                    '<span>2 tbs</span> Olive oil',
                    '<span>1</span> Large Garlic clove crushed',
                    '<span>40g</span> Parmesan finely grated',
                  ]
                }, {
                  title: 'Method',
                  main: true,
                  content: [
                    'Preheat oven to 200°C fan forced. Lightly grease a small roasting pan.',
                    'Cut the stem off the end of the sprouts and remove any yellow outer leaves. Cut sprouts in half and arrange in the roasting pan. Combine the oil and garlic then spoon over the sprouts, toss gently to coat. Season well with salt and pepper. Roast for 20 minutes until sprouts start to colour.',
                    'Scatter over the parmesan and roast a further 10 minutes until leaves are crisp and centre of the sprouts are just tender. Serve.'
                  ]
                }
              ]
            },
            {
              type: 'recipe',
              title: 'BABY BRUSSLES SPROUTS, PANCETTA & HALOUMI FRITTERS',
              image: BrusselPancetta,
              button: {
                link: 'https://www.perfection.com.au/recipes/baby-brussles-sprouts-pancetta-and-haloumi-fritters/',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>250g</span> Sweet Solanato ®Tomatoes halved [[SALAD]]',
                    '<span>300g</span> Bambino® Wombok Hearts trimmed, halved',
                    '<span>4</span> Qukes® baby cucumbers chopped [[SALAD]]',
                    '<span>200g</span> Pancetta or rindless streaky bacon chopped',
                    '<span>2 (300g total)</span> Carrots grated',
                    '<span>125g</span> Haloumi grated',
                    '<span>4</span> Green onions finely chopped',
                    '<span>1 cup</span> Self-raising flour',
                    '<span>4</span> Eggs lightly beaten',
                    '<span>1 tbs</span> Light olive oil for cooking',
                    'Chilli mayonaise or lemon wedges to serve, optional',
                    '<span>50g</span> Baby rocket [[SALAD]]',
                    'Extra virgin olive oil [[SALAD]]',
                  ]
                },
                {
                  title: 'Method',
                  main: true,
                  content: [
                    'Preheat oven to 160°C fan forced. Place pancetta or bacon in a frying pan over medium heat, cook, stirring for 3 minutes until light golden. Remove to a large bowl and cool for 10 minutes.',
                    'Thinly slice the Brussels sprouts. Add to the pancetta with carrot, haloumi and green onions and mix well. Add the flour and mix well. Pour in the eggs, season and mix until well combined.',
                    'Cover the base of a large non-stick frying pan with oil and heat over a medium heat. Using 1/3 cup of the Brussel Sprout mixture per fritter, carefully spoon into the pan. Cook in batches for 3-4 minutes on each side, or until golden. Remove to a tray. Cook remaining fritters. Transfer the tray to the oven and cook for 10 minutes.',
                    'Meanwhile, combine the salad ingredients. Serve the fritters, with salad and mayonnaise or lemon wedges.'
                  ]
                }
              ]
            }
          ]
        },
        {
          question: 'Taste & Flavour',
          icon: TasteIcon,
          content: [{
              type: 'text',
              copy: 'Bambino® Baby Brussels are tender, mild and sweet. The smaller the yummier!'
            }
          ]
        },
        {
          question: 'How do I keep this fresh<br>for the longest time?',
          icon: TimeIcon,
          content: [{
            type: 'text',
            copy: 'Keep them in their punnet and store in your fridge crisper. At their best within the first few days.'
          }]
        },
        {
          question: 'What can I<br> pair this with?',
          icon: RecipeIcon,
          content: [{
              type: 'text',
              copy: 'Sweet ingredients like maple syrup, apples & honey as well as savoury like bacon, miso & mustard.'
            }
          ]
        },
        {
          question: 'Health & Nutrition',
          icon: HealthIcon,
          content: [{
              type: 'text',
              copy: 'Filled with good health like cell-stimulating folate (folic acid), immuno-boosting vitamin C, vitamin K for strong bones.'
            }
          ]
        }],
        seasonality: [1,2,3,4,5,5,5,5,4,3,0,0]
      },
      {
        title: 'Fioretto',
        slug: 'cauliflower',
        logo: '',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
          question: 'What are<br>Fioretto Cauliblossom?',
          icon: HeartIcon,
          content: [{
            type: 'text',
            copy: 'Meet cauliflower’s elegant, good-looking cousin with tall and willowy creamy green stems.'
          }]
        }, {
          question: 'Recipe<br>Inspiration',
          icon: RecipeIcon,
          content: [
            {
              type: 'recipe',
              title: 'MOROCCAN ROAST FIORETTO® WITH FARRO, PISTACHIO, POMEGRANATE & YOGHURT',
              image: FiorettoPomegranate,
              button: {
                link: 'https://www.perfection.com.au/recipes/moroccan-roast-fioretto-with-farro-pistachio-pomegranate-yoghurt/',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>2 x 150g packs</span> Fioretto ® Cauli Blossom',
                    '<span>2 tbsp</span> olive oil',
                    '<span>2 tsp</span> harissa paste, to taste',
                    '<span>2 tsp</span> Salt, to taste',
                    '<span>2 cups</span> farro cooked according to packet instructions',
                    '<span>1 cup</span> mint roughly chopped',
                    '<span>1 cup</span> coriander roughly chopped',
                    '<span>1 cup</span> dill roughly chopped',
                    '<span>½ cup</span> roughly chopped pomegranate seeds',
                    '<span>½ cup</span> toasted pistachios crushed',
                    '<span>½ cup</span> dried apricots roughly chopped',
                    '<span>3 tbsp</span> extra virgin olive oil',
                    '<span>1 tbsp</span> chardonnay vinegar',
                    '<span>2 tbsp</span> pomegranate molasses',
                    '<span>1 ½ cups</span> natural Greek-style yoghurt',
                    '<span>1</span> lemon'
                  ]
                }, {
                  title: 'Method',
                  main: true,
                  content: [
                    'Preheat an oven to 210C and line a baking tray with baking paper.',
                    'Toss the Fioretto® with the olive oil, harissa paste and a little salt.',
                    'Spread over the baking tray and roast for 8-10 minutes, you want it lightly charred but still with a little bite. Set aside.'
                  ]
                }
              ]
            },
            {
              type: 'recipe',
              title: 'PROSCIUTTO WRAPPED CHARRED FIORETTO®',
              image: FiorettoProscuitto,
              button: {
                link: 'https://www.perfection.com.au/recipes/prosciutto-wrapped-charred-fioretto/',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>200g</span> Fioretto® Cauli Blossom',
                    '<span>100g</span> fig or quince paste',
                    '<span>8 slices (about 125g)</span> prosciutto',
                    '<span>2 tbs</span> extra virgin olive oil',
                    'Aioli & rocket leaves to serve',
                  ]
                },
                {
                  title: 'Method',
                  main: true,
                  content: [
                    'Bring a saucepan of salted water to the boil. Cut the Fioretto® so you have 8 pieces. Drop into the boiling water and cook for 1 minute until stems are bright green. Drain, then refresh in cold water. Pat dry with paper towel.',
                    'Soften fig paste in the microwave for 10-20 seconds. Lay 1 slice of prosciutto on a board. Spread with a little fig paste and top with 1 piece of Fioretto®. Roll up and repeat with remaining prosciutto, fig paste and Fioretto®. Drizzle the Fioretto® with the oil and turn to coat',
                    'Preheat a frying pan over medium-high heat. Add the Fioretto® and cook, in batches, turning occasionally for 3 minutes, until lightly charred. Remove to a tray. Add a little water and 1 tablespoon of remaining paste to the hot pan, swirl to combine. Spoon over the Fioretto®. Serve warm or at room temperature with rocket & aioli for dipping.'
                  ]
                }
              ]
            },
            {
              type: 'recipe',
              title: 'FIORETTO® CAULI BLOSSOM WITH CHEESE SAUCE',
              image: FiorettoCheese,
              button: {
                link: 'https://www.perfection.com.au/recipes/fioretto-cauli-blossom-with-cheese-sauce/',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>300g</span> Fioretto® Cauli Blossom',
                    '<span>60g</span> butter chopped',
                    '<span>2 tbs</span> plain flour',
                    '<span>2 tsp</span> Dijon mustard',
                    '<span>1 1/2 cups</span> full cream milk',
                    '<span>60g</span> cheddar coarsely grated',
                    '<span>1/2 cup</span> panko crumbs',
                  ]
                }, {
                  title: 'Method',
                  main: true,
                  content: [
                    'Preheat oven to 200°C fan forced. Bring a large saucepan of salted water to a boil. Add Fioretto® cauli blossom and cook for 1 minute until stems turn green. Drain well. Arrange in a single layer onto a greased shallow tray or baking dish.',
                    'Melt the butter in a medium saucepan over medium heat until foaming. Remove 1 tbs to a bowl. Add flour to the pan and cook, stirring for 2 minutes or until the mixture bubbles. Remove from heat and stir in the mustard. Gradually add the milk, whisking constantly until smooth. Return to medium-high heat and cook, stirring for 5 minutes or until sauce comes to the boil. Remove from the heat. Stir in the cheddar and season. Spoon over the Fioretto® cauli blossom.',
                    'Add the panko crumbs to the reserved melted butter and mix well. Spoon over the Fioretto® cauli blossom and bake for 8-10 minutes or until light golden. Serve.',
                  ]
                }
              ]
            }
          ]
        },
        {
          question: 'Taste & Flavour',
          icon: TasteIcon,
          content: [{
              type: 'text',
              copy: 'Fioretto® Cauli Blossom® is creamy and mildly nutty on top, with delightfully sweet and tasty green stems.'
            }
          ]
        },
        {
          question: 'How do I keep this fresh<br>for the longest time?',
          icon: TimeIcon,
          content: [{
            type: 'text',
            copy: 'Refrigerate these beautiful blossoms stem-down to stop moisture from gathering in the delicious Cauli floret clusters.'
          }]
        },
        {
          question: 'What can I<br> pair this with?',
          icon: RecipeIcon,
          content: [{
              type: 'text',
              copy: 'The prettiest veg yet!'
            }, {
              type: 'text',
              copy: "You can eat your Cauli Blossom® any which way – deliciously roasted with cheese sauce & breadcrumbs or as an impressive appetiser wrapped in prosciutto spread with fig jam."
            }
          ]
        },
        {
          question: 'Health & Nutrition',
          icon: HealthIcon,
          content: [{
              type: 'text',
              copy: 'High in Vitamin C, dietary fibre and potassium with low calories and very low carbs.'
            }
          ]
        }],
        seasonality: [1,2,3,4,5,5,5,4,3,2,1,0]
      },
      {
        title: 'Cos Lettuce',
        slug: 'cos-lettuce',
        logo: '',
        background: 'yellow',
        border: false,
        stampBackground: 'green',
        stampPos: {
          left: false,
          top: true
        },
        questions: [{
          question: 'What are<br>Cos Lettuce?',
          icon: HeartIcon,
          content: [{
            type: 'text',
            copy: 'A variety of lettuce that grows in a tall head of sturdy green leaves.'
          }]
        }, {
          question: 'Recipe<br>Inspiration',
          icon: RecipeIcon,
          content: [
            {
              type: 'recipe',
              title: 'SWEET SOLANATO® TOMATO, PRAWN & QUKES® LETTUCE CUPS WITH SAFFRON MAYONNAISE',
              image: QukesAnimalSandwiches,
              button: {
                link: 'https://www.perfection.com.au/recipes/sweet-solanato-tomato-prawn-qukes-lettuce-cups-with-saffron-mayonnaise/',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>200g</span> Sweet Solanato ®Tomatoes',
                    '<span>250g</span> Qukes® baby cucumbers',
                    '<span>2</span> Baby Cos Heart',
                    '<span>700g</span> cooked medium king prawns peeled, deveined, halved lengthways',
                    '<span>1 tbs</span> extra virgin olive oil',
                    '<span>1/4 tsp</span> chilli flakes crushed',
                    'to serve micro herbs',
                    '<span>1/2 tsp</span> Saffron threads [[SAFFRON MAYONNAISE]]',
                    '<span>1 1/2</span> hot water [[SAFFRON MAYONNAISE]]',
                    '<span>3/4 cup</span> whole egg mayonnaise [[SAFFRON MAYONNAISE]]',
                    '<span>2 tsp</span> horseradish cream [[SAFFRON MAYONNAISE]]',
                  ]
                }, {
                  title: 'Method',
                  main: true,
                  content: [
                    'To make the saffron mayonnaise, heat a small non-stick frying pan over medium heat until hot. Remove from the heat, add saffron threads. Shake in the hot pan for 30 seconds then tip threads into a small bowl. Add the water, set aside for 5 minutes to infuse. Whisk the mayonnaise and horseradish together. Stir in the saffron and water, season. Cover and set aside until ready to serve.',
                    'Separate the leaves from the baby cos, wash and pat dry with paper towel. Slice the tomatoes into quarters lengthways and place into a bowl. Slice 4 Qukes® into rounds and add to the tomatoes with the prawns. Combine the oil and chilli flakes, spoon over the tomato mixture, season and stir gently to combine.',
                    'Arrange lettuce leaves on serving platter. Dollop a spoonful of saffron mayonnaise into the centre of each. Top with tomato and prawn mixture. Season and garnish with micro herbs if desired.',
                  ]
                }
              ]
            },
            {
              type: 'recipe',
              title: 'MEDITERRANEAN MUSHROOM LETTUCE CUPS',
              image: QukesFalafelBowl,
              button: {
                link: 'https://www.perfection.com.au/recipes/mediterranean-mushroom-lettuce-cups',
                text: 'Learn more about recipes'
              },
              recipe: [{
                  title: 'Ingredients',
                  main: true,
                  content: [
                    '<span>200g</span> Button Mushrooms thinly sliced',
                    '<span>1 each</span> Cos Lettuce washed and dried',
                    '<span>1/4 cup</span> chopped roasted capsicum',
                    '<span>1/4 cup</span> semi-dried tomatoes chopped',
                    '<span>2 sliced</span> char-grilled eggplant chopped',
                    '<span>3 tbsp</span> olive oil',
                    '<span>1 tbsp</span> freshly squeezed lemon juice',
                    '<span>1 tsp</span> Dijon mustard',
                    '<span>2 tbsp</span> pine nuts toasted',
                    '<span>60g</span> feta cheese',
                  ]
                },
                {
                  title: 'Method',
                  main: true,
                  content: [
                    'Place the button mushrooms in a ceramic bowl. Whisk the olive oil, lemon juice, Dijon mustard, salt and freshly ground black pepper in a bowl and then pour over mushrooms. Stir to coat. Cover and allow to marinate for 20-30 minutes or until tender.',
                    'Add the semi-dried tomatoes, char-grilled eggplant, roasted capsicum and toasted pine nuts. Spoon into  lettuce leaves, crumble over some feta and serve.',
                  ]
                }
              ]
            }
          ]
        },
        {
          question: 'Taste & Flavour',
          icon: TasteIcon,
          content: [{
              type: 'text',
              copy: 'Sink your teeth in and soak up the delicate flavours of sweetness and tang combined with its succulent crunch is one of the freshest flavours around.'
            }, {
              type: 'text',
              copy: 'You can spot Cos Lettuce by its long head of dark green leaves.'
            }
          ]
        },
        {
          question: 'How do I keep this fresh<br>for the longest time?',
          icon: TimeIcon,
          content: [{
            type: 'text',
            copy: 'Wrap your lettuce, unwashed, in a plastic bag and store in your fridge crisper. It will keep for up to five days.'
          }]
        },
        {
          question: 'What can I<br> pair this with?',
          icon: RecipeIcon,
          content: [{
              type: 'text',
              copy: 'Perfect for crisp, refreshing salads. Cos lettuce quarters are great grilled quickly over high heat, topped with dressing.'
            }
          ]
        },
        {
          question: 'Health & Nutrition',
          icon: HealthIcon,
          content: [{
              type: 'text',
              copy: 'Thirst quenching thanks to its high water content, and filled with folate and antioxidants for all-round good health.'
            }
          ]
        }],
        seasonality: [5,5,3,2,1,0,2,3,3,4,5,5]
      }
    ],
    grownProcess: [
      {
      id: 1,
      icon: '',
      videoLink: FL_environment,
      animation: 'environment',
      copy: 'Our growers have farms located all over the country so that we can supply the best produce all year round. In winter we look to farmland in the north and in summer farmland in the south of Australia.'
    }, {
      id: 2,
      icon: '',
      videoLink: FL_growing,
      animation: 'growing',
      copy: 'Fields are prepared, seeds are grown into seedlings which are then placed into the ground. The crop takes approx 6 to 8 weeks to fully mature.'
    }, {
      id: 3,
      icon: '',
      videoLink: FL_harvest,
      animation: 'harvest',
      copy: 'Once just right the vegetables are harvested by hand in the field and packed on farm into bunches or into bags to protect the produce.'
    }, {
      id: 4,
      icon: '',
      videoLink: FL_packing,
      animation: 'packing',
      copy: 'Vege is double checked for quality ready for delivery to fruit & vege markets and supermarket shelves around Australia.'
    }
  ]
  }
]

export default scenes;