import React from 'react'
import styled from 'styled-components'
import { styles } from '../../../styles/AppTheme'

const CloseBtnStyles = styled.button `
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  border-radius: 100%;
  overflow: hidden;
  z-index: 99;
  width: 35px;
  height: 35px;
  padding: 0;
  border: 0;

  span {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);

    &::before, &::after {
      content: '';
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: ${styles.theme.secondaryColour};
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
    }

    &::before { transform: translate(-50%, -50%) rotate(-45deg); }
    &::after { transform: translate(-50%, -50%) rotate(45deg); }
  }
`

const CloseButton = ({handleClose, className = ''}) => {
  return (
    <CloseBtnStyles className={`close-btn ${className}`} onClick={handleClose}>
      <span></span>
    </CloseBtnStyles>
  )
}

export default CloseButton
