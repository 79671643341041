import React, {useEffect} from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import Paragraph from '../_library/typography/Paragraph'

const MotionPermErrWrapper = styled.div `
  
`;

const MotionPermErr = () => {
  useEffect(() => {
    ReactGA.event({
      category: "Error",
      action: "Motion"
    });
  }, []);

  return (
    <MotionPermErrWrapper>
      <Paragraph>The motion and orientation permissions were denied on launch. Please refresh your browser and press allow on the pop-up.</Paragraph>
    </MotionPermErrWrapper>
  )
}

export default MotionPermErr
