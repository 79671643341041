import React from 'react'
import styled from 'styled-components'

import { styles } from '../../../styles/AppTheme'

const ParagraphWrapper = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;
  font-family: ${styles.primaryFont};
`;

const Paragraph = ({className = '', children}) => {
  return (
    <ParagraphWrapper className={`paragraph ${className}`}>
      <p>{children}</p>
    </ParagraphWrapper>
  )
}

export default Paragraph
