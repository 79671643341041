import { 
  TOGGLE_AUDIO,
  TRIGGER_PRODUCT,
  CHANGE_STATE,
  CHANGE_SCENE,
  TOGGLE_MAP
} from '../action-types';

const appReducer = (state, action) => {
  const { type, payload } = action;

  switch(type) {
    case TOGGLE_MAP: 
      return {
        ...state,
        mapOpen: payload
      }
    case TOGGLE_AUDIO:
      return {
        ...state,
        audioOn: payload
      }
    case CHANGE_STATE:
      return {
        ...state,
        sceneState: payload,
        currentProduct: -1
      } 
    case TRIGGER_PRODUCT:
      return {
        ...state,
        sceneState: payload.state,
        currentProduct: payload.product
      } 
    case CHANGE_SCENE:
      return {
        ...state, 
        sceneData: payload
      }
    default:
      return state
  }
}

export default appReducer;