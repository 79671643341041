import { UAParser } from 'ua-parser-js'

const checkDevice = () => {
  const parser = new UAParser();
  const uaData = parser.getResult();

  const { browser, os, device, ua } = uaData;
  const touchPoints = navigator.maxTouchPoints;

  let deviceInformation = {
    vendor: device.vendor,
    os: os.name,
    os_version: os.version,
    browser: browser.name,
    browser_version: browser.version,
    type: device.type
  };

  // If no vendor do some manual checks
  if(!device.vendor) {

    // Apple
    if(os.name === 'Mac OS') {
      deviceInformation = {
        ...deviceInformation,
        vendor: 'Apple',
        type: touchPoints > 0 ? 'tablet' : 'desktop'
      }
    } 

    if(os.name === 'iOS') {
      deviceInformation = {
        ...deviceInformation,
        vendor: 'Apple'
      }
    }

    if(os.name === 'Linux' && browser.name === 'Samsung Browser') {
      deviceInformation = {
        ...deviceInformation,
        vendor: 'Samsung',
        os: 'Android',
        type: 'tablet'
      }
    }

    if(os.name === 'Windows') {
      if(touchPoints === 0) {
        deviceInformation = {
          ...deviceInformation,
          type: 'desktop'
        }
      }
      // Do windows check here
      // TODO: check surface products as they will 
      // TODO: return windows but have touch points, 
      // TODO: rear camera and could possibly use an AR experience
    }
  }

  return {
    ...deviceInformation,
    touchPoints,
    ua
  }
}

export default checkDevice;