import React, { useReducer, useEffect } from 'react'
import ReactGA from 'react-ga'

import ConfigContext from './contexts/config-context'
import configReducer from './reducers/config-reducer'
import { 
  SET_ERROR,
  SET_DEVICE_INFO,
  LOADING_FINISHED,
  SET_AR_ENABLED,
  SET_AR_PLACED,
  SET_SURFACE_FOUND,
  SET_SETUP_DONE,
  SET_PROGRESS,
  SET_AR_TYPE,
  SOUND_ENABLED,
  INTRO_COMPLETED,
  INTRO_STARTED
} from './action-types'

import config from '../data/_config' // Default settings
import { usePC } from "@unbnd-operations/react-hooks"
import checkDevice from '../utils/helpers/check-device'

const ConfigState = (props) => {
  const initalState = config;
  const [state, dispatch] = useReducer(configReducer, initalState);

  // * Error screen handling
  // *-------------------------------------* /
  // Handle permission errors (camera, motion)
  usePC({ event: 'ar:error:permission', callback: ({reason}) => {
    dispatch({ type: SET_ERROR, payload: reason });
  }});

  // Handle browser error
  const [setBrowserError] = usePC({ event: 'ar:error:browser', callback: () => {
    dispatch({type: SET_ERROR, payload: 'browser' });
  }});

  // Trigger a desktop error - {Available in context}
  const setDesktopError = () => {
    dispatch({ type: SET_ERROR, payload: 'desktop' });
  };


  // * PlayCanvas Loading
  // *-------------------------------------* /
  // Loading done function
  usePC({ event: 'start', once: true, callback: () => {
    dispatch({ type: LOADING_FINISHED, payload: true });
    ReactGA.event({
      category: "Debug",
      action: "PlayCanvas Ready"
    });
  }});


  // * Start AR
  // *-------------------------------------* /
  // Initialise AR - {Avaiable in context}
  const [initialiseAR] = usePC({ event: 'ar:init', once: true });

  const startIntro = () => {
    dispatch({ type: INTRO_STARTED, payload: true });
  }

  // Sound Enabled - {Available in context}
  const enableSound = () => {
    dispatch({ type: SOUND_ENABLED, payload: true });
  };

  // AR Ready
  usePC({ event: 'ar:started', once: true, callback: () => {
    dispatch({ type: SET_AR_ENABLED, payload: true });
  }});

  // Complete Intro - {Available in context}
  const completeIntro = () => {
    dispatch({ type: INTRO_COMPLETED, payload: true });
  };


  // * AR Type
  // *-------------------------------------* /
  // Check AR Type 
  usePC({ event: 'ar:type', once: true, callback: ({type}) => {
    dispatch({ type: SET_AR_TYPE, payload: type });
  }})

  
  // * Placement functions
  // *-------------------------------------* /
  // Handle Surface Found
  usePC({ event: 'ar:surface:found', once: true, callback: () => {
    dispatch({ type: SET_SURFACE_FOUND, payload: true});
  }});

  // Setup Done
  const [completeSetup] = usePC({ event: 'ar:setup:done', once: true, callback: () => {
    // console.log('Setup done');
    dispatch({ type: SET_SETUP_DONE, payload: true });
    ReactGA.event({
      category: "Debug",
      action: "Setup Completed"
    });
  }});

  // Handle Place AR
  const [handleARPlace] = usePC({ event: 'ar:placed', callback: () => {
    dispatch({ type: SET_AR_PLACED, payload: true });
  }});

  // Handle Putdown AR
  const [handleARPutdown] = usePC({ event: 'ar:putdown', callback: () => {
    dispatch({ type: SET_AR_PLACED, payload: true })
  }});

  // PlaceAR Function - {Available in context}
  const placeAR = (firstPlace) => {
    // console.log('Place AR');
    // console.log(firstPlace);
    if (firstPlace) {
      handleARPlace();
    } else {
      handleARPutdown();
    }  
  };

  // Handle Pickup AR
  const handlePickupAR = () => {
    dispatch({ type: SET_AR_PLACED, payload: false });
  };

  // PickupAR Function - {Available in context}
  const [pickupAR] = usePC({ event: 'ar:pickup', callback: handlePickupAR });

   // RecenterAR Function - {Available in context}
  const [recenterAR] = usePC({ event: 'ar:recenter' });


  // * Get device information
  // *-------------------------------------* /
  useEffect(() => {
    const thisDevice = checkDevice();

    dispatch({
      type: SET_DEVICE_INFO,
      payload: thisDevice
    });
  }, []);


  return (
    <ConfigContext.Provider value={{
      config: state,
      setDesktopError,
      setBrowserError,
      initialiseAR,
      startIntro,
      placeAR,
      completeSetup,
      pickupAR,
      recenterAR,
      enableSound,
      completeIntro
    }}>
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigState
