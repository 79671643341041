import React, { useState, useContext, Fragment } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'

import { styles } from '../../../styles/AppTheme'
import BackArrow from '../buttons/BackArrow'
import SolidButton from '../buttons/SolidButton'

import AppContext from '../../../global/contexts/app-context'

import SoundOnIcon from '../../../assets/images/icons/sound-on.svg'
import SoundOffIcon from '../../../assets/images/icons/sound-off.svg'
import MoreInfoIcon from '../../../assets/images/icons/more-information.svg'
import ExclIcon from '../../../assets/images/icons/exclamation-icon.svg'
import tutorialData from '../../../data/tutorial'

const NavbarWrapper = styled.nav`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    .navbar-overlay {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(var(--vh) * 100 + 1px);
      display: block;
      z-index: -1;
      pointer-events: none;

      &.active {
        background: rgba(0,0,0,0.5);
        pointer-events: auto;
      }
    }

  .navbar-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 1rem 2rem;
    height: ${props => props.height}px;
    z-index: 5;
    pointer-events: auto;
    align-items: center;
  }

  .nav {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .skip-tutorial, .next-tutorial {
      font-size: 12px;
      text-transform: uppercase;
      ${'' /* position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); */}
      border: 1px solid white;
      padding: 0.25rem 0.75rem;
      border-radius: 1rem;
      font-family: ${styles.headingFont};
      color: ${styles.theme.baseLight};
    }

    .next-tutorial {
      margin-left: 10px;
    }

    .sound-on {
      margin-right: 20px;
    }
  }
`;

const CollapseMenu = styled.div`
  position: fixed;
  width: auto;
  height: auto;
  background-color: #fff;
  top: ${props => props.height}px;
  right: 1.25rem;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform: translateY(30px);
  transition: all 400ms ease-in-out;
  font-size: 1.5rem;
  padding: 1.75rem 0 0;
  border-radius: 1.75rem;

  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);

    .btn-solid {
      pointer-events: auto;

      .disabled {
        pointer-events: none; // !EDIT For Demo
      }
    }
  }

  .menu-items-wrapper {
    .menu-item {
      padding: 0 2rem 1.75rem 2rem;

      .btn-solid {
        font-size: 12px;
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: center;
        padding: .5rem 1.25rem;
        margin: 0 auto;
        box-shadow: 0 3px 0 ${styles.theme.primaryShadow};

        img {
          width: 1rem;
          height: 1rem;
          margin-right: .25rem;
        }

        span {
          white-space: nowrap;
        }

        // !EDIT For Demo
        &.disabled {
          background-color: ${styles.theme.baseGrey};
          box-shadow: 0 3px 0 #8E8E8E;
          pointer-events: none;
        }
      }

      &.restart {
        border-top: 1px solid #EDEDED;
        padding-top: 2rem;
        padding-bottom: 0;

        .btn-solid {
          background-color: ${styles.theme.secondaryColour};
          box-shadow: 0 3px 0 ${styles.theme.secondaryShadow};

          // !EDIT For Demo
          &.disabled {
            background-color: ${styles.theme.baseGrey};
            box-shadow: 0 3px 0 #8E8E8E;
            pointer-events: none;
          }
        }
      }


    }
  }
`;

const Navbar = ({ logo, menuItems, className, handleMenuItem, height = 80, showBack }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    app: {
      audioOn,
      sceneState,
      mapOpen,
      sceneData
    },
    toggleAudio,
    triggerStateGoBack,
    tutorialCompleted,
    nextTutorialStep,
    tutorialStep
  } = useContext(AppContext);

  const handleMenu = (itemId) => {
    handleMenuItem(itemId);
    setIsOpen(false);
    if (itemId === 'skip-tutorial') {
      ReactGA.event({
        category: "Tutorial",
        action: "Skipped Tutorial"
      });
    }
  }

  const handleCloseOverlay = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(!isOpen);
  }

  const goBackClick = () => {
    triggerStateGoBack(sceneState, mapOpen);
  }

  const goNextTutorial = () => {
    console.log(`sceneData`, sceneData)
    console.log(`tutorialData[tutorialStep].scene`, tutorialData[tutorialStep].scene)
    if(sceneData?.slug !== tutorialData[tutorialStep].scene) return;
    nextTutorialStep(tutorialStep + 1);
    ReactGA.event({
      category: "Tutorial",
      action: "Next Tutorial Step"
    });
  }

  return (
    <NavbarWrapper id='navbar' height={height} className={className}>
      <div onClick={handleCloseOverlay} className={`navbar-overlay ${isOpen ? 'active' : ''}`}></div>
      <div className="navbar-wrapper">

        <div className='nav'>
          <div className="tutorial-btn-wrapper">
            {!tutorialCompleted && !mapOpen && (
              <>
                <button onClick={() => handleMenu('skip-tutorial')} className="skip-tutorial">
                  Skip tutorial
                </button>
                {tutorialStep < 7 && !audioOn && (
                  <button onClick={() => goNextTutorial()} className="next-tutorial">
                    Next step
                  </button>
                )}
              </>
            )}
          </div>

          <div className="nav-right-wrapper">
            <button className="sound-on" onClick={toggleAudio}>
              {audioOn
                ? <img src={SoundOnIcon} alt="Sound on icon" />
                : <img src={SoundOffIcon} alt="Sound off icon" />
              }
            </button>

            <button className="more-info" onClick={() => setIsOpen(!isOpen)}>
              <img src={MoreInfoIcon} alt="More Information button" />
            </button>
          </div>
        </div>

        {showBack && <BackArrow handleBack={goBackClick} />}
      </div>

      <CollapseMenu height={height} className={`nav-menu ${isOpen ? 'active' : ''}`}>
        <div className='menu-items-wrapper'>
          {menuItems.map((item, index) => (
            <Fragment key={index}>
              <div className={`menu-item ${item.id}`}>
                <SolidButton handleClick={() => handleMenu(item.id)}>
                  <img src={ExclIcon} className="symbol" alt={`${item.name} Icon`} />
                  <span>{item.name}</span>
                </SolidButton>
              </div>
            </Fragment>
          ))}
        </div>
      </CollapseMenu>
    </NavbarWrapper>
  )
}

export default Navbar
