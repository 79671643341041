import React, { useState, useContext, useRef, useEffect } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import { usePC } from "@unbnd-operations/react-hooks"
import AppContext from '../../../global/contexts/app-context'

import QuestionsNav from './QuestionsNav'
import QuestionContent from './QuestionContent'
import QuestionPopup from './QuestionPopup'
import TitleStamp from '../../custom/TitleStamp'
import LinkoutPrompt from '../../custom/LinkoutPrompt';

const ProductQuestionWrapper = styled.div `
  overflow: hidden;

  .question-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 3.5rem 0 1rem 0;
    width: 100%;
    overflow-x: hidden;

    .title-wrapper {
      margin-top: .75rem;
    }
  }
`

const Product = () => {
  const { app: { sceneData, currentProduct } } = useContext(AppContext);
  const [activeQuestion, setActiveQuestion] = useState(-1);
  const [activeAnswer, setActiveAnswer] = useState(-1); // Answer
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(false);
  const productData = sceneData.products[currentProduct];
  const scrollBarRef = useRef(null);

  const [featureOn] = usePC({event: 'product:feature:on'});
  const [featureOff] = usePC({event: 'product:feature:off'});

  const handleNavClick = id => {
    // If Same back to initial state
    if(id === activeQuestion) {
      featureOff();
      return setActiveQuestion(-1);
    }

    featureOn();
    setActiveQuestion(id);

    ReactGA.event({
      category: "Experience",
      action: "View Question",
      label: `${productData.title} - Question:${id}`
    });
  }

  const openPopup = id => {
    setActiveAnswer(id);
    setIsPopupOpen(true);
  }

  const openLinkOutPrompt = id => {
    console.log(`id`, id)
    setActiveAnswer(id);
    setIsPromptOpen(true);
  }

  const closePopup = () => {
    setActiveAnswer(-1);
    setIsPopupOpen(false);
  }

  const closeLinkOutPrompt = () => {
    setActiveAnswer(-1);
    setIsPromptOpen(false);
  }

  const resetQuestionScrollBar = () => {
    setScrollTop(true);
  }

  useEffect(() => {
    if (!scrollBarRef) return;
    if (!scrollTop) return;
    if (!scrollBarRef?.current) return;
    scrollBarRef.current.scrollLeft = 0;
    setScrollTop(false);
  }, [scrollBarRef, scrollTop]);

  useEffect(() => {
    ReactGA.event({
      category: "Experience",
      action: "Product Viewed",
      label: productData.title
    });
  }, []);

  return (
    <ProductQuestionWrapper>


      <section className="question-bottom">
        <QuestionsNav
          questions={productData.questions}
          activeQuestion={activeQuestion}
          pickQuestion={handleNavClick}
          resetScroll={resetQuestionScrollBar}
        />


      {activeQuestion >= 0 ? (
        <QuestionContent
          content={productData.questions[activeQuestion]}
          openPopup={openPopup}
          openPrompt={openLinkOutPrompt}
          scrollBarRef={scrollBarRef}
        />
      ) : (
          <TitleStamp
            large={true}
            logo={productData.logo}
            title={productData.title}
            background={productData.background}
            border={productData.border}
            stampBG={productData.stampBackground}
            stampPos={productData.stampPos}
          />
      )}

      </section>

    {isPopupOpen && activeAnswer >= 0 && (
      <QuestionPopup
        content={productData.questions[activeQuestion].content[activeAnswer]}
        closePopup={closePopup}
      />
    )}

    {isPromptOpen && activeAnswer >= 0 && (
      <LinkoutPrompt
        linkOutURL={productData.questions[activeQuestion].content[activeAnswer].link}
        goBackClick={closeLinkOutPrompt}
      />
    )}

    </ProductQuestionWrapper>
  )
}

export default Product
