import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import ConfigContext from '../global/contexts/config-context'
import AppContext from '../global/contexts/app-context'
import { styles } from '../styles/AppTheme'

import useAudio from '../utils/hooks/useAudio'

import { Loading, Setup, LandingPage, Experience, Rotate, Error } from '../components/';
import StartButton from '../components/loading/StartButton';

import ExperienceAmbient from '../assets/audio/main-ambient.mp3'

const SceneWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${styles.theme.mainBackground};
`;

const Main = () => {
  const { 
    config: { 
      sceneLoaded, 
      arEnabled,
      arTriggered,
      introStarted,
      introCompleted,
      deviceInformation,
      error,
      setupDone
    },
    setBrowserError
  } = useContext(ConfigContext);
  const {app: { audioOn }, videoIsPlaying } = useContext(AppContext);
  const [isFocused, setIsFocused] = useState(true);
  // const [playAmbient] = useAudio(ExperienceAmbient, {
  //   loop: true, 
  //   volume: (audioOn && isFocused && !videoIsPlaying) ? 0.4 : 0
  // });

  const onBlur = () => {
    setIsFocused(false);
  }

  const onFocus = () => {
    setIsFocused(true);
  }

  useEffect(() => {
    // console.log(isFocused);
  }, [isFocused])

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
    }
  }, [])

  useEffect(() => {
    if(!setupDone) return;

    // playAmbient();
  }, [setupDone]);

  useEffect(() => {
    if(!deviceInformation) return;
    if (deviceInformation.type === "desktop") return;

    const startScript = document.createElement('script');
    startScript.src = './scene/__start__.js';
    document.body.appendChild(startScript);
    
  }, [deviceInformation]);

  useEffect(() => {
    if(!sceneLoaded) return;

    // if(deviceInformation.os === "iOS" && deviceInformation.browser === "Chrome") {
    //   setBrowserError(); 
    // }
  }, [sceneLoaded])

  return (
    <>
      {deviceInformation && (
        <>

          {!introStarted && <LandingPage /> }

          {!introCompleted && deviceInformation.type !== 'desktop' && <StartButton isActive={sceneLoaded} /> }

          {sceneLoaded ? (
            <>              
              {error && <Error /> }

              {introStarted && !introCompleted && <Loading /> }

              {arTriggered && introCompleted && <Rotate /> }

              {arEnabled && introCompleted && !setupDone && <Setup /> }
              
              {arEnabled && introCompleted && setupDone && <Experience /> }

            </>
          ) : <SceneWrapper />}
        </>
      )} 
    </>
  )
}

export default Main
