import { 
  SET_ERROR,
  SET_DEVICE_INFO,
  LOADING_FINISHED,
  SET_AR_ENABLED,
  SET_AR_PLACED,
  SET_PROGRESS,
  SET_SETUP_DONE,
  SET_SURFACE_FOUND,
  SET_AR_TYPE,
  SOUND_ENABLED,
  INTRO_COMPLETED,
  INTRO_STARTED
} from '../action-types'

const configReducer = (state, action) => {
  const { type, payload } = action;

  switch(type) {
    case SET_ERROR:
      return {
        ...state,
        error: payload
      }
    case SET_AR_TYPE:
      return {
        ...state,
        arType: payload
      }
    case SET_DEVICE_INFO:
      return {
        ...state,
        deviceInformation: payload
      }
    case SET_PROGRESS: 
      return {
        ...state,
        loadingProgress: payload
      }
    case LOADING_FINISHED:
      return {
        ...state,
        sceneLoaded: payload
      }
    case SOUND_ENABLED:
      return {
        ...state,
        soundEnabled: payload
      }
    case INTRO_STARTED:
      return {
        ...state,
        introStarted: payload
      }
    case INTRO_COMPLETED: 
      return {
        ...state,
        introCompleted: payload
      }
    case SET_AR_ENABLED:
      return {
        ...state,
        arEnabled: payload
      }
    case SET_SETUP_DONE:
      return {
        ...state,
        setupDone: payload
      }
    case SET_SURFACE_FOUND:
      return {
        ...state,
        arFound: payload
      }
    case SET_AR_PLACED:
      return {
        ...state,
        arPlaced: payload
      }
    default: 
      return state
  }
}

export default configReducer;