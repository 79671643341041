import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { usePC } from '@unbnd-operations/react-hooks';
import { styles } from '../../styles/AppTheme'

import ConfigContext from '../../global/contexts/config-context';
import AppContext from '../../global/contexts/app-context';
import SolidButton from '../_library/buttons/SolidButton'


import ArIcon from '../../assets/images/icons/ar-icon.svg'
import ExclIcon from '../../assets/images/icons/exclamation-icon.svg'
import SoundOnIcon from '../../assets/images/icons/sound-on.svg'
import SoundOffIcon from '../../assets/images/icons/sound-off.svg'

const StartButtonWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: calc(100% - 4rem);
  transform: translate(-50%, -100%);
  z-index: 900;
  width: 100%;
  transition: all 300ms;
  pointer-events: auto;
  opacity: 0;
  text-align: center;

  &.active {
    opacity: 1;
  }

  &.center {
    position: absolute;
    bottom: auto;
    top: 50%;
    transform: translate(-50%,-50%);
    
    .btn-solid {
      width: auto;
    }
  }

  &.fade-out {
    pointer-events: none;
    opacity: 0;
    transition: all 400ms linear 300ms;
  }

  .btn-solid {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    width: 250px;

    img {
      height: 24px;
      width: 24px;
      margin-right: .5rem;
    }

    span {
      display: block;
      text-align: left;
    }
  }

  .start-tip {
    font-size: .75rem;
    position: absolute;
    left: 50%;
    bottom: -3.5rem;
    transform: translateX(-50%);
    text-align: center;
    width: 60%;
    color: #333333;
    font-family: ${styles.primaryFont};
  }
  

`;

const LoadingProgressWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: calc(100% - 4rem);
  transform: translate(-50%, -100%);
  z-index: 899;
  width: 100%;
  pointer-events: auto;
  opacity: 0;
  transition: opacity 300ms linear 500ms;

  &.active {
    opacity: 1;
  }

  .progress-wrapper {
    pointer-events: none;
    padding: 1rem 2rem;
    background: ${styles.theme.baseGrey};
    box-shadow: 0 6px 0 #8E8E8E;
    border-radius: 100px;
    width: 250px;
    margin: 0 auto;
    height: 56px;
    position: relative;
    overflow: hidden;

    span {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 1rem;
      font-family: ${styles.headingFont};
      text-transform: uppercase;
      color: ${styles.theme.invertedTextColour};
    }



    .progress-bar {
      position: absolute;
      left: 0;
      top: 0;
      width: ${props => props.progress}%;
      height: 56px;
      background: ${styles.theme.primaryColour};
    }
  }
`;

const StartButton = ({ isActive }) => {
  const {
    config: { arEnabled, soundEnabled, introStarted },
    initialiseAR,
    enableSound,
    startIntro,
    completeIntro
  } = useContext(ConfigContext);
  const [progress, setProgress] = useState(0);

  // Loading progress (from PlayCanvas)
  usePC({ event: 'preload:progress', callback: value => setProgress(value)});

  const { toggleAudio } = useContext(AppContext)

  const [buttonState, setButtonState] = useState(0);

  const handleButtonClick = (e) => {
    e.preventDefault();

    if (!isActive) return;

    switch (buttonState) {
      case 0:
        startIntro();
        break;
      case 1:
        initialiseAR();
        break;
      case 2:
        enableSound();
        break;
      default:
      // console.log('do nothing');
    }
  }

  const buttonContent = () => {
    switch (buttonState) {
      case 0:
        return (
          <>
            <img src={ArIcon} alt="AR Icon" />
            <span>Begin AR Experience</span>
          </>
        )
      case 1:
        return (
          <>
            <img src={ExclIcon} alt="Exclamation mark icon" />
            <span>Turn on permission</span>
          </>
        )
      case 2:
        return (
          <>
            <img src={SoundOffIcon} alt="Sound off icon" />
            <span>Turn sound on</span>
          </>
        )
      case 3:
        return (
          <>
            <img src={SoundOnIcon} alt="Sound off icon" />
            <span>Sound turned on</span>
          </>
        )
      default:
        return (
          <></>
        )
    }
  }

  const goWithoutSound = () => {
    completeIntro();
    toggleAudio();
  }

  useEffect(() => {
    if (!introStarted) {
      setButtonState(0);
      return;
    }

    if (!arEnabled) {
      setButtonState(1);
      return;
    }

    if (!soundEnabled) {
      setButtonState(2);
      return;
    }

    setButtonState(3);
  }, [arEnabled, introStarted, soundEnabled])

  return (
    <>
      <StartButtonWrapper className={`start-btn-wrapper ${isActive ? 'active' : ''} ${buttonState > 0 ? 'center' : ''} ${buttonState === 3 ? 'fade-out' : ''}`}>
        <SolidButton handleClick={handleButtonClick}>
          {buttonContent()}
        </SolidButton>
        {buttonState === 2 && (
          <span className="start-tip">For best experience, please turn your phone off silent mode.</span>
        )}
      </StartButtonWrapper>
      
      {buttonState === 2 && (
        <span onClick={goWithoutSound} className='skip-btn'>Continue without sound</span>
      )}

      <LoadingProgressWrapper 
        progress={Math.floor(progress * 100)} 
        className={`loading-progress-wrapper ${isActive ? '' : 'active'}`}
      >
        <div className="progress-wrapper">
          <div className="progress-bar"></div>
          <span>{Math.floor(progress * 100)}%</span>
        </div>
      </LoadingProgressWrapper>
    </>
  )
}

export default StartButton
