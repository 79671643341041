import React, { useContext } from 'react'
import styled from 'styled-components'

import { styles } from '../../styles/AppTheme'
import ConfigContext from '../../global/contexts/config-context'

import TickSvg from '../../assets/images/icons/tick.svg'

const SetupWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  justify-content: flex-end;
  text-align: center;
  pointer-events: none;
  background: rgba(0,0,0,0.25);

  .place-controller {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: auto;
    z-index: 20;
  }

  p {
    color: #FFFFFF;
    margin-bottom: 1rem;
  }

  .instruction {
    font-size: 1rem;
    font-family: ${styles.headingFont};
    text-transform: uppercase;
    margin-bottom: .5rem;
  }

  .tip {
    font-size: .75rem;
    font-family: ${styles.primaryFont};
  }

  .scan-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 50vw;
    max-width: 168px;
    max-height: 168px;
    border: 4px solid #FFFFFF;
    border-radius: 50%;

    &::after {
      content: '';
      width: 99%;
      height: 99%;
      border-radius: 50%;
      border: 2px solid #FFFFFF;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: -1;
      background: rgba(159,217,180,.44);
    }

    svg {
      width: 100%;
      height: 100%;
      stroke: #FFFFFF;
    }
  }

  .complete-btn {
    background: ${styles.theme.secondaryColour};
    border: 2px solid ${styles.theme.baseLight};
    border-radius: 35px;
    width: 70px;
    height: 70px;
    margin: 1rem auto;
    display: grid;
    place-items: center;
    pointer-events: auto;
    box-shadow: 0 3px ${styles.theme.secondaryShadow};

    img {
      margin: 0;
      filter: invert(1);
    }
  }
`;

const Setup = () => {
  const { 
    config,
    placeAR,
    completeSetup
  } = useContext(ConfigContext);
  
  const {
    arFound,
    arPlaced
  } = config;
  

  const handlePlace = () => {
    if(!arFound) return;

    placeAR(true);
  }

  const handleCompleteClick = () => {
    completeSetup();
  }

  return (
    <SetupWrapper id='setup-screen'>
      {arPlaced ? (
        <>
          <div className="complete-btn" onClick={handleCompleteClick}>
            <img src={TickSvg} alt='tick icon to place models in scene'/>
          </div>
          <p className="instruction">Pinch and drag to resize or move</p>
        </>
      ) : (
        <>
          
          {!arFound ? (
            <div className="scan-icon">
              <svg viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="84" cy="84" r="82" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="62" y="36" width="44" height="85" rx="8" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M149 133.5L123.913 108.861C122.011 106.993 120.681 104.62 120.082 102.021L112.102 67.4439C112.034 67.1489 112 66.8472 112 66.5444V59.765C112 56.942 108.978 55.1482 106.5 56.5V56.5" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M61 62L58.8186 61.7382C55.1826 61.3019 52.9205 65.5754 55.3256 68.3369L62 76" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M61 75.5L58.5697 77.2675C56.8489 78.519 56.409 80.8985 57.5686 82.6824L62 89.5" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M117 159L102.376 132.259C100.515 128.856 97.5455 126.191 93.9609 124.708L85 121" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <line x1="80.25" y1="112.75" x2="87.75" y2="112.75" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          ) : (
            <div className="place-controller" onClick={handlePlace}></div>
          )}
          <p className="instruction">
            {!arFound
              ? 'Slowly pan your device to find a surface' 
              : 'Surface found. Tap to place'
            }
          </p>
          <p className="tip">{!arFound ? (
            <>
              Tip: Look for a table for the best experience
            </>
          ) : ( 
            <>
              &nbsp;
            </>
          )}</p>
        </>
      )}
    </SetupWrapper>
  )
}

export default Setup
