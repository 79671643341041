import React from 'react'
import styled from 'styled-components'
import { styles } from '../../../styles/AppTheme'

const ArrowSvg = styled.svg `
  height: ${props => props.arrowSize}px;
  width: ${props => props.arrowSize}px;
  pointer-events: auto;
  margin-bottom: 0;
`

const ArrowWrapper = styled.button`
  background-color: ${styles.theme.baseLight};
  border-radius: 25px;
  height: 45px;
  width: 50px;
  display: grid;
  place-items: center;
  box-shadow: 0px 3px #5D836B;
  z-index: 1;
`;

const BackArrow = ({handleBack, className = '', colour, size, stroke}) => {

  const handleClick = () => {
    handleBack();
  }

  return (
    <ArrowWrapper className="back-arrow" onClick={handleClick}>
      <ArrowSvg arrowSize={24} xmlns="http://www.w3.org/2000/svg" className={`arrow ${className}`} fill="none" viewBox="0 0 24 24" stroke={styles.theme.darkGreen}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
      </ArrowSvg>
    </ArrowWrapper>
  )
}

export default BackArrow
