import styled from 'styled-components';

export const styles = {
  primaryFont: '"Archer", sans-serif',
  headingFont: '"GT Pressura", sans-serif',
  theme: {
    primaryColour: '#FFB648', // Orange
    secondaryColour: '#9FD9B4', // Green 
    textColour: "#000000",
    invertedTextColour: "#FFFFFF",
    baseDark: '#000000',
    baseLight: '#FFFFFF',
    baseGrey: '#DDDDDD', 
    mainBackground: '#DDD9D4', // Off white
    placementError: '#FF866B',
    placementSuccess: '#9FD9B4',
    placementBase: '#757575',
    primaryShadow: '#CBAB7B', // Orange Shadow
    secondaryShadow: '#89B99A', // Green Shawdow
    darkGreen: '#1B5633', // dark green
    brown: '#DDC0A6',
    yellow: '#FFB648'
  },
};

const AppTheme = styled.div`
  width: 100%;
  height: 100% !important;
  overflow: hidden;

  a {
    &:hover, 
    &:focus {
      color: ${styles.theme.primaryColour};
      outline: none;
    }
  }

  p {
    font-family: ${styles.primaryFont};
  }

  img {
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${styles.headingFont};
    line-height: 1.25;
  }

  .main-heading {
    font-size: 1.75rem;
    margin-bottom: .5rem;
    text-transform: uppercase;
  }

  .heading {
    font-size: 1.25rem;
    margin-bottom: .25rem;
    text-transform: uppercase;
  }

  .sub-heading {
    font-size: 1rem;
    margin-bottom: .25rem;
  }

  .btn {
    font-family: ${styles.headingFont};
  }




`;

export default AppTheme;