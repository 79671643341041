export const grownGlasshouse = [
  {
    scene: 'glasshouse',
    step: 1,
    text: 'WELCOME TO OUR AMAZING GLASSHOUSE, WHERE WE GROW OUR INCREDIBLE TOMATOES AND CRUNCHY QUKES.',
    duration: 11000,
    audio: 'vo-welcome-gh-long',
    animation: 'talk',
    rotation: 10,
    position: {
      x: -0.7,
      y: 0.01,
      z: 1.134
    }
  }, {
    scene: 'glasshouse',
    step: 2,
    text: 'SIMPLY TAP ON THE SCREEN TO MOVE THROUGH THE DIFFERENT STAGES OF GROWING, FROM POLLINATION ALL THE WAY THROUGH TO PACKING AND DELIVERY TO STORE.',
    animation: 'talk',
    rotation: 10,
    position: {
      x: -0.7,
      y: 0.01,
      z: 1.134
    }
  }
];

export const grownPlantation = [
  {
    scene: 'plantation',
    step: 1,
    text: 'WELCOME TO OUR PLANTATIONS, HOME OF OUR FAMOUS CALYPSO MANGOES AND OUR LATEST ADDITION TO THE PERFECTION FRESH FAMILY, BANANAS. THIS IS WHERE WE’LL SHOW YOU HOW THESE CROWD FAVOURITES GO FROM TREE TO STORE!',
    duration: 16000,
    audio: 'vo-welcome-pt-long',
    animation: 'talk',
    rotation: 10,
    position: {
      x: -0.7,
      y: 0.01,
      z: 1.134
    }
  }, {
    scene: 'plantation',
    step: 2,
    text: 'SIMPLY TAP ON THE SCREEN TO MOVE THROUGH THE DIFFERENT STAGES OF GROWING, FROM POLLINATION ALL THE WAY THROUGH TO PACKING AND DELIVERY TO STORE.',
    animation: 'talk',
    rotation: 10,
    position: {
      x: -0.7,
      y: 0.01,
      z: 1.134
    }
  }
];

export const grownTunnels = [
  {
    scene: 'tunnels',
    step: 1,
    text: 'WELCOME TO OUR TUNNELS, WHERE WE GROW OUR FRESHEST AND TASTIEST BERRIES. DISCOVER HOW OUR LUSCIOUS RASPBERRIES, JUICY BLUEBERRIES AND OUR SWEET STRAWBERRIES ARE GROWN IN THESE CLIMATE-SHELTERED ENVIRONMENTS, THEN DELIVERED TO YOUR LOCAL STORE.',
    duration: 19000,
    audio: 'vo-welcome-tn-long',
    animation: 'talk',
    rotation: 10,
    position: {
      x: -0.7,
      y: 0.01,
      z: 1.134
    }
  }, {
    scene: 'tunnels',
    step: 2,
    text: 'SIMPLY TAP ON THE SCREEN TO MOVE THROUGH THE DIFFERENT STAGES OF GROWING, FROM POLLINATION ALL THE WAY THROUGH TO PACKING AND DELIVERY TO STORE.',
    animation: 'talk',
    rotation: 10,
    position: {
      x: -0.7,
      y: 0.01,
      z: 1.134
    }
  }
];

export const grownFarmlands = [
  {
    scene: 'farmlands',
    step: 1,
    text: 'WELCOME TO THE PERFECTION FARMLANDS. FROM GROWING SEEDLINGS, TO HARVESTING BY HAND, WE’LL SHOW YOU HOW SOME OF YOUR FAVOURITES ARE GROWN, SUCH AS BROCCOLINI, FIORETTO, CABBAGE AND BRUSSELS SPROUTS.',
    duration: 16000,
    audio: 'vo-welcome-fl-long',
    animation: 'talk',
    rotation: 10,
    position: {
      x: -0.7,
      y: 0.01,
      z: 1.134
    }
  }, {
    scene: 'farmlands',
    step: 2,
    text: 'SIMPLY TAP ON THE SCREEN TO MOVE THROUGH THE DIFFERENT STAGES OF GROWING, FROM POLLINATION ALL THE WAY THROUGH TO PACKING AND DELIVERY TO STORE.',
    animation: 'talk',
    rotation: 10,
    position: {
      x: -0.7,
      y: 0.01,
      z: 1.134
    }
  }
];