import React from 'react'
import styled from 'styled-components'

import { styles } from '../../styles/AppTheme'
import Stamp from './Stamp'
import Heading from '../_library/typography/Heading'

import AustralianIcon from '../../assets/images/icons/aussie-icon.svg'
import ExploreIcon from '../../assets/images/icons/explore-arrow.svg'

const TitleWrapper = styled.div`
  position: relative;
  width: auto;

  .main-stamp {
    pointer-events: none;
    > .inner-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .heading {
        margin-bottom: 0;
      }

      .product-logo {
        height: 80%;
        width: auto;
      }
    }
  }
  .stamp-sm {
    .heading {
      font-size: 1.25rem;
    }

    .icon-stamp {
      ${props => props.left ? 'left:' : 'right:'} ${props => props.top ? '-3em' : '-4em'};
      ${props => props.top ? 'top:' : 'bottom:'} -2em;
      transform: rotate(-11deg);
    }
  }

  .stamp-lg {
    .heading {
      font-size: 1.625rem;
    }

    .icon-stamp {
      ${props => props.left ? 'left:' : 'right:'} -2em;
      ${props => props.top ? 'top:' : 'bottom:'} -2em;
      transform: rotate(${props => props.top ? '-11deg' : '11deg'});
    }
  }

  .icon-stamp {
    position: absolute;
    filter: drop-shadow(${props => props.left ? '3px' : '-3px'} ${props => props.top ? '3px' : '-3px'} 0 rgba(0,0,0,0.15));
    -webkit-filter: drop-shadow(${props => props.left ? '3px' : '-3px'} ${props => props.top ? '3px' : '-3px'} 0 rgba(0,0,0,0.15));

    .inner-content {
      display: grid;
      place-items: center;
    }
  }

  .description {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: .5rem;
    }

    span {
      font-family: ${styles.primaryFont};
    }
  }
`;

const TitleStamp = ({large = false, title, logo, border, background, stampBG, stampPos: {left, top}}) => {
  const getColor = color => {
    switch(color) {
      case 'green':
        return styles.theme.secondaryColour;
      case 'blue':
        return '#6CC5E9';
      case 'yellow': 
      default:
        return styles.theme.primaryColour;
      
    }
  }

  const backgroundColor = getColor(background);
  const stampBackgroundColor = getColor(stampBG)

  return (
    <TitleWrapper className="title-wrapper" left={left} top={top} >
      <Stamp
        className={`main-stamp ${large ? 'stamp-lg' : 'stamp-sm'}`}
        scale={large ? 14 : 11}
        height={6}
        width={22}
        bgColor={backgroundColor}
        borderColor={border ? '#FFFFFF' : backgroundColor}
      >
        {logo ? (
          <img className="product-logo" src={logo} alt={`${title} Logo`} />
        ) : (
          <>
            <Heading priority="h2">{title}</Heading>
            {!large && (title === 'Seasonality' || title === `How it's grown`) && (
              <div className="description">
                <img src={ExploreIcon} alt="Explore" />
                <span>Explore</span>
              </div>
            )}
          </>
        )}  

        <Stamp
          className={`icon-stamp`}
          scale={8}
          height={6}
          width={6}
          borderColor={styles.theme.baseLight}
          bgColor={stampBackgroundColor}
        >
          <img src={AustralianIcon} alt="Always Australian Grown" />
        </Stamp>      
      </Stamp>
    </TitleWrapper>
  )
}

export default TitleStamp
