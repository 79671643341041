import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

const ScrollMeWrapper = styled.div`
  height: 10px;
  pointer-events: none;
  position: static;

  &.extend {
    height: 300vh;
    pointer-events: auto;

    .swipe {
      opacity: 1;
    }
  }

  .swipe {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ff9900;
    pointer-events: none;
    opacity: 0;

    svg {
      height: 80px;
      width: 80px;
      margin-bottom: 20px;
    }
  }

`;

const FullScreen = () => {
  const scrollRef = useRef(null)

  const stopScroll = (e) => {
    // console.log('Stopping scroll');
    e.preventDefault();
    // e.stopPropagation();
  }

  const checkHeight = () => {
    const screenHeight = Math.min(window.screen.width, window.screen.height);
    const isPortrait = window.innerHeight > window.innerWidth;
    const show = isPortrait ? window.innerHeight < window.screen.height * 0.9 : screenHeight - window.innerHeight > 40;

    if(show) {
      document.body.style.overflowY = 'scroll';
      scrollRef.current.classList.add('extend');
      document.getElementById('root').removeEventListener('touchstart', stopScroll);
      scrollRef.current.style.height = null;
    } else {
      document.body.style.overflowY = 'hidden';
      scrollRef.current.classList.remove('extend');
      document.getElementById('root').addEventListener('touchstart', stopScroll);
      if(isPortrait) scrollRef.current.style.height = `${window.innerHeight}px`;
    }
  }

  useEffect(() => {
    document.body.style.height = `${Math.min(window.screen.width, window.screen.height)}px`;

    checkHeight();
    window.addEventListener('resize', checkHeight);

    return () => window.removeEventListener('resize', checkHeight);
  },[]);

  return (
    <ScrollMeWrapper 
      ref={scrollRef}
      className="swipe-up-modal"
    >
      <figure className="swipe">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
        </svg>
        <span>Swipe up for fullscreen</span>
      </figure>
    </ScrollMeWrapper>
  )
}

export default FullScreen
