import React, { useContext, useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import AppContext from '../../../global/contexts/app-context'
import { usePC } from "@unbnd-operations/react-hooks"


import ProduceSlider from './ProduceSlider'
import TitleStamp from '../../custom/TitleStamp'

import seasonalityTutorial from '../../../data/tutorial-seasonality'

const SeasonalityWrapper = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 .5rem 3rem .5rem;
  pointer-events: none;
  opacity: 0;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
`;

const Seasonality = ({active, tutorialCompleted, completeTutorial}) => {
  // Contexts
  const { app: { sceneData, } } = useContext(AppContext);

  // State
  // const [tutorialCompleted, setTutorialCompleted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(0);

  // PlayCanvas events
  const [updateCrates] = usePC({
    event: 'season:change'
  });

  const [triggerTutorialStep] = usePC({
    event: 'character:active'
  });

  const [hideTony] = usePC({
    event: 'character:hide'
  });


  const handleSliderChange = (month) => {
    if(!sceneData) return;
    if(sceneData.slug === 'reception') return;
    if(currentStep < 1 && !tutorialCompleted) return; // Prevent click until "how to" step
    // Pass to PC
    const monthData = {
      month: month,
      products: sceneData.products.map(product => product.slug !== 'miniroos'
      ? ({
          name: product.slug,
          amount: product.seasonality[month]
        })
      : null
      ).filter(item => item !== null)
    }
    // console.log('month:', month)
    updateCrates({data: monthData});

    ReactGA.event({
      category: "Experience",
      action: "View Month",
      label: `${sceneData.title} - ${month}`
    });
  }

  const handleTutorial = (step) => {
    if(tutorialCompleted) return;
    const stepData = seasonalityTutorial[step];

    const fixedData = {
      ...stepData,
      position: stepData.position[sceneData.slug]
    }

    triggerTutorialStep({step: fixedData});

    if(fixedData.duration) {
      setTimeout(() => {
        setCurrentStep(step + 1);
      }, fixedData.duration);
    } else {
      setTimeout(() => {
        console.log('setTimeout')
        completeTutorial();
        hideTony();
        handleSliderChange(0);

      }, 1500);
    }
  }

  const handleArrowClick = month =>{
    if(!tutorialCompleted) return;
    // console.log('Arrow button');
    // console.log(month);

    setCurrentMonth(month);
  }

  // Reset Current month
  useEffect(() => {
    if(active) return;

    setCurrentMonth(0);
  }, [active]);

  // Trigger on every change
  useEffect(() => {
    if(!active) return;
    if(!tutorialCompleted) return;

    handleSliderChange(currentMonth);
  }, [currentMonth])

  // Trigger first slider when tutorial is completed
  // or Season enter
  useEffect(() => {
    if(!active) return;
    if(!tutorialCompleted) return;
    // console.log(`active`, active)
    // console.log(`tutorialCompleted`, tutorialCompleted)

    // handleSliderChange(0);
    setTimeout(() => {
      console.log('setTimeout')
      handleSliderChange(0);
    }, 2000);
  }, [active, tutorialCompleted])

  // Trigger tutorial steps
  useEffect(() => {
    if(!active) return;
    if(tutorialCompleted) return;

    handleTutorial(currentStep);
  }, [active, currentStep])


  return (
    <SeasonalityWrapper id="seasonality" className={active ? 'active' : ''}>

      <ProduceSlider
        handleChange={handleArrowClick}
        currentMonth={currentMonth}
        clickable={(currentStep > 0 || tutorialCompleted)}
      />

      <TitleStamp
        large={true}
        title="Seasonality"
        background="green"
        stampBG="blue"
        border={true}
        stampPos={{left: true, top: false}}
      />
    </SeasonalityWrapper>
  )
}

export default Seasonality
