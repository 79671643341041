import React, { useState } from 'react'
import styled from 'styled-components'

import usePC from '../../../utils/hooks/usePC'
import HandSelectorIcon from '../../../assets/images/icons/hand-selector.svg'
import HandFocusIcon from '../../../assets/images/icons/hand-focus-icon.svg'

const HandSelectorWrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
  }
`

const HandSelector = ({active}) => {
  const [isFocusing, setIsFocusing] = useState(false);

  const HandIcon = isFocusing ? HandFocusIcon : HandSelectorIcon;

  const handleFocus = () => {
    setIsFocusing(true);
  }; 

  const handleBlur = () => {
    setIsFocusing(false);
  };

  usePC({
    event: 'picker:focus',
    callback: handleFocus
  });

  usePC({
    event: 'picker:blur',
    callback: handleBlur
  });

  usePC({
    event: 'picker:grab',
    callback: handleBlur
  });

  return (
    <HandSelectorWrapper className={`HandSelectorWrapper ${active ? 'active' :''}`}>
      <img src={HandIcon} alt="Hand Selection Indicator" />
    </HandSelectorWrapper>
  )
}

export default HandSelector
