import React, { useRef, useEffect, useState, useContext } from 'react'
import { styles } from '../../../styles/AppTheme'

import styled from 'styled-components'
import BackArrow from '../buttons/BackArrow';
import SolidButton from '../buttons/SolidButton';

import linkOutIcon from '../../../assets/images/icons/link-out-icon.svg'
import LinkoutPrompt from '../../custom/LinkoutPrompt';
import appContext from '../../../global/contexts/app-context';

const VideoWrapper = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;

  .back-arrow {
    position: fixed;
    top: 1rem;
    left: 2rem;
    height: 50px;
  }

  .video {
    width: 100%;
  }

  .video-learn-more {
    position: absolute;
    bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    z-index: 99;
    p {
      font-family: ${styles.headingFont};
      font-size: 0.938rem;
      margin-left: 0.5rem;
    }
  }

`

const Video = ({className, src, playsInline, autoplay, mute, playVideo, closePopup, linkOut}) => {
  const { triggerVideoPlaying, triggerVideoPaused } = useContext(appContext);
  const videoRef = useRef(null);
  const [isPromptOpen, setIsPromptOpen] = useState(false);

  const linkOutClick = e => {
    e.preventDefault();
    e.stopPropagation();
    triggerVideoPaused();
    setIsPromptOpen(true);
  }

  const closePromptClick = e => {
    e.preventDefault();
    e.stopPropagation();
    triggerVideoPaused();
    setIsPromptOpen(false);
  }

  useEffect(() => {
    if(playVideo === true) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [playVideo])

  useEffect(() => {
    videoRef.current.muted = mute;
  }, [mute]);

  useEffect(() => {
    triggerVideoPlaying();
    
    if(mute) {
      videoRef.current.muted = mute;
    }

    if(autoplay) {
      videoRef.current.autoplay = true;
      videoRef.current.play();
      
    }

    return () => {
      triggerVideoPaused();
    }
  }, []);

  return (
    <VideoWrapper onClick={e => e.stopPropagation()}>

      <BackArrow 
        handleBack={closePopup}
      />

      <video 
        ref={videoRef}
        className={`video ${className}`}
        src={src}
        playsInline={playsInline}
        controls={true}
      >
      </video>

      {linkOut && (
        <SolidButton
          className='video-learn-more'
          handleClick={linkOutClick}
        >
          <img src={linkOutIcon} alt="Link out button icon" />
          <p>{linkOut.text}</p>
        </SolidButton>
      )}

      {isPromptOpen && (
        <LinkoutPrompt 
          linkOutURL={linkOut.link}
          goBackClick={closePromptClick}
        />
      )}

    </VideoWrapper>
  )
}

export default Video
