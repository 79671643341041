import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

import AppTheme from './styles/AppTheme'
import ConfigState from './global/ConfigState'
import AppState from './global/AppState'
import { Main } from './pages'

const App = () => {

  useEffect(() => {
    ReactGA.initialize("UA-211411238-1");
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <AppTheme id="app-theme">
      <ConfigState>
        <AppState>
          <Main />
        </AppState>
      </ConfigState>
    </AppTheme>
  )
}

export default App
