import React, { Fragment } from 'react'
import styled from 'styled-components'

import { styles } from '../../../styles/AppTheme'
import Stamp from '../../custom/Stamp';
import { setHTML } from '../../../utils/helpers/'

import PlayIcon from '../../../assets/images/icons/play-circle.svg'
import ShareIcon from '../../../assets/images/icons/share.svg'


const QuestionContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  pointer-events: auto;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  &::before {
    content: '';
    width: 1rem;
    display: block;
    flex: 0 0 1rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .stamp {
    flex: 0 0 auto;
    margin-right: .75rem;

    &.recipe-content-stamp {
      color: ${styles.theme.baseLight};
      .recipe-content-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        .recipe-title {
          padding: 0.3rem 0.75rem 0;
          font-family: ${styles.headingFont};
        }
      }
    }

    &.image-content-stamp {
      .image-content-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    &.video-content-stamp {
      .video-content-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &.linkOut-content-stamp {
      .share-icon{ 
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0.813rem;
      }
      .linkOut-copy {
        height: 100%;
        padding: 0 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: ${styles.headingFont};
        text-transform: uppercase;
      }
      &::before {
        content: '';
        width: 20px;
        height: 2px;
        background-color: ${styles.theme.baseLight};
        position: absolute;
        z-index: 1;
        top: 1.5rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.question-content-stamp {
      color: ${styles.theme.baseDark};

      .question-copy {
        font-family: ${styles.primaryFont};
      }
      &::before {
        content: '';
        width: 20px;
        height: 2px;
        background-color: ${styles.theme.baseLight};
        position: absolute;
        z-index: 1;
        top: 1.5rem;
        left: 50%;
        transform: translateX(-50%);
      }

      .inner-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        .index-number {
          position: absolute;
          bottom: 0.813rem;
          color: ${styles.theme.baseLight};
          font-family: ${styles.headingFont};
        }
      }
    }
  }
`

const QuestionContent = ({content, openPopup, openPrompt, scrollBarRef}) => {
  return (
    <QuestionContentWrapper ref={scrollBarRef} className='question-content-wrapper'>
      {content.content.map((item, index) => (
        <Fragment key={index}>
        {item.type === 'text' && (
          <Stamp 
            borderColor={styles.theme.baseLight}
            bgColor={styles.theme.brown}
            width={item.copy.length > 140 ? 19 : 14}
            height={14}
            scale={12}
            className='question-content-stamp'
          >
            <span className='question-copy' dangerouslySetInnerHTML={setHTML(item.copy)}></span>
            <span className='index-number'>0{index+1}</span>
          </Stamp>

        )}

        {item.type === 'recipe' && (
          <Stamp 
            borderColor={styles.theme.baseLight}
            bgColor={styles.theme.brown}
            width={14}
            height={14}
            scale={12}
            className='recipe-content-stamp'
            onClick={() => openPopup(index)}
          >
          <div className="recipe-content-wrapper">
            <h4 className='recipe-title'>{item.title}</h4>
            <img src={item.image} alt="" />
          </div>
          </Stamp>
        )}

        {item.type === 'image' && (
          <Stamp 
            borderColor={styles.theme.baseLight}
            bgColor={styles.theme.brown}
            width={14}
            height={14}
            scale={12}
            className='image-content-stamp'
            onClick={() => openPopup(index)}
          >
          <div className="image-content-wrapper" 
            style={{
              backgroundImage: `url(${item.image}`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}
          ></div>
          </Stamp>
        )}

        {item.type === 'video' && (
          <Stamp 
            borderColor={styles.theme.baseLight}
            bgColor={styles.theme.brown}
            width={18}
            height={14}
            scale={12}
            className='video-content-stamp'
            onClick={() => openPopup(index)}
          >
          <div className="video-content-wrapper" 
            style={{
              backgroundImage: `url(${item.videoImage}`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <img src={PlayIcon} alt="" />
          </div>
          </Stamp>
        )}

        {item.type === 'link-out' && (
          <Stamp 
            borderColor={styles.theme.baseLight}
            bgColor={styles.theme.brown}
            width={14}
            height={14}
            scale={12}
            className='linkOut-content-stamp'
            onClick={() => openPrompt(index)}
          >
            <span className='linkOut-copy'>{item.title}</span>
            <img className='share-icon' src={ShareIcon} alt="" />
          </Stamp>
        )}
        </Fragment>
      ))}



    </QuestionContentWrapper>
  )
}

export default QuestionContent
