const config = {
  error: '', // Error data
  // loadingProgress: 0, // PC Loading Progress
  sceneLoaded: false, // PC Scene Loaded
  // arTriggered: false, // AR Triggered from React ('ar:init')
  arEnabled: false, // AR Enabled from PC (ar:enabled)
  soundEnabled: false, // Sound Enabled
  arFound: false, // AR Surface found
  arPlaced: false, // AR Placed
  introStarted: false, // Trigger the Intro sequence
  introCompleted: false, // Intro Sequence Completed
  setupDone: false, // Loading done
  arType: '', // AR Type (8thwall, Zappar or WebXR)
  deviceInformation: null, // Device information
  isXR8Android: false, // using 8thWall on Android (Shows 8th wall logo on loading)
  isXR8iOS: true, // using 8thWall on iOS (Shows 8th wall logo on loading)
  portraitEnabled: true, // Portrait View Available
  landscapeEnabled: false // Landscape View Available
}

export default config;